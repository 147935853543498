import { createTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Raleway, Arial',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: '#333547',
    },
    secondary: {
      main: '#FF4081',
      contrastText: '#333547',
    },
    error: {
      main: '#EC4561',
    },
    background: {
      default: '#F8F8FA',
    },
    textPrimary: {
      dark: '#5B626C',
    },
    textSecondary: {
      light: '#FFFFFF',
    },
  },
});

export default theme;
