import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import { GET_SECTIONS_ONLY_QUERY, GET_USERS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import ConfirmDialog from './ConfirmDialog';
import {
  PROMOTE_CLASSES_MUTATION,
  PROMOTE_SECTIONS_MUTATIONS,
} from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '80%',
    margin: '10px auto',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  select: {
    width: '50%',
    marginBottom: 10,
    marginTop: 10,
  },
  button: {
    marginBottom: 20,
    width: '50%',
  },
}));
export default function PromoteSections(props) {
  const classes = useStyles();
  const { prevTerm, currentTerm, schoolID, reset } = props;
  const [secs, setSecs] = React.useState({});
  const [teachers, setTeachers] = React.useState([]);
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const secsData = useQuery(GET_SECTIONS_ONLY_QUERY, {
    variables: { id: schoolID, currentTerm: prevTerm },
    onCompleted: data => {
      const tmp = {};
      data.sections.map((s, i) => {
        tmp[i] = {
          name: `${s.class.class} ${s.section}`,
          id: s.id,
          sr: s.sr,
          teachers: s.section_teachers.map(t => ({
            value: t.teacherID,
            label: t.user.name,
          })),
        };
        return s;
      });
      setSecs(tmp);
    },
    fetchPolicy: 'network-only',
  });
  const teachersData = useQuery(GET_USERS_QUERY, {
    variables: {
      id: schoolID,
    },
    onCompleted: data => {
      const ts = data.users.map(t => ({ value: t.id, label: t.name }));
      setTeachers(ts);
    },
    fetchPolicy: 'network-only',
  });
  const [promoteClasses] = useMutation(PROMOTE_CLASSES_MUTATION);
  const [promoteSections] = useMutation(PROMOTE_SECTIONS_MUTATIONS);
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      zIndex: state.selectProps.zIndex,
    }),
  };
  const promoteSectionsSubmit = async () => {
    setDialog(false);
    setLoading(true);
    try {
      await promoteClasses({
        variables: {
          schoolID,
          termID: currentTerm,
        },
      });
      for (const sec of Object.values(secs)) {
        const vars = {
          id: parseInt(sec.id),
          termID: parseInt(currentTerm),
          data: sec.teachers.map(t => ({
            teacherID: t.value,
            sectionID: parseInt(sec.id),
          })),
        };
        await promoteSections({
          variables: vars,
        });
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    reset();
  };
  if (secsData.loading || teachersData.loading || loading) return <Loader />;
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        {!Object.keys(secs).length && (
          <Typography
            variant="h5"
            style={{ textAlign: 'center', marginTop: 20 }}
          >
            There are no sections associated with this term!
          </Typography>
        )}
        {Object.keys(secs).length > 0 && (
          <>
            <div className={classes.content}>
              {Object.values(secs).map((sec, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                  key={sec.id}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ width: 20, fontWeight: 'bold' }}
                  >
                    {sec.sr}
                  </Typography>
                  <div style={{ paddingLeft: 10, paddingRight: 10 }} />
                  <Typography
                    variant="subtitle1"
                    style={{ width: 500 }}
                  >{`${sec.name}`}</Typography>
                  <Select
                    key={`select-${index}`}
                    name={`select-${index}`}
                    value={sec.teachers}
                    onChange={val => {
                      const newSecs = { ...secs };
                      newSecs[index].teachers = val;
                      setSecs(newSecs);
                    }}
                    options={teachers}
                    isMulti
                    placeholder="Select Teachers"
                    className={classes.select}
                    zIndex={Object.keys(secs).length - index}
                    styles={customStyles}
                  />
                </div>
              ))}
            </div>
            <Divider style={{ margin: 20 }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => setDialog(true)}
              >
                Promote Sections To New Term - THIS CANNOT BE UNDONE
              </Button>
            </div>
            <ConfirmDialog
              open={dialog}
              handleConfirmClose={() => setDialog(false)}
              handleSubmit={promoteSectionsSubmit}
            />
          </>
        )}
      </Paper>
    </Container>
  );
}
