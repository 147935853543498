import React from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { GET_FEES_STRUCTURE_STUDENTS } from '../resolvers/Queries';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
import FeeDialog from './FeeDialog';
import TermsContext from './TermsContext';

export default function CollectFees(props) {
  const { school } = props;
  const [currentRow, setCurrentRow] = React.useState(false);
  const [feeDialog, setFeeDialog] = React.useState(false);
  const { currentTerm } = React.useContext(TermsContext);

  const studentsData = useQuery(GET_FEES_STRUCTURE_STUDENTS, {
    variables: {
      termID: currentTerm,
    },
  });
  if (studentsData.loading) return <Loader />;
  console.log(currentTerm);
  const students = [];
  studentsData.data.fees_structure.map(f => {
    f.students_fees_structures.map(sf => {
      const s = sf.student;
      students.push({
        id: s.id,
        roll: s.roll,
        section: s.section.section,
        class: s.class.class,
        sections: `${s.class.id}:${s.section.id}`,
        name: s.name,
        phone: s.phone,
        registerNo: s.registerNo,
        feeCat: f.name ? f.name : '',
      });
      return s;
    });
    return f;
  });

  return (
    <Box>
      <Table
        columns={[
          { title: 'ID', field: 'id', hidden: true },
          { title: 'Name', field: 'name' },
          { title: 'Register No.', field: 'registerNo' },
          { title: 'Phone', field: 'phone' },
          { title: 'Fee Category', field: 'feeCat' },
          { title: 'Current Class', field: 'class' },
          { title: 'Current Section', field: 'section' },
          { title: 'Sections', field: 'sections', hidden: true },
          { title: 'Roll', field: 'roll', width: 20 },
        ]}
        data={students}
        title="Students"
        actions={[
          {
            icon: tableIcons.Money,
            tooltip: 'Collect Fees',
            onClick: (event, rowData) => {
              setCurrentRow(rowData);
              setFeeDialog(true);
            },
          },
        ]}
      />
      {feeDialog && (
        <FeeDialog
          open={feeDialog}
          handleConfirmClose={() => setFeeDialog(false)}
          student={currentRow}
          school={school}
        />
      )}
    </Box>
  );
}
