import React from 'react';
import { Container, Paper, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as Yup from 'yup';
import { useApolloClient } from '@apollo/client';
import SelectField from './SelectField';
import DatePicker from './DatePicker';
import UserContext from './UserContext';
import { GET_REPORT_QUERY } from '../resolvers/Queries';
import Loader from './Loader';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '100%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    height: 55,
  },
  form: {
    // maxWidth: '25%',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default function Report(props) {
  const { school, sections, type: reportType, years } = props;
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState('');
  const client = useApolloClient();

  if (loading) return <Loader />;
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Formik
            initialValues={{
              section: sections[0].value,
              currentTerm: school.termID,
              start: null,
              end: null,
              email: user.email,
              reportType,
            }}
            onSubmit={async values => {
              setLoading(true);

              values.start = values.start.toDate();
              values.end = values.end.toDate();

              const downloadLink = await client.query({
                query: GET_REPORT_QUERY,
                variables: {
                  data: {
                    ...values,
                    schoolID: school.id,
                    teacherID:
                      user.permissions !== 'SUPERADMIN'
                        ? user.id
                        : '70fb9650-1d68-468a-832d-ccb190efe9a6',
                  },
                },
                fetchPolicy: 'network-only',
              });

              setLink(`${downloadLink.data.report.link}`);
              setLoading(false);
            }}
            validationSchema={Yup.object().shape({
              section: Yup.string().required('Section Is Required'),
              currentTerm: Yup.string().required('Year Is Required'),
              start: Yup.date().required('Start Date is Required'),
              end: Yup.date().required('End Date is Required'),
              email: Yup.string()
                .email()
                .required('Email Is Required'),
              reportType: Yup.string().required('Required'),
            })}
          >
            {p => {
              const { errors, values, touched, handleBlur, handleChange } = p;

              return (
                <Form className={classes.form}>
                  <div style={{ flex: 0.25, padding: 10, marginTop: 10 }}>
                    <Field
                      name="start"
                      label="Start Date"
                      component={DatePicker}
                    />
                    <div style={{ marginTop: 10 }}>
                      <Field
                        name="end"
                        label="End Date"
                        minimum={values.start}
                        component={DatePicker}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 0.25, padding: 10 }}>
                    <Field
                      name="currentTerm"
                      component={SelectField}
                      options={years}
                      label="Select A Year"
                      zIndex={5}
                    />
                  </div>

                  <div style={{ flex: 0.25, padding: '4px 10px 10px 10px' }}>
                    <TextField
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email && errors.email}
                      margin="normal"
                      fullWidth
                      required
                      variant="outlined"
                    />
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      className={classes.button}
                      startIcon={<EmailIcon />}
                    >
                      Send File To Mail
                    </Button>
                  </div>
                  <div style={{ flex: 0.25, padding: '20px 10px 10px 10px' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={loading || !link}
                      className={classes.button}
                      target="_blank"
                      href={link}
                      startIcon={<GetAppIcon />}
                    >
                      Download File
                    </Button>
                    <div style={{ paddingTop: 10 }}>
                      {/* <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={loading || !link}
                        onClick={fileHandler}
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                      >
                        View Report
                      </Button> */}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {/* {link && viewFile && (
            
          )} */}
        </div>
      </Paper>
    </Container>
  );
}
