import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDialog(props) {
  const { open, handleConfirmClose, handleSubmit } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are You Sure You Want To Continue With This Action?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            THIS ACTION IS NOT REVERSIBLE. If you are not sure of what you are
            doing, PLEASE DO NOT CONTINUE! If in ANY DOUBT please contact vendor
            for support. This can not be undone and you could lose precious data
            FOREVER.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Go Back
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
