import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Divider } from '@material-ui/core';
import StudentForm from './StudentForm';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function AddStudent(props) {
  const classes = useStyles();
  const { school } = props;
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Typography component="h1" variant="h5">
            Add Student
          </Typography>
          <StudentForm update={false} school={school} student={{}} />
        </div>
      </Paper>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Typography className={classes.content} component="h1" variant="h5">
            Usage Guide
          </Typography>
          <Divider />
          <Typography className={classes.content} variant="body1" gutterBottom>
            1 - Students need to be added after the teachers and sections /
            subjects have been added.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            2 - The phone number added here be used to send all SMS. Please
            remove any spaces and extra characters from the number. Do not add
            the country code. The number should be exactly 10 digits long.
            Please do not leave it blank. Default Number will be 1234567890.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            3 - The Register Number is a school wide registration number and
            should be unique to each student and will be used to monitor and
            track the childs progress throughout the years at the school.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            4 - Students can be edited from the view students panel.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            5 - Roll numbers can also be adjusted here.
          </Typography>
        </div>
      </Paper>
    </Container>
  );
}
