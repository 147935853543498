import React from 'react';
import { useQuery } from '@apollo/client';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import { GET_SCHOOL_QUERY } from '../resolvers/Queries';
import Loader from '../components/Loader';
import AddClass from '../components/AddClass';
import ViewSectionSubjects from '../components/ViewSectionSubjects';
import BulkAddSections from '../components/BulkAddSections';
import UserContext from '../components/UserContext';
import ViewCurriculum from '../components/ViewCurriculum';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Classes() {
  const { user } = React.useContext(UserContext);
  const { data, loading } = useQuery(GET_SCHOOL_QUERY, {
    variables: { id: user.schoolID },
  });
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  if (loading) return <Loader />;
  return (
    <Layout>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          centered
          aria-label="full width tabs example"
        >
          <Tab label="View Classes" {...a11yProps(0)} />
          <Tab label="View Curriculum" {...a11yProps(1)} />
          <Tab label="Add Class/Section" {...a11yProps(2)} />
          <Tab label="Bulk Add" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <ViewSectionSubjects school={data.schools_by_pk} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <ViewCurriculum school={data.schools_by_pk} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <AddClass school={data.schools_by_pk} />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        {data.schools_by_pk.att_type !== 'SUBJECTWISE' && (
          <BulkAddSections school={data.schools_by_pk} />
        )}
        {data.schools_by_pk.att_type === 'SUBJECTWISE' && (
          <h1>Bulk Upload Subjects</h1>
        )}
      </TabPanel>
    </Layout>
  );
}
