import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  main: {
    display: 'flex',
    margin: 20,
    width: 250,
    height: 250,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    paddingLeft: 20,
  },
});
class QRCodePrint extends React.PureComponent {
  render() {
    const { student, classes } = this.props;
    return (
      <div className={classes.main}>
        <div>
          <Typography variant="h6" className={classes.text}>
            {student.name}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            className={classes.text}
          >
            {student.email}
          </Typography>
        </div>
        <img src={student.qrcode} alt="qrcode" />
      </div>
    );
  }
}

export default withStyles(styles)(QRCodePrint);
