import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/client';
import SelectField from './SelectField';
import {
  UPDATE_BOOK_MUTATION,
  CREATE_BOOK_MUTATION,
} from '../resolvers/Mutations';
import { GET_SUBJECTS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 400,
    paddingBottom: 50,
  },
  button: {
    position: 'relative',
    top: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}));
export default function BookForm(p) {
  const { update = false, subject, book = null, closeDialog } = p;
  const [createBook, createBookRes] = useMutation(CREATE_BOOK_MUTATION);
  const [updateBook, updateBookRes] = useMutation(UPDATE_BOOK_MUTATION);
  const classes = useStyles();
  if (createBookRes.loading || updateBookRes.loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        subject: subject.id,
        pages: update ? book.pages : 0,
        teachers: update ? book.books_teachers.map(t => t.user.id) : [],
        book: update ? book.name : '',
      }}
      onSubmit={async values => {
        if (update) {
          const c = {
            name: values.book,
            pages: values.pages,
          };
          const booksTeachers = values.teachers.map(t => ({
            teacherID: t,
            bookID: book.id,
          }));
          try {
            await updateBook({
              variables: {
                data: c,
                bookID: parseInt(book.id),
                booksTeachers,
              },
              refetchQueries: [
                {
                  query: GET_SUBJECTS_QUERY,
                  variables: {
                    sectionID: subject.sectionID,
                  },
                },
              ],
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          const c = {
            subjectID: values.subject,
            name: values.book,
            pages: values.pages,
          };
          const tIds = values.teachers.map(t => ({ teacherID: t }));
          c.books_teachers = { data: tIds };
          try {
            await createBook({
              variables: { data: c },
              refetchQueries: [
                {
                  query: GET_SUBJECTS_QUERY,
                  variables: {
                    sectionID: subject.sectionID,
                  },
                },
              ],
            });
          } catch (e) {
            console.log(e);
          }
        }
        closeDialog();
      }}
      validationSchema={Yup.object().shape({
        subject: Yup.number().required(),
        book: Yup.string().required(),
        teachers: Yup.array().required(),
        pages: Yup.number(),
      })}
    >
      {props => {
        const { values, touched, errors, handleChange, handleBlur } = props;
        const ts = {};
        subject.subject_teachers.map(u => {
          ts[u.user.email] = {
            label: `${u.user.name} | ${u.user.email}`,
            value: u.user.id,
          };
          return u;
        });
        const teachers = Object.values(ts);
        return (
          <Form className={classes.container}>
            <TextField
              label="Book Name"
              name="book"
              value={values.book}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.book && touched.book && errors.book}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <Field
              name="teachers"
              component={SelectField}
              options={teachers}
              label="Select Teacher/s"
              isMulti
              zIndex={4}
            />

            <TextField
              label="Total Pages"
              name="pages"
              value={values.pages}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.pages && touched.pages && errors.pages}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              {update ? 'Update Book' : 'Add Book'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
