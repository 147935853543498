import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    padding: '14px 14px',
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },

  button: {
    marginTop: 30,
  },
}));
const DatePicker = ({
  label,
  form: { setFieldValue },
  field: { value, name },
  maximum = '',
  minimum = '',
}) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        // disableToolbar
        format="DD/MM/YYYY"
        id="date-picker-inline"
        label={label}
        value={value}
        disableFuture
        fullWidth
        animateYearScrolling
        minDate={minimum !== '' ? minimum : undefined}
        maxDate={maximum !== '' ? maximum : undefined}
        inputVariant="outlined"
        onChange={v => {
          setFieldValue(name, v);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputProps={{
          className: classes.input,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
