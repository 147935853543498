import React from 'react';
import SchoolIcon from '@material-ui/icons/School';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import ClassIcon from '@material-ui/icons/Class';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
// import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import PublishIcon from '@material-ui/icons/Publish';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

const Routes = [
  {
    path: '/schools',
    name: 'Schools',
    icon: <SchoolIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN'],
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },
  {
    path: 'users',
    name: 'Users',
    icon: <PersonIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },
  {
    path: 'classes',
    name: 'Classes',
    icon: <ClassIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },
  // {
  //   path: 'subjects',
  //   name: 'Subjects',
  //   icon: <MenuBookIcon style={{ color: '#b4c9de' }} />,
  //   permissions: ['SUPERADMIN', 'ADMIN'],
  // },
  {
    path: 'students',
    name: 'Students',
    icon: <PeopleIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },

  // {
  //   path: 'attendance',
  //   name: 'Attendance',
  //   icon: <PlaylistAddCheckIcon style={{ color: '#b4c9de' }} />,
  //   permissions: ['SUPERADMIN', 'ADMIN'],
  // },

  {
    path: 'fees',
    name: 'Fees',
    icon: <AccountBalanceIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN', 'FEES'],
  },
  {
    path: 'expenses',
    name: 'Expense',
    icon: <EqualizerIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN', 'FEES'],
  },
  {
    path: 'buses',
    name: 'Buses',
    icon: <DirectionsBusIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },
  {
    path: 'reports',
    name: 'Reports',
    icon: <DescriptionIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },
  {
    path: 'settings',
    name: 'Settings',
    icon: <SettingsIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN', 'ADMIN'],
  },
  {
    path: 'promotions',
    name: 'Promotions',
    icon: <PublishIcon style={{ color: '#b4c9de' }} />,
    permissions: ['SUPERADMIN'],
  },
];

export default Routes;
