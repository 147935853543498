import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Divider } from '@material-ui/core';
import ClassSectionSubject from './ClassSectionSubject';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function AddClass(props) {
  const classes = useStyles();
  const { school } = props;
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Typography component="h1" variant="h5">
            Add/Update : Class/Section/Subject
          </Typography>
          <ClassSectionSubject
            update={false}
            school={school}
            data={{}}
            closeMain=""
          />
        </div>
      </Paper>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Typography className={classes.content} component="h1" variant="h5">
            Usage Guide
          </Typography>
          <Divider />
          <Typography className={classes.content} variant="body1" gutterBottom>
            1 - Users need to be added first before classes can be added.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            2 - The phone number added here will be the user's password. Please
            remove any spaces and extra characters from the number. Do not add
            the country code. The number should be exactly 10 digits long.
            Default password is 1234567890.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            3 - Please enter a valid email address to enable password reset. If
            an email does not exist please create one with this format:
            admin.[nameOfSchool]@gmail.com. Like admin.demodaily@gmail.com. In
            case of deparment wise admin add a unique suffix to the email like
            admin.college.mech@gmail.com. This format can also be used
            teacher.first.a.schoolName@gmail.com
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            4 - User details can be changed from the View User Panel.
          </Typography>
          <Typography className={classes.content} variant="body1" gutterBottom>
            5 - Departments and classes can only be assigned to HODs after the
            classes have been created. For all deparment / classes admin access
            leave the dropdown blank.
          </Typography>
        </div>
      </Paper>
    </Container>
  );
}
