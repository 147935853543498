import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import Loader from './Loader';
import { GET_USERS_QUERY } from '../resolvers/Queries';
import Table, { tableIcons } from './Table';
import EditUserDialog from './EditUserDialog';
import {
  DELETE_USER_MUTATION,
  TOGGLE_USER_MUTATION,
} from '../resolvers/Mutations';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import UserContext from './UserContext';

export default function ViewUsers() {
  const { user } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [currentUser, setcurrentUser] = React.useState({});
  const [deleteUser] = useMutation(DELETE_USER_MUTATION);
  const [toggleUser] = useMutation(TOGGLE_USER_MUTATION);

  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };
  const usersData = useQuery(GET_USERS_QUERY, {
    variables: { id: user.schoolID },
  });
  if (usersData.loading) return <Loader />;
  const users = usersData.data.users.map((u, index) => {
    const tmp = {
      id: u.id,
      fbID: u.fbID,
      sr: index + 1,
      name: u.name,
      email: u.email,
      permission: u.permissions,
      phone: u.phone,
      disabled: u.disabled,
      schoolID: u.schoolID,
      classes: u.classes_admins.map(c => c.class.id),
    };
    return tmp;
  });
  return (
    <Box>
      <Table
        columns={[
          { title: 'Sr.No.', field: 'sr', width: 30 },
          { title: 'ID', field: 'id', hidden: true },
          { title: 'fbID', field: 'fbID', hidden: true },
          { title: 'schoolID', field: 'schoolID', hidden: true },
          { title: 'Name', field: 'name' },
          { title: 'Phone', field: 'phone' },
          { title: 'Email', field: 'email' },
          { title: 'Permissions', field: 'permission' },
          {
            title: 'Enabled',
            field: 'disabled',
            render: rowData => (
              <span>{rowData.disabled ? 'Disabled' : 'Enabled'}</span>
            ),
          },
        ]}
        data={users}
        title="USERS"
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: 'Edit User',
            onClick: (event, rowData) => {
              setcurrentUser(rowData);
              setOpen(true);
            },
          },
          rowData => ({
            icon: rowData.disabled ? tableIcons.Enable : tableIcons.Disable,
            tooltip: rowData.disabled ? 'Enable User' : 'Disable User',
            onClick: async (event, d) => {
              await toggleUser({
                variables: {
                  id: d.id,
                  cols: { disabled: !d.disabled },
                },
                refetchQueries: [
                  {
                    query: GET_USERS_QUERY,
                    variables: { id: user.schoolID },
                  },
                ],
              });
              enqueueSnackbar(
                rowData.disabled
                  ? `User Has Been Successfully Enabled`
                  : `User Has Been Successfully Disabled`,
                {
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }
              );
            },
          }),
          {
            icon: tableIcons.Delete,
            tooltip: 'Permanently Delete User',
            onClick: async (event, rowData) => {
              setcurrentUser(rowData);
              setConfirmOpen(true);
            },
          },
        ]}
      />

      <EditUserDialog
        open={open}
        handleClose={handleClose}
        data={currentUser}
      />
      <ConfirmDeleteDialog
        open={confirmOpen}
        handleConfirmClose={handleConfirmClose}
        data={currentUser}
        deleteUser={deleteUser}
      />
    </Box>
  );
}
