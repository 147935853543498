import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import Layout from '../components/Layout';
import Authorize from './Authorize';
import { EXPENSE_CAT_QUERY } from '../resolvers/Queries';
import UserContext from '../components/UserContext';
import Loader from '../components/Loader';
import SchoolContext from '../components/SchoolContext';
import AddExpenseCategory from '../components/AddExpenseCategory';
import AddExpense from '../components/AddExpense';
import ViewExpenseRange from '../components/ViewExpenseRange';

const useStyles = makeStyles(theme => ({
  paper: {},
  flexDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  },
  button: {
    height: 50,
    width: '100%',
  },
  box: {
    marginTop: 10,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Expenses() {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { user } = React.useContext(UserContext);
  const { school } = React.useContext(SchoolContext);

  const permissions = ['SUPERADMIN', 'ADMIN', 'FEES'];
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  const expenseCatsData = useQuery(EXPENSE_CAT_QUERY, {
    variables: {
      schoolID: school.id,
    },
  });
  if (expenseCatsData.loading) return <Loader />;
  const expCats = expenseCatsData.data.expense_cats.map((e, index) => ({
    name: e.category,
    id: e.id,
    sr: index + 1,
  }));
  return (
    <Layout>
      <Authorize pms={permissions}>
        <AppBar position="static" color="default" className={classes.box}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            centered
            aria-label="full width tabs example"
          >
            <Tab label="Enter Expenses" {...a11yProps(0)} />
            <Tab label="View Expenses" {...a11yProps(1)} />
            <Tab label="Expense Categories" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <AddExpense school={school} user={user} expCats={expCats} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ViewExpenseRange school={school} user={user} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <AddExpenseCategory school={school} user={user} expCats={expCats} />
        </TabPanel>
      </Authorize>
    </Layout>
  );
}
