import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import { useSnackbar } from 'notistack';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TextField, InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  STUDENT_FEES_AGG_QUERY,
  GET_CLEARING_CHEQUES,
  RECEIPT_QUERY,
} from '../resolvers/Queries';
import Loader from './Loader';
import {
  ADD_PAYMENT_MUTATION,
  FEES_COLLECTED_MUTATION,
  UPDATE_FEES_COLLECTED,
} from '../resolvers/Mutations';
import UserContext from './UserContext';
import ConfirmFees from './ConfirmFees';
import ConfirmDiscount from './ConfirmDiscount';
import Cheque from './Cheque';
import ErrorDialog from './ErrorDialog';
import TermsContext from './TermsContext';
import DeleteDiscountDialog from './DeleteDiscountDialog';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: 18,
  },
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const useStyle = makeStyles(() => ({
  button: {
    color: '#FFF',
  },
  payment: {
    marginBottom: 100,
  },
}));
export default function FeeDialog(props) {
  const { open, handleConfirmClose, student, school } = props;
  const { user } = React.useContext(UserContext);
  const { terms, currentTerm } = React.useContext(TermsContext);
  const classes = useStyle();
  const [fees, setFees] = React.useState({});
  const [print, setPrint] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [discount, setDiscount] = React.useState(false);
  const [deleteDiscount, setDeleteDiscount] = React.useState(false);
  const [error, setError] = React.useState('');
  const [cheque, setCheque] = React.useState(false);
  const [chequeNo, setChequeNo] = React.useState('');
  const [totalToPay, setTotalToPay] = React.useState(0);
  const [date, setDate] = React.useState(moment());
  const componentRef = React.useRef();
  const receiptRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const studentFeeData = useQuery(STUDENT_FEES_AGG_QUERY, {
    variables: {
      schoolID: school.id,
      termID: currentTerm,
      studentID: student.id,
    },
    fetchPolicy: 'network-only',
  });
  const clearingChequesData = useQuery(GET_CLEARING_CHEQUES, {
    variables: {
      termID: currentTerm,
      studentID: student.id,
    },
    fetchPolicy: 'network-only',
  });
  const [collectFees, collectFeesData] = useMutation(FEES_COLLECTED_MUTATION);
  const [clearCheque, clearChequeData] = useMutation(UPDATE_FEES_COLLECTED);
  const [reciept, setReciept] = React.useState({});
  const [feesCollected, setFeesCollected] = React.useState([]);
  const client = useApolloClient();
  const printReciept = useReactToPrint({
    content: () => receiptRef.current,
  });
  const [collectPayment, collectPaymentData] = useMutation(
    ADD_PAYMENT_MUTATION
  );
  React.useEffect(() => {
    const tmpTotalToPay = Object.values(fees).reduce(
      (total, n) => total + n.pendingAmount,
      0
    );
    setTotalToPay(tmpTotalToPay);
  }, [fees]);
  const confirmCollectedDiscount = async () => {
    const data = [];
    let total = totalToPay;

    try {
      Object.values(fees).map(f => {
        let currentFeeAmount;
        if (total === 0) {
          return f;
        }
        if (total > f.pendingAmount) {
          currentFeeAmount = f.pendingAmount;
          total -= currentFeeAmount;
        } else {
          currentFeeAmount = total;
          total -= currentFeeAmount;
        }

        data.push({
          feeID: f.feesID,
          schoolID: school.id,
          studentID: student.id,
          termID: currentTerm,
          userID: user.id,
          amount: currentFeeAmount,
          feesStatus: 'DISCOUNT',
          chequeNo: '',
          collectedAt: date,
        });
        return f;
      });
      await collectFees({
        variables: { data },
      });
      await studentFeeData.refetch();
      setFees({});
    } catch (e) {
      console.log(e);
    }
  };
  const confirmedCollectFees = async shouldPrint => {
    const data = [];
    let total = totalToPay;
    if (chequeNo.length > 0 && chequeNo.length < 6) {
      enqueueSnackbar(`Cheque Number Should Be Atleast 6 Digits Long!`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
      return;
    }
    try {
      if (Object.values(fees).length === 0) {
        enqueueSnackbar(`No Fees Selected`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
        return;
      }
      Object.values(fees)
        // eslint-disable-next-line no-nested-ternary
        .sort((x, y) => (x === y ? 0 : x ? 1 : -1))
        .map(f => {
          let currentFeeAmount;
          if (total === 0) {
            return f;
          }
          if (f.fixedAmount) {
            if (total > f.pendingAmount) {
              currentFeeAmount = f.pendingAmount;
              total -= currentFeeAmount;
            } else {
              currentFeeAmount = total;
              total -= currentFeeAmount;
            }
          } else {
            currentFeeAmount = total;
            total -= currentFeeAmount;
          }
          data.push({
            feeID: f.feesID,
            schoolID: school.id,
            studentID: student.id,
            termID: currentTerm,
            userID: user.id,
            amount: currentFeeAmount,
            // eslint-disable-next-line no-nested-ternary
            feesStatus: chequeNo
              ? 'CLEARING'
              : currentFeeAmount === f.pendingAmount || f.pendingAmount === 0
              ? 'PAID'
              : 'PART_PAID',
            chequeNo: chequeNo || '',
            collectedAt: date,
          });
          return f;
        });
      // Save payment and get payment ID
      const paymentID = await collectPayment({
        variables: {
          data: {
            schoolID: school.id,
            studentID: student.id,
            termID: currentTerm,
            amount: totalToPay,
            collectedAt: date,
            fees_collecteds: {
              data,
            },
          },
        },
      });
      const receiptD = await client.query({
        query: RECEIPT_QUERY,
        variables: {
          paymentID: paymentID.data.insert_payments_one.id,
        },
      });

      const ids = paymentID.data.insert_payments_one.fees_collecteds;
      const fData = await studentFeeData.refetch();
      await clearingChequesData.refetch();

      setFees({});

      if (shouldPrint) {
        setReciept({
          id: receiptD.data.receipt_view[0].receiptno,
          amount: receiptD.data.receipt_view[0].amount,
        });
        const fCollected = [];
        ids.map(i => {
          const { feeID } = i;
          fData.data.students_fees_terms_agg.map(f => {
            if (f.feesID === feeID) {
              fCollected.push(f);
            }
            return f;
          });
          return i;
        });
        setFeesCollected(fCollected);
        printReciept();
        setFeesCollected([]);
        setReciept({});
      }
    } catch (e) {
      console.log(e);
    }
  };
  let totalPaid = 0;
  let pending = 0;
  const discounts = {
    title: `Select Discounts to DELETE For ${student.name} - ${
      terms.filter(t => t.id === currentTerm)[0].name
    } - ${school.name} -
    Fees Category : ${student.feeCat}`,
    items: [],
    schoolID: school.id,
    studentID: student.id,
    currentTerm,
  };
  if (
    studentFeeData.loading ||
    collectFeesData.loading ||
    clearChequeData.loading ||
    clearingChequesData.loading ||
    collectPaymentData.loading
  )
    return <Loader />;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleConfirmClose}>
          <span style={{ paddingLeft: 25, fontSize: 18 }}>
            Collect Fees For {student.name} -{' '}
            {terms.filter(t => t.id === currentTerm)[0].name} - {school.name} -
            Fees Category : {student.feeCat}
          </span>
        </DialogTitle>
        <DialogContent>
          {studentFeeData.data.students_fees_terms_agg.length > 0 &&
          studentFeeData.data.students_fees_terms_agg[0].feesID ? (
            <>
              {/* Cheque Clearing Box */}
              {clearingChequesData.data.clearing_cheques.length > 0 && (
                <div
                  style={{
                    paddingRight: 20,
                    paddingLeft: 20,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingRight: 20,
                      border: '1px solid grey',
                      padding: 5,
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    <span>Cheque No</span>
                    <span>Date Submitted</span>
                    <span>Amount</span>
                    <span>Status</span>
                    <span>Action</span>
                  </div>
                  {clearingChequesData.data.clearing_cheques.map((f, index) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: 20,
                        border: '1px solid grey',
                        padding: 5,
                      }}
                      key={`clearing-${index}`}
                    >
                      <span>{f.chequeNo}</span>
                      <span>
                        {moment(f.collectedAt).format('DD/MM/YY hh:mm a')}
                      </span>
                      <span>{f.paidAmount}</span>
                      <span>CLEARING</span>
                      <div
                        style={{
                          flex: 'display',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Mark Cheque As Cleared">
                          <CheckBoxIcon
                            color="secondary"
                            style={{ fontSize: 18 }}
                            onClick={async () => {
                              const mutationPromises = [];
                              f.collectedObjs.split('|').map(o => {
                                mutationPromises.push(
                                  clearCheque({
                                    variables: {
                                      collectedIDs: o.split(':')[0],
                                      cols: {
                                        feesStatus:
                                          parseInt(o.split(':')[1]) === 0
                                            ? 'PAID'
                                            : 'PART_PAID',
                                      },
                                    },
                                  })
                                );
                                return o;
                              });
                              await Promise.all(mutationPromises);
                              await clearingChequesData.refetch();
                              await studentFeeData.refetch();
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Mark Cheque As Failed">
                          <CancelPresentationIcon
                            color="secondary"
                            style={{ fontSize: 18 }}
                            onClick={async () => {
                              const mutationPromises = [];
                              f.collectedObjs.split('|').map(o => {
                                mutationPromises.push(
                                  clearCheque({
                                    variables: {
                                      collectedIDs: o.split(':')[0],
                                      cols: {
                                        feesStatus: 'FAILED',
                                      },
                                    },
                                  })
                                );
                                return o;
                              });
                              await Promise.all(mutationPromises);
                              await clearingChequesData.refetch();
                              await studentFeeData.refetch();
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* End Cheque Clearing Box */}

              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ color: '#FFF', marginLeft: 20 }}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
                documentTitle={`FEES-CHART-${student.name}`}
                onBeforeGetContent={() => setPrint(true)}
                onAfterPrint={() => setPrint(false)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ color: '#FFF', marginLeft: 20 }}
                onClick={() => {
                  setDeleteDiscount(true);
                }}
              >
                Remove Discounts
              </Button>
              <div
                style={
                  school.images[0] || (print && school.images[0])
                    ? {
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginTop: 5,
                        // background: `url(${school.images[0].url}) no-repeat center`,
                        // backgroundSize: `contain`,
                      }
                    : {
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 20,
                      }
                }
                ref={componentRef}
              >
                {print && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexGrow: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        flex: 0.75,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 28,
                          fontWeight: 'bold',
                        }}
                      >
                        {school.name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: 28,
                          fontWeight: 'bold',
                        }}
                      >
                        {terms.filter(t => t.id === currentTerm)[0].name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {student.name}
                      </span>
                      <br />
                    </div>
                    <div
                      style={{
                        flex: 0.25,
                      }}
                    >
                      {school.images[0] && (
                        <img
                          src={school.images[0].url}
                          alt=""
                          height={100}
                          width={100}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: 20,
                    border: '1px solid grey',
                    padding: 5,
                    fontWeight: 'bold',
                    fontSize: 12,
                    textTransform: 'uppercase',
                  }}
                >
                  <span style={{ flex: 0.2 }}>Fee Name</span>
                  <span style={{ flex: 0.15 }}>Due</span>
                  <span style={{ flex: 0.15 }}>Amount</span>
                  <span style={{ flex: 0.2 }}>Status</span>
                  <span style={{ flex: 0.2 }}>Cheque No</span>
                  <span style={{ flex: 0.2 }}>Paid On</span>
                  <span style={{ flex: 0.2 }}>Collected By</span>
                  <span style={{ flex: 0.15 }}>Pending</span>
                  {!print && (
                    <span
                      style={{ flex: 0.05, position: 'relative', left: -20 }}
                    >
                      Action
                    </span>
                  )}
                </div>
                {studentFeeData.data.students_fees_terms_agg.map((s, index) => {
                  pending += s.fixedAmount ? s.pendingAmount : 0;
                  totalPaid += s.collectedAmount; // s.fixedAmount ? s.collectedAmount : 0;
                  return (
                    <div
                      key={`div${index}`}
                      style={
                        print
                          ? {
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingRight: 20,
                              border: '1px solid grey',
                              padding: 2,
                              fontSize: '8 !important',
                            }
                          : {
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingRight: 20,
                              border: '1px solid grey',
                              padding: 5,
                            }
                      }
                    >
                      <span style={{ flex: 0.2 }}>{s.feeName}</span>
                      <span style={{ flex: 0.15 }}>
                        {s.month || 'One Time'}
                      </span>
                      <span style={{ flex: 0.15 }}>&#8377; {s.amount}</span>

                      <span style={{ flex: 0.2 }}>
                        {s.feesStatuses.map((a, i) => {
                          if (a === 'DISCOUNT') {
                            const tmpDiscount = {
                              id: s.collectedIDs[i],
                              status: a,
                              amount: s.collectedAmounts[i],
                              collectedAt: s.collectedAt[i],
                              collectedBy: s.collectedBy[i],
                            };
                            discounts.items.push(tmpDiscount);
                          }
                          return (
                            <React.Fragment key={`status-${i}`}>
                              {a
                                ? `${a} : ₹ ${s.collectedAmounts[i]}`
                                : 'PENDING'}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </span>
                      <span style={{ flex: 0.2 }}>
                        {s.chequeNos.map((a, i) => (
                          <React.Fragment key={`cheqeue-${i}`}>
                            {a || ''}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.2 }}>
                        {s.collectedAt.map((a, i) => (
                          <React.Fragment key={`collectedAt-${i}`}>
                            {a ? `${moment(a).format('DD/MM/YY hh:mm a')}` : ''}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.2 }}>
                        {s.collectedBy.map((a, i) => (
                          <React.Fragment key={`collectedBy-${i}`}>
                            {a ? `${a}` : ''}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.15, fontWeight: 'bold' }}>
                        &#8377; {s.fixedAmount ? s.pendingAmount : 0}
                      </span>
                      <span
                        // style={{ width: 20, position: 'relative', left: -20 }}
                        style={{ flex: 0.05, cursor: 'pointer' }}
                      >
                        {!print &&
                          (!s.fixedAmount || s.pendingAmount > 0) &&
                          !fees[s.slug] && (
                            <AddCircleIcon
                              color="secondary"
                              onClick={() => {
                                const newFees = { ...fees };
                                newFees[s.slug] = s;
                                setFees(newFees);
                              }}
                            />
                          )}
                        {!print &&
                          (!s.fixedAmount || s.pendingAmount > 0) &&
                          fees[s.slug] && (
                            <RemoveIcon
                              color="secondary"
                              onClick={() => {
                                const newFees = { ...fees };
                                delete newFees[s.slug];
                                setFees(newFees);
                              }}
                            />
                          )}
                      </span>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <h4>Total Paid: &#8377; {totalPaid}</h4>
                  <h4>Pending: &#8377; {pending}</h4>
                </div>
                {!print && (
                  <>
                    <h3>Take Payment: &#8377; {totalToPay}</h3>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ flex: 0.2, padding: 0 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            // disableToolbar
                            format="DD/MM/YYYY"
                            id="date-picker-inline"
                            label="Date"
                            value={date}
                            disableFuture
                            fullWidth
                            animateYearScrolling
                            maxDate={moment()}
                            inputVariant="outlined"
                            onChange={v => {
                              setDate(v);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      {/* <div style={{ flex: 0.7, padding: 5, paddingRight: 0 }}> */}
                      <TextField
                        name="name"
                        value={totalToPay}
                        onChange={e => {
                          setTotalToPay(e.target.value);
                        }}
                        style={{
                          flex: 0.3,
                          padding: 0,
                          margin: 0,
                          paddingLeft: 10,
                        }}
                        margin="normal"
                        fullWidth
                        required
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              &#8377;
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* </div> */}
                    </div>
                  </>
                )}
                {totalToPay > 0 && !print && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        const fixedAmountExists = Object.values(fees).filter(
                          f => !f.fixedAmount
                        );
                        const tmpTotalToPay = Object.values(fees).reduce(
                          (total, n) => total + n.pendingAmount,
                          0
                        );
                        if (
                          totalToPay > tmpTotalToPay &&
                          fixedAmountExists.length < 1
                        ) {
                          setError(
                            'The collecting amount is greater than the total of fees collected'
                          );
                        } else {
                          setChequeNo('');
                          setConfirm(true);
                        }
                      }}
                    >
                      Take Cash Payment
                    </Button>
                    <div style={{ paddingLeft: 20 }} />
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        const fixedAmountExists = Object.values(fees).filter(
                          f => !f.fixedAmount
                        );
                        const tmpTotalToPay = Object.values(fees).reduce(
                          (total, n) => total + n.pendingAmount,
                          0
                        );
                        if (
                          totalToPay > tmpTotalToPay &&
                          fixedAmountExists.length < 1
                        ) {
                          setError(
                            'The collecting amount is greater than the total of fees collected'
                          );
                        } else {
                          setChequeNo('');
                          setCheque(true);
                        }
                      }}
                    >
                      Take Cheque Payment
                    </Button>
                    <div style={{ paddingLeft: 20 }} />

                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        const fixedAmountExists = Object.values(fees).filter(
                          f => !f.fixedAmount
                        );
                        if (fixedAmountExists.length > 0) {
                          setError(
                            'Non Fixed Amounts Can Not Be Given Discount.'
                          );
                        } else {
                          setChequeNo('');
                          setDiscount(true);
                        }
                      }}
                    >
                      Give Discount
                    </Button>
                  </div>
                )}
                {print && <h4>Signature and School Stamp</h4>}
                <div style={{ marginBottom: 200 }} />
              </div>
              {feesCollected.length > 0 && (
                <div
                  style={
                    school.images[0]
                      ? {
                          paddingLeft: 20,
                          paddingRight: 20,
                          minHeight: '100%',
                          marginTop: 20,
                          // background: `url(${school.images[0].url}) no-repeat center`,
                          // backgroundSize: `contain`,
                        }
                      : {
                          paddingLeft: 20,
                          paddingRight: 20,
                          marginTop: 20,
                          minHeight: '100%',
                        }
                  }
                  ref={receiptRef}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexGrow: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ flex: 0.75 }}>
                      <span
                        style={{
                          fontSize: 28,
                          fontWeight: 'bold',
                        }}
                      >
                        {school.name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: 28,
                          fontWeight: 'bold',
                        }}
                      >
                        {terms.filter(t => t.id === currentTerm)[0].name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {student.name}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Receipt No: {reciept.id}
                      </span>
                      <br />
                    </div>
                    <div style={{ flex: 0.25 }}>
                      {school.images[0] && (
                        <img
                          src={school.images[0].url}
                          alt=""
                          height={100}
                          width={100}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingRight: 20,
                      border: '1px solid grey',
                      padding: 5,
                      fontWeight: 'bold',
                      fontSize: 12,
                      textTransform: 'uppercase',
                    }}
                  >
                    <span style={{ flex: 0.2 }}>Fee Name</span>
                    <span style={{ flex: 0.15 }}>Type</span>
                    <span style={{ flex: 0.15 }}>Amount</span>
                    <span style={{ flex: 0.2 }}>Status</span>
                    <span style={{ flex: 0.2 }}>Cheque No</span>
                    <span style={{ flex: 0.2 }}>Paid On</span>
                    <span style={{ flex: 0.2 }}>Collected By</span>
                    <span style={{ flex: 0.15 }}>Pending</span>
                  </div>
                  {feesCollected.map((s, index) => (
                    <div
                      key={`div${index}`}
                      style={
                        print
                          ? {
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingRight: 20,
                              border: '1px solid grey',
                              padding: 2,
                              fontSize: '8 !important',
                            }
                          : {
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              paddingRight: 20,
                              border: '1px solid grey',
                              padding: 5,
                            }
                      }
                    >
                      <span style={{ flex: 0.2 }}>{s.feeName}</span>
                      <span style={{ flex: 0.15 }}>
                        {s.month || 'One Time'}
                      </span>
                      <span style={{ flex: 0.15 }}>&#8377; {s.amount}</span>

                      <span style={{ flex: 0.2 }}>
                        {s.feesStatuses.map((a, i) => (
                          <React.Fragment key={`status-${i}`}>
                            {a
                              ? `${a} : ₹ ${s.collectedAmounts[i]}`
                              : 'PENDING'}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.2 }}>
                        {s.chequeNos.map((a, i) => (
                          <React.Fragment key={`cheqeue-${i}`}>
                            {a || ''}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.2 }}>
                        {s.collectedAt.map((a, i) => (
                          <React.Fragment key={`collectedAt-${i}`}>
                            {a ? `${moment(a).format('DD/MM/YY hh:mm a')}` : ''}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.2 }}>
                        {s.collectedBy.map((a, i) => (
                          <React.Fragment key={`collectedBy-${i}`}>
                            {a ? `${a}` : ''}
                            <br />
                          </React.Fragment>
                        ))}
                      </span>
                      <span style={{ flex: 0.15, fontWeight: 'bold' }}>
                        &#8377; {s.amount - s.collectedAmount}
                      </span>
                    </div>
                  ))}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h4>Total Paid: &#8377; {reciept.amount}</h4>
                    <h4>Total Paid This Year: &#8377; {totalPaid}</h4>
                    <h4>Pending: &#8377; {pending}</h4>
                  </div>
                  <h4>Signature and School Stamp</h4>
                </div>
              )}
            </>
          ) : (
            <Typography component="h1" variant="h5">
              Looks like there are no fees assigned to this class
            </Typography>
          )}
        </DialogContent>
      </Dialog>
      <ConfirmFees
        open={confirm}
        handleConfirmClose={() => setConfirm(false)}
        handleSubmit={shouldPrint => {
          confirmedCollectFees(shouldPrint);
          setConfirm(false);
        }}
        amount={totalToPay}
      />
      <Cheque
        open={cheque}
        handleConfirmClose={() => setCheque(false)}
        amount={totalToPay}
        chequeNo={chequeNo}
        setChequeNo={setChequeNo}
        confirm={shouldPrint => {
          confirmedCollectFees(shouldPrint);
          setCheque(false);
        }}
      />
      <ConfirmDiscount
        open={discount}
        handleConfirmClose={() => setDiscount(false)}
        handleSubmit={() => {
          confirmCollectedDiscount();
          setDiscount(false);
        }}
        amount={totalToPay}
      />
      <ErrorDialog
        open={error.length > 0}
        handleConfirmClose={() => setError(false)}
        error={error}
      />
      <DeleteDiscountDialog
        open={deleteDiscount}
        handleConfirmClose={() => setDeleteDiscount(false)}
        data={discounts}
      />
    </div>
  );
}
