import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { GET_FEES_STRUCTURE_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
import { DELETE_FEE_STRUCTURE_MUTATION } from '../resolvers/Mutations';
import TermsContext from './TermsContext';

export default function ViewFeesStructures(props) {
  const { user } = props;
  const { currentTerm } = React.useContext(TermsContext);

  const feesStructureData = useQuery(GET_FEES_STRUCTURE_QUERY, {
    variables: { termID: currentTerm },
    fetchPolicy: 'network-only',
  });
  const [deleteFeesStructure, deleteFeesStructureData] = useMutation(
    DELETE_FEE_STRUCTURE_MUTATION
  );
  if (feesStructureData.loading || deleteFeesStructureData.loading)
    return <Loader />;
  const feesCats = feesStructureData.data.fees_structure.map((e, index) => ({
    name: e.name,
    id: e.id,
    accName: e.account ? e.account.name : '',
    sr: index + 1,
  }));
  return (
    <Box>
      <Table
        columns={[
          { title: 'Sr', field: 'sr', width: 20 },
          { title: 'id', field: 'id', hidden: true },
          { title: 'Name', field: 'name' },
          { title: 'Account', field: 'accName' },
        ]}
        data={feesCats}
        title="Fees Categories"
        actions={
          user.permissions === 'SUPERADMIN'
            ? [
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Delete User',
                  onClick: async (event, rowData) => {
                    await deleteFeesStructure({
                      variables: {
                        id: rowData.id,
                      },
                    });
                    await feesStructureData.refetch();
                  },
                },
              ]
            : []
        }
      />
    </Box>
  );
}
