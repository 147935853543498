import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const MyTimePicker = ({
  label,
  form: { setFieldValue },
  field: { value, name },
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <KeyboardTimePicker
      margin="normal"
      name={name}
      label={label}
      KeyboardButtonProps={{
        'aria-label': 'change time',
      }}
      onChange={v => {
        setFieldValue(name, v);
      }}
      value={value}
    />
  </MuiPickersUtilsProvider>
);
export default MyTimePicker;
