import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Layout from '../components/Layout';
import SchoolContext from '../components/SchoolContext';
import FeesMonthly from '../components/charts/FeesMonthly';
import FeesPanels from '../components/dashboard/FeesPanels';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 10,
    margin: 10,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
export default function Dashboard() {
  const { school } = React.useContext(SchoolContext);
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <FeesPanels school={school} />
          <Grid item xs={12}>
            {school.feesModule && (
              <Paper className={classes.paper}>
                <FeesMonthly />
              </Paper>
            )}
          </Grid>
          <Grid item xs={2}>
            {/* <Paper className={classes.paper}>Shortcuts</Paper> */}
          </Grid>
          <Grid item xs={5}>
            {/* <Paper className={classes.paper}>
              Class Wise Fees Collected With Pending
            </Paper> */}
          </Grid>
          <Grid item xs={5}>
            {/* <Paper className={classes.paper}>Payment Modes Of Collection</Paper> */}
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}
