import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/client';
import {
  UPDATE_CLASS_MUTATION,
  CREATE_CLASS_MUTATION,
} from '../resolvers/Mutations';
import { CLASSES_QUERY } from '../resolvers/Queries';
import Loader from './Loader';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: 30,
  },
}));
export default function ClassForm(p) {
  const { update, cl, school, closeDialog, closeMain } = p;
  const [createClass, createClassRes] = useMutation(CREATE_CLASS_MUTATION);
  const [updateClass, updateClassRes] = useMutation(UPDATE_CLASS_MUTATION);
  const classes = useStyles();
  if (createClassRes.loading || updateClassRes.loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        class: update ? cl.class : '',
      }}
      onSubmit={async values => {
        if (update) {
          await updateClass({
            variables: {
              data: { class: values.class },
              classID: cl.classID,
            },
            refetchQueries: [
              {
                query: CLASSES_QUERY,
                variables: {
                  schoolID: school.id,
                  termID: school.termID,
                },
              },
            ],
          });
        } else {
          const c = {
            class: values.class,
            termID: school.termID,
            schoolID: school.id,
          };
          await createClass({
            variables: { data: c },
            refetchQueries: [
              {
                query: CLASSES_QUERY,
                variables: {
                  schoolID: school.id,
                  termID: school.termID,
                },
              },
            ],
          });
        }
        closeDialog();
        closeMain();
      }}
      validationSchema={Yup.object().shape({
        class: Yup.string().required(),
      })}
    >
      {props => {
        const { values, touched, errors, handleChange, handleBlur } = props;
        return (
          <Form className={classes.container}>
            <TextField
              label="Class"
              name="class"
              value={values.class}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.class && touched.class && errors.class}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              {update ? 'Update Class' : 'Add Class'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
