import React from 'react';
import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
  GET_USERS_QUERY,
  CLASSES_QUERY,
  GET_SECTIONS_ONLY_QUERY,
  GET_SUBJECTS_QUERY,
} from '../resolvers/Queries';
import Loader from './Loader';
import ClassDialog from './ClassDialog';
import SectionDialog from './SectionDialog';
import UserContext from './UserContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginBottom: 10,
  },
}));

export default function ClassSectionSubject(pr) {
  const { update, school, data, closeMain } = pr;
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const teachers = useQuery(GET_USERS_QUERY, {
    variables: { id: user.schoolID },
  });

  const clsRes = useQuery(CLASSES_QUERY, {
    variables: {
      schoolID: user.schoolID,
      termID: school.termID,
    },
  });
  const secRes = useQuery(GET_SECTIONS_ONLY_QUERY, {
    variables: {
      id: user.schoolID,
      currentTerm: school.termID,
    },
    fetchPolicy: 'network-only',
  });
  const subRes = useQuery(GET_SUBJECTS_QUERY, {
    variables: {
      termID: school.termID,
    },
  });
  const [classDialog, setClassDialog] = React.useState(false);

  const [sectionDialog, setSectionDialog] = React.useState(false);
  // const [bookDialog, setBookDialog] = React.useState(false);
  const closeClassDialog = () => setClassDialog(false);
  const closeSectionDialog = () => setSectionDialog(false);

  const classData = update ? { class: data.class, classID: data.classID } : '';
  // eslint-disable-next-line no-nested-ternary
  const sectionData = update
    ? school.att_type === 'SUBJECTWISE'
      ? { section: data.section, sectionID: data.sectionID }
      : {
          section: data.section,
          sectionID: data.sectionID,
          teachers: data.teachers,
          notificationTime: data.notificationTime,
          sr: data.sr,
          class: data.classID,
        }
    : '';

  if (teachers.loading || clsRes.loading || secRes.loading || subRes.loading)
    return <Loader />;
  return (
    <>
      <h5>{update ? 'Update Class' : 'Add Class'}</h5>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={() => setClassDialog(true)}
      >
        {update ? 'Update Class' : 'Add Class'}
      </Button>
      <ClassDialog
        open={classDialog}
        handleClose={closeClassDialog}
        data={classData}
        update={update}
        school={school}
        closeMain={update ? closeMain : ''}
      />
      <h5>{update ? 'Update Section' : 'Add Section'}</h5>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={() => setSectionDialog(true)}
      >
        {update ? 'Update Section' : 'Add Section'}
      </Button>
      <SectionDialog
        open={sectionDialog}
        handleClose={closeSectionDialog}
        data={sectionData}
        update={update}
        school={school}
        closeMain={update ? closeMain : ''}
        cls={clsRes.data.classes}
        teachers={teachers.data.users}
      />
    </>
  );
}
