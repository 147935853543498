import React from 'react';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import { PENDING_FEES } from '../resolvers/Queries';
import Loader from './Loader';
import Table from './Table';
import TermsContext from './TermsContext';

export default function ViewPendingFeesEntries() {
  const { enqueueSnackbar } = useSnackbar();
  const [payments, setPayments] = React.useState([]);
  const { currentTerm } = React.useContext(TermsContext);

  const feesRes = useQuery(PENDING_FEES, {
    variables: { termID: currentTerm },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const tmpPayments = [];
      data.pending_fees_view.map((p, i) => {
        const tmp = {
          sr: i + 1,
          amount: p.amount,
          student: p.student.name,
          class: p.student.class.class,
          section: p.student.section.section,
          roll: p.student.roll,
          registerNo: p.student.registerNo,
        };
        tmpPayments.push(tmp);
        return tmp;
      });
      setPayments(tmpPayments);
    },
  });
  if (feesRes.loading) return <Loader />;
  if (feesRes.error) {
    enqueueSnackbar(
      `Something Went Wrong. Heres the error ${feesRes.error.message}`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }
  return (
    <Box>
      <Table
        columns={[
          { title: 'Sr.No.', field: 'sr', width: 20 },
          { title: 'Student', field: 'student' },
          { title: 'Class', field: 'class' },
          { title: 'Section', field: 'section' },
          { title: 'Roll', field: 'roll' },
          { title: 'GR.NO', field: 'registerNo' },
          {
            title: 'Amount',
            field: 'amount', // eslint-disable-next-line react/display-name
          },
        ]}
        data={payments}
        title="Fees Pending"
        actions={[]}
      />
    </Box>
  );
}
