import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Container,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { CREATE_STUDENTS_MUTATION } from '../resolvers/Mutations';
import { GET_SECTIONS_QUERY, GET_STUDENTS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import UploadData from './UploadData';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));
export default function BulkAddStudents(props) {
  const classes = useStyles();
  const { school } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [students, setStudents] = React.useState([]);
  const [submitEnabled, setsubmitEnabled] = React.useState(false);
  const [createStudents, createStudentsRes] = useMutation(
    CREATE_STUDENTS_MUTATION
  );
  const currentYearID = school.termID;
  const sectionsRes = useQuery(GET_SECTIONS_QUERY, {
    variables: {
      id: school.id,
      currentTerm: currentYearID,
    },
  });
  const formatName = name =>
    name
      .replace(/\s\s+/g, ' ')
      .replace(/\.(?=[^\s])/g, '. ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  const schema = Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      phone: Yup.string().length(10),
      roll: Yup.number().required(),
      registerNo: Yup.mixed(),
      section: Yup.string().required(),
      class: Yup.string().required(),
      gender: Yup.string(),
      birthdate: Yup.date(),
      fbID: Yup.string(),
      religion: Yup.string(),
    })
  );
  const studentsImport = [
    [
      'class',
      'section',
      'roll',
      'name',
      'phone',
      'registerNo',
      'gender',
      'birthdate',
      'religion',
      'termID',
      'createTermID',
      'fbid',
    ],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
  ];
  const cls = {};
  if (sectionsRes.data) {
    sectionsRes.data.sections.map(c => {
      const cl = c.class.class.toLowerCase().trim();
      const sc = c.section.toLowerCase().trim();
      if (typeof cls[cl] === 'undefined')
        cls[cl] = { id: c.class.id, sections: {} };
      if (typeof cls[cl].sections[sc] === 'undefined')
        cls[cl].sections[sc] = {
          id: c.id,
        };
      return c;
    });
  }

  const handleSubmit = async () => {
    const data = [];
    for (const st of students) {
      const tmp = {
        name: formatName(st.name),
        phone: st.phone,
        roll: parseInt(st.roll),
        registerNo: st.registerNo,
        createTermID: parseInt(st.createTermID),
        termID: parseInt(st.termID),
        disabled: false,
        gender: st.gender,
        religion: st.religion,
        fbID: st.fbID,
        schoolID: school.id,
        classID: parseInt(st.class),
        sectionID: parseInt(st.section),
      };
      if (st.birthdate) tmp.birthdate = st.birthdate;
      data.push(tmp);
    }
    try {
      await createStudents({
        variables: {
          data,
        },
        refetchQueries: [
          {
            query: GET_STUDENTS_QUERY,
            variables: {
              id: school.id,
              termID: currentYearID,
            },
          },
        ],
      });
      enqueueSnackbar(`Students Successfully Imported`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } catch (e) {
      enqueueSnackbar(`Error in Apollo Action. ${e.message}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
  };
  const handleForce = da => {
    const studentsArray = [];
    for (const student of da) {
      const s = {
        roll: parseInt(student.roll),
        registerNo: student.registerno || '',
        name: student.name,
        phone: student.phone || '1234567890',
        gender: student.gender?.toUpperCase() || 'BLANK',
        religion: student.religion || '',
        termID: student.termID
          ? school.terms.filter(t => t.name === student.termID)[0].id
          : currentYearID,
        createTermID: student.createTermID
          ? school.terms.filter(t => t.name === student.termID)[0].id
          : currentYearID,
        fbID: student.fbid || '',
      };
      if (student.birthdate)
        s.birthdate =
          moment(student.birthdate.replace('-', '/')) || student.birthdate;
      const cl = student.class.toLowerCase().trim();
      const sc = student.section.toLowerCase().trim();
      s.class = cls[cl] ? cls[cl].id : null;
      s.section = cls[cl].sections[sc] ? cls[cl].sections[sc].id : null;
      if (!s.class || !s.section) {
        enqueueSnackbar(
          `${student.class} or ${student.section} does not match any class.Please check again!`,
          {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }
        );
        return false;
      }
      studentsArray.push(s);
    }
    schema.isValid(studentsArray).then(async function (valid) {
      if (valid) {
        setStudents(studentsArray);
        setsubmitEnabled(true);
        enqueueSnackbar('File looks good. Please click submit to import.', {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
      } else {
        enqueueSnackbar(
          'Some field is not valid. Please Recheck The File And Try Again',
          {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }
        );
      }
    });
  };
  if (sectionsRes.loading || createStudentsRes.loading) return <Loader />;
  if (sectionsRes.error || createStudentsRes.error) {
    enqueueSnackbar(
      `Error in Apollo Action.Here's the error - ${sectionsRes.error.message ||
      createStudentsRes.error.message} `,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <UploadData
            template={studentsImport}
            filename="StudentsTemplate.csv"
            checkData={handleForce}
            title="Upload CSV file for Student import"
          />
          <Divider />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!submitEnabled}
          >
            Upload Students
          </Button>
        </div>
      </Paper>
      <Paper className={classes.wrapper}>
        <Typography className={classes.content} component="h1" variant="h5">
          Usage Guide
        </Typography>
        <Divider />
        <Typography className={classes.content} gutterBottom variant="body1">
          1 - Please use the Downaload Template button to download a template to
          add data to.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          2 - These are the required fields and cannot be left blank. Roll,
          Class, Section, Name, Phone. Other fields are optional and can be left
          blank. Any student having blank or invalid data will cause failure of
          import.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          3 - Teachers, Classes and Sections need to have been imported
          beforehand. If the exact name of the class or section has not been
          entered the the fields, there may be some unexpected issues. Import
          will probably fail.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          4 - Birthdate, if entered, need to be in this format: DD/MM/YYYY. For
          example 24/01/1985. Other formats will cuase the import to fail.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          5 - Please add ONLY ONE valid mobile phone number per student.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          6 - Gender should be one of the following: MALE, FEMALE, OTHER
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          7 - Old data import can be done here.
        </Typography>
      </Paper>
    </Container>
  );
}
