import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';
import Authorize from '../pages/Authorize';
import Loader from './Loader';
import { EXPENSES_RANGE_QUERY, GET_TERMS_QUERY } from '../resolvers/Queries';
import ViewExpenses from './ViewExpenses';
import DateRangePicker from './DateRangePicker';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper1: {
    width: '100%',
  },
  wrapper2: {
    width: '20%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function ViewExpenseRange(props) {
  const classes = useStyles();
  const { school, user } = props;
  const permissions = ['ADMIN', 'SUPERADMIN', 'FEES'];
  const [st, setSt] = React.useState(moment());
  const [ed, setEd] = React.useState(moment());
  const yearsData = useQuery(GET_TERMS_QUERY, {
    variables: { schoolID: school.id },
  });
  const [getExpenses, expensesData] = useLazyQuery(EXPENSES_RANGE_QUERY);
  const submit = async (start, end, termID) => {
    console.log(start, end);
    try {
      await getExpenses({
        variables: {
          termID,
          start,
          end,
        },
      });
      setSt(start);
      setEd(end);
    } catch (e) {
      console.log(e);
    }
  };

  if (expensesData.loading || yearsData.loading) return <Loader />;
  return (
    <Authorize pms={permissions}>
      <DateRangePicker
        school={school}
        years={yearsData.data.terms.map(t => ({
          value: t.id,
          label: t.name,
        }))}
        submit={submit}
      />
      {expensesData.data && expensesData.data.expenses && (
        <Container className={classes.main} maxWidth="xl" disableGutters>
          <Paper className={classes.wrapper1}>
            <div className={classes.content}>
              <div>
                <h2>
                  Total Expenses From {st.format('DD-MM-YYYY')} to{' '}
                  {ed.format('DD-MM-YYYY')}:
                  {` ₹ ${expensesData.data.expenses.reduce(
                    (total, b) => total + b.amount,
                    0
                  )}`}
                </h2>
                <ViewExpenses expensesData={expensesData} user={user} />
              </div>
            </div>
          </Paper>
        </Container>
      )}
    </Authorize>
  );
}
