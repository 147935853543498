import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
  button: {
    color: '#FFF',
  },
  payment: {
    marginBottom: 100,
  },
}));
export default function Cheque(props) {
  const {
    open,
    handleConfirmClose,
    chequeNo,
    setChequeNo,
    amount,
    confirm,
  } = props;
  const classes = useStyle();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          COLLECT CHEQUE OF AMOUNT &#8377;{amount}.
        </DialogTitle>
        <DialogContent>
          <>
            <span>
              Please make sure you have collected and verified the cheque of
              said amount as this record can not be edited or deleted after you
              click collect. The payment will be marked as clearing. It will
              have to be manually changed to paid once the cheque clears.
            </span>
            <TextField
              label="Cheque Number"
              name="chequeNo"
              value={chequeNo}
              onChange={e => setChequeNo(e.target.value)}
              margin="normal"
              fullWidth
              required
              autoComplete="off"
              variant="outlined"
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleConfirmClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => confirm(true)}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            COLLECT & PRINT
          </Button>
          <Button
            variant="contained"
            onClick={() => confirm(false)}
            className={classes.button}
            color="primary"
            autoFocus
          >
            COLLECT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
