import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function UpdatePasswordForm(pr) {
  const { user, updatePass } = pr;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      onSubmit={async values => {
        await updatePass({ id: user.id, password: values.password });
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .length(10)
          .required('Password should have 10 characters.'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], "Passwords don't match")
          .required('Confirm Password is required'),
      })}
    >
      {props => {
        const { values, touched, errors, handleChange, handleBlur } = props;
        return (
          <Form className={classes.container}>
            <TextField
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.password && touched.password && errors.password
              }
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label="Confirm"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              type="password"
              onBlur={handleBlur}
              helperText={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
            >
              Update Password
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
