/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import {
  Container,
  Typography,
  Paper,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import Copyright from '../components/Copyright';
import Loader from '../components/Loader';
import SchoolContext from '../components/SchoolContext';
import UserContext from '../components/UserContext';
import { GET_DAILY_CLASSES } from '../resolvers/Queries';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  title: {
    backgroundColor: '#0EABBC',
    fontFamily: 'Montserrat',
    color: '#FFF',
    textAlign: 'center',
    height: 70,
  },
}));
export default function Teacher() {
  const classes = useStyles();
  const history = useHistory();

  const { user } = React.useContext(UserContext);
  const { school } = React.useContext(SchoolContext);
  const [items, setItem] = React.useState([]);
  React.useEffect(() => {
    const localData = JSON.parse(localStorage.getItem('live'));
    if (localData) {
      const { startTime, multiple, names } = localData;
      const params = {
        attid: localData.attids,
        attIDandBookID: localData.attIDandBookID,
        repeat: true,
        startTime,
        multiple,
        names,
      };
      history.push('/teacherLive', params);
    }
  }, [history]);
  const attTime = moment()
    .utc()
    .startOf('day')
    .toISOString(true);
  const vars = {
    whereSection: {
      schoolID: { _eq: school.id },
      termID: { _eq: school.termID },
      section_teachers: { teacherID: { _eq: user.id } },
    },
    whereAtt: {
      attTime: { _eq: attTime },
    },
    whereSubject: {
      subject_teachers: { teacherID: { _eq: user.id } },
    },
    whereBook: {
      books_teachers: { teacherID: { _eq: user.id } },
    },
  };
  const { data, loading } = useQuery(GET_DAILY_CLASSES, {
    variables: vars,
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  });
  if (loading) return <Loader />;
  const sectionsListData = [];
  const sections = [];
  const subjects = [];
  const books = [];
  data.sections.map(s => {
    if (s.subjects && s.subjects.length > 0) {
      s.subjects.map(sub => {
        if (sub.books && sub.books.length > 0) {
          sub.books.map(b => {
            const tmpBook = {
              ...b,
              section: s,
              sub,
            };
            books.push(tmpBook);
            return b;
          });
        } else {
          const tmpSub = {
            ...sub,
            section: s,
          };

          subjects.push(tmpSub);
        }
        return sub;
      });
    } else {
      sections.push(s);
    }
    return s;
  });
  if (books.length) sectionsListData.push({ title: 'Books', data: books });
  if (subjects.length)
    sectionsListData.push({ title: 'Subjects', data: subjects });
  if (sections.length)
    sectionsListData.push({ title: 'Sections', data: sections });
  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h3" style={{ paddingTop: 10 }}>
          Classes
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <Typography
          variant="h4"
          style={{
            color: '#0EABBC',
            fontSize: 24,
            fontWeight: 'bold',
            fontFamily: 'Arial',
          }}
        >
          {moment().format('DD MMM YYYY')}
        </Typography>
        {items && items.length > 0 && (
          <ButtonGroup aria-label="button group" fullWidth size="large">
            <Button color="primary" onClick={() => setItem([])}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                const params = {
                  items,
                  activeTeacher: user,
                  startTime: moment().toISOString(),
                };
                history.push('/teacherLive', params);
              }}
            >
              Start Live
            </Button>
          </ButtonGroup>
        )}
        {sectionsListData && sectionsListData.length && (
          <List
            dense
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {sectionsListData.map((sec, index) => (
              <div key={index}>
                <ListItem style={{ padding: 0 }}>
                  <ListItemText
                    style={{
                      color: '#0EABBC',
                      backgroundColor: '#ECFCFD',
                      padding: 10,
                      height: 50,
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontSize: 22,
                        fontWeight: 'bold',
                        fontFamily: 'Arial',
                      }}
                    >
                      {sec.title}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <List component="div" disablePadding>
                  {sec.data.map(d => {
                    const title =
                      d.__typename === 'sections'
                        ? `${d.class.class} ${d.section}`
                        : d.__typename === 'subjects'
                        ? d.subject
                        : d.name;
                    const subtitle =
                      d.__typename === 'sections'
                        ? ``
                        : d.__typename === 'subjects'
                        ? `${d.section.class.class}
                ${d.section.section}`
                        : `${d.section.class.class}
                ${d.section.section}
                ${d.sub.subject}`;
                    return (
                      <ListItem
                        key={d.id}
                        style={{
                          height: 80,
                          width: '100%',

                          border: '1px solid #ECFCFD',
                        }}
                        onClick={() => {
                          console.log('Clicking');
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#FFF',
                            textAlign: 'left',
                            border: 'none',
                          }}
                        >
                          <ListItemText>
                            <Typography
                              variant="h4"
                              style={{
                                fontSize: 18,
                                fontFamily: 'Arial',
                                verticalAlign: 'middle',
                                color: '#026776',
                              }}
                            >
                              {title}
                            </Typography>
                            <Typography
                              variant="h4"
                              style={{
                                fontSize: 16,
                                fontFamily: 'Arial',
                                verticalAlign: 'middle',
                                color: '#0EABBC',
                              }}
                            >
                              {subtitle}
                            </Typography>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => {
                                setItem(prev => {
                                  if (
                                    prev.filter(p => p.id === d.id).length < 1
                                  )
                                    return [...prev, { ...d, title, subtitle }];

                                  const rm = prev.filter(p => d.id !== p.id);
                                  return rm;
                                });
                              }}
                              checked={
                                items.filter(p => p.id === d.id).length > 0
                              }
                            />
                          </ListItemSecondaryAction>
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            ))}
          </List>
        )}
        <Button
          color="secondary"
          onClick={() => {
            firebase.auth().signOut();
            history.push('/');
          }}
          variant="outlined"
          style={{ margin: 20 }}
        >
          Logout
        </Button>
        <Copyright />
      </Paper>
    </Container>
  );
}
