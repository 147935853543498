import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CssBaseline, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
  },
  title: {
    color: 'red',
    fontStyle: 'italic',
    margin: 'auto',
  },
}));
export default function Unauthorized() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth="xl" className={classes.container}>
        <Typography
          component="div"
          role="tabpanel"
          id="full-width-tabpanel-"
          aria-labelledby="full-width-tab-"
          key="title"
          className={classes.title}
          variant="h6"
          color="primary"
        >
          Looks Like You Are Not Authorized To View This Area
        </Typography>
      </Container>
    </div>
  );
}
