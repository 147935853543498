import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { GET_SECTIONS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
import ClassSectionSubjectDialog from './ClassSectionSubjectDialog';
import { DELETE_SECTION_MUTATION } from '../resolvers/Mutations';

export default function ViewSections(props) {
  const { school } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogClose] = React.useState(false);
  const [rowD, setRowD] = React.useState({});
  const closeDialog = () => setDialogClose(false);
  const vars = {
    id: school.id,
    currentTerm: school.termID,
  };
  console.log(school.termID);
  const sectionRes = useQuery(GET_SECTIONS_QUERY, {
    variables: vars,
    fetchPolicy: 'network-only',
  });
  const [deleteSection, deleteSectionRes] = useMutation(
    DELETE_SECTION_MUTATION
  );
  if (sectionRes.loading || deleteSectionRes.loading) return <Loader />;
  if (sectionRes.error || deleteSectionRes.error) {
    enqueueSnackbar(
      `Something Went Wrong. Heres the error ${sectionRes.error.message ||
        deleteSectionRes.error.message}`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }
  let { sections } = sectionRes.data;
  sections = sections
    // .sort((a, b) => a.sr - b.sr)
    .map(s => ({
      sr: s.sr,
      sectionID: s.id,
      class: s.class ? s.class.class : '',
      classID: s.class ? s.class.id : '',
      section: s.section,
      teachers: s.section_teachers,
      students: s.students_aggregate.aggregate.count,
      notificationTime: s.notificationTime,
    }));
  return (
    <Box>
      <Table
        columns={[
          { title: 'Sr.No.', field: 'sr', width: 20 },
          { title: 'ClassID', field: 'classID', hidden: true },
          { title: 'SectionID', field: 'sectionID', hidden: true },
          { title: 'Class', field: 'class' },
          { title: 'Section', field: 'section' },
          {
            title: 'Teacher/s',
            field: 'teachers',
            render: rowData =>
              rowData.teachers.map(t => (
                <span key={t.teacherID}>
                  {t.user.name}
                  <br />
                </span>
              )),
          },
          { title: 'Students', field: 'students' },
          {
            title: 'Notification Time',
            field: 'notificationTime',
            // eslint-disable-next-line react/display-name
            render: rowData => {
              if (rowData.notificationTime) {
                const time = rowData.notificationTime.split(':');
                return (
                  <span>{`${moment()
                    .hour(time[0])
                    .minute(time[1])
                    .second('0')
                    .format('h:mm a')}`}</span>
                );
              }
            },
          },
        ]}
        data={sections}
        title="Sections"
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: 'Edit Section',
            onClick: (event, rowData) => {
              setRowD(rowData);
              setDialogClose(true);
            },
          },
          rowData => ({
            icon: tableIcons.Delete,
            tooltip:
              rowData.students > 0 ? 'Cannot Delete Section' : 'Delete Section',
            onClick: async (event, d) => {
              if (rowData.students > 0) {
                enqueueSnackbar(
                  'Section With Students Cannot Be Deleted. Delete All The Students First',
                  {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }
                );
              } else {
                await deleteSection({
                  variables: {
                    id: rowData.sectionID,
                  },
                  refetchQueries: [
                    {
                      query: GET_SECTIONS_QUERY,
                      variables: vars,
                    },
                  ],
                });
                enqueueSnackbar('Section Deleted Successfully!', {
                  variant: 'success',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                });
              }
            },
          }),
        ]}
      />
      <ClassSectionSubjectDialog
        open={dialogOpen}
        handleClose={closeDialog}
        data={rowD}
        update
        school={school}
        closeMain=""
      />
    </Box>
  );
}
