import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import * as moment from 'moment';
import { CREATE_ATTS_MUTATION } from '../resolvers/Mutations';
import Loader from './Loader';
import { GET_SECTIONS_STUDENTS } from '../resolvers/Queries';
import Authorize from '../pages/Authorize';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  usage: {
    padding: 10,
    fontWeight: 'bold',
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    marginTop: 20,
  },
}));
export default function BulkDailyAtt(props) {
  const classes = useStyles();
  const { school } = props;
  const { enqueueSnackbar } = useSnackbar();
  const permissions = ['SUPERADMIN'];
  const [atts, setAtts] = React.useState([]);
  const [submitEnabled, setsubmitEnabled] = React.useState(false);
  const [importAtts, importAttsData] = useMutation(CREATE_ATTS_MUTATION);
  const currentYearID = school.termID;
  const sectionsRes = useQuery(GET_SECTIONS_STUDENTS, {
    variables: {
      id: school.id,
      currentTerm: currentYearID,
    },
  });

  const cls = [];
  const students = [];
  const teachers = [];
  if (sectionsRes.data) {
    sectionsRes.data.sections.map(c => {
      cls.push({
        classID: c.class.id,
        sectionID: c.id,
        fsID: c.fsID,
      });
      c.students.map(s => {
        students.push({
          id: s.id,
          fsID: s.fsID,
        });
        return s;
      });
      c.teachers.map(t => {
        teachers.push({
          id: t.id,
          email: t.email,
        });
        return t;
      });
      return c;
    });
  }

  const handleSubmit = async () => {
    const data = [];
    for (const a of atts) {
      const t = cls.filter(s => s.fsID === a.section)[0];
      const teacher = teachers.filter(te => te.email === a.teacher.email)[0].id;
      const tmp = {
        month: parseInt(a.month),
        date: parseInt(a.date),
        year: parseInt(a.year),
        school: {
          connect: {
            id: school.id,
          },
        },
        schoolyearID: school.terms.filter(t => t.name === a.schoolyearID)[0].id,
        class: {
          connect: {
            id: t.classID,
          },
        },
        section: {
          connect: {
            id: t.sectionID,
          },
        },
        createdAt: moment(),
        lastUpdated: moment(),
        smsSent: a.smsSent,
        studentAttendance: {
          create: [],
        },
        teacherAttendance: {
          create: {
            value: a.teacher.val || 'P',
            teacher: {
              connect: {
                id: teacher,
              },
            },
          },
        },
      };
      for (const s of a.students) {
        const g = {
          value: s.val || 'P',
          student: {
            connect: {
              id: students.filter(st => st.fsID === s.fsID)[0].id,
            },
          },
        };
        tmp.studentAttendance.create.push(g);
      }
      data.push(tmp);
    }
    setTimeout(async () => {
      try {
        await importAtts({
          variables: {
            data,
          },
        });
        enqueueSnackbar(`Students Successfully Imported`, {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
      } catch (e) {
        enqueueSnackbar(`Error in Apollo Action. ${e.message}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
      }
    }, 1000);
  };
  let fileReader;
  const handleReadEnd = () => {
    const content = JSON.parse(fileReader.result);
    setAtts(content.data);
    setsubmitEnabled(true);
    enqueueSnackbar(
      `No Validation Has Been Run. Proceed Only If You Know What You Are Doing`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  };
  const handleFile = e => {
    fileReader = new FileReader();
    fileReader.onloadend = handleReadEnd;
    fileReader.readAsText(e.target.files[0]);
  };
  if (sectionsRes.loading || importAttsData.loading) return <Loader />;
  if (sectionsRes.error) {
    enqueueSnackbar(
      `Error in Apollo Action. Here's the error - ${sectionsRes.error.message}`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }
  if (importAttsData.error) {
    enqueueSnackbar(
      `Error in Apollo Action. Here's the error - ${importAttsData.error.message}`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }

  return (
    <Authorize pms={permissions}>
      <Container className={classes.main} maxWidth="xl" disableGutters>
        <Paper className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.title}>
              <h4>Import Data</h4>
            </div>
            <Divider />
            <Button
              variant="contained"
              component="label"
              className={classes.button}
            >
              Upload File
              <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleFile}
              />
            </Button>
            <Divider />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!submitEnabled}
            >
              Upload Sections
            </Button>
          </div>
        </Paper>
        <Paper className={classes.wrapper}>
          <Typography className={classes.content} component="h1" variant="h5">
            Usage Guide
          </Typography>
          <Divider />
          <Typography className={classes.usage} gutterBottom variant="h5">
            This upload option is only for importing old data exported by the
            previous system. If you're reading this, then it's probably best if
            you don't use this form.
          </Typography>
        </Paper>
      </Container>
    </Authorize>
  );
}
