import React from 'react';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import Loader from '../components/Loader';
import { GET_SCHOOLS_QUERY } from '../resolvers/Queries';
import ShowSchools from '../components/ShowSchools';
import AddSchoolForm from '../components/AddSchoolForm';
import Authorize from './Authorize';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.paper,
    minHeight: '100vh',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function Schools() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const permissions = ['SUPERADMIN'];
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  const schoolsData = useQuery(GET_SCHOOLS_QUERY);
  if (schoolsData.loading) return <Loader />;
  const { data } = schoolsData;
  return (
    <Authorize pms={permissions}>
      <main className={classes.content}>
        <Container maxWidth="sm" className={classes.container}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Select School" {...a11yProps(0)} />
              <Tab label="Add School" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} dir={theme.direction}>
            {data.schools.length === 0 &&
              'No Schools Found. Add a school to continue!'}
            {data.schools.length > 0 && <ShowSchools schools={data.schools} />}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {/* // TODO Change Panel After Adding new School */}
            <AddSchoolForm changePanel={handleChange} />
          </TabPanel>
        </Container>
      </main>
    </Authorize>
  );
}
