import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { Button, Box, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { GET_FEES_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
import {
  UPDATE_FEES_MUTATION,
  DELETE_FEE_MUTATION,
} from '../resolvers/Mutations';
import UpdateFeeForm from './UpdateFeeForm';
import ConfirmDialog from './ConfirmDialog';
import TermsContext from './TermsContext';

const months = {};
months.JAN = 0;
months.FEB = 1;
months.MAR = 2;
months.APR = 3;
months.MAY = 4;
months.JUN = 5;
months.JUL = 6;
months.AUG = 7;
months.SEP = 8;
months.OCT = 9;
months.NOV = 10;
months.DEC = 11;

export default function FeesTable(props) {
  const { feeCat, school, user } = props;
  const { enqueueSnackbar } = useSnackbar();

  const componentRef = React.useRef();
  const [updateFeeShow, setUpdateFeeShow] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [deleteFeeMutation, deleteFeeData] = useMutation(DELETE_FEE_MUTATION);
  const { currentTerm } = React.useContext(TermsContext);

  const feesData = useQuery(GET_FEES_QUERY, {
    variables: {
      feesStructureID: feeCat.value,
      termID: currentTerm,
    },
    fetchPolicy: 'network-only',
  });
  const [updateFees, updateFeesData] = useMutation(UPDATE_FEES_MUTATION);
  if (feesData.loading || updateFeesData.loading || deleteFeeData.loading)
    return <Loader />;
  const feeArr = [];
  const deleteFee = async () => {
    try {
      await deleteFeeMutation({
        variables: {
          id: currentRow.id,
        },
      });
      await feesData.refetch();
      enqueueSnackbar(`${currentRow.name} has been successfully deleted!`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } catch (e) {
      enqueueSnackbar(`Action Failed. Heres the error: ${e}`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
    setConfirmDialog(false);
  };
  const tmpArr = [];

  if (feesData.data.fees.length > 0) {
    feesData.data.fees.map(f => {
      tmpArr.push({
        id: f.id,
        sr: f.sr,
        name: f.name,
        // type: f.fixedAmount,
        amount: f.amount || '',
        createdAt: moment(f.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        due: f.month,
      });
      return f;
    });
  }

  return (
    <div>
      <Box>
        {tmpArr.length > 0 ? (
          <div>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ color: '#FFF' }}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={`FEES-CHART-${school.name}-${feeCat.label}-${school.terms[0].name}`}
            />
            <Table
              columns={[
                { title: 'ID', field: 'id', hidden: true },
                { title: 'SR.', field: 'sr' },
                { title: 'Name', field: 'name' },
                { title: 'Due Month', field: 'due' },
                {
                  title: 'Amount',
                  field: 'amount',
                  // eslint-disable-next-line react/display-name
                  render: rowData => <span>&#8377; {`${rowData.amount}`}</span>,
                },
                { title: 'Created On', field: 'createdAt', width: 300 },
              ]}
              data={tmpArr}
              pagesize={
                // eslint-disable-next-line no-nested-ternary
                tmpArr.length <= 20 ? 20 : tmpArr.length <= 50 ? 50 : 100
              }
              title={`Fees for ${feeCat.label}`}
              actions={
                ['ADMIN', 'SUPERADMIN'].indexOf(user.permissions) > -1
                  ? [
                      {
                        icon: tableIcons.Edit,
                        tooltip: 'Edit Fee',
                        onClick: (event, rowData) => {
                          setCurrentRow(rowData);
                          setUpdateFeeShow(true);
                        },
                      },
                      {
                        icon: tableIcons.Delete,
                        tooltip: 'Delete This Fee',
                        onClick: async (event, rowData) => {
                          setCurrentRow(rowData);
                          setConfirmDialog(true);
                        },
                      },
                    ]
                  : []
              }
            />
          </div>
        ) : (
          <Typography component="h1" variant="h5">
            Looks like there are no fees assigned to this class
          </Typography>
        )}
        <UpdateFeeForm
          open={updateFeeShow}
          handleClose={() => setUpdateFeeShow(false)}
          data={currentRow}
          termID={currentTerm}
          feeCatID={feeCat.value}
          updateFees={updateFees}
          feesData={feesData}
        />
        <ConfirmDialog
          open={confirmDialog}
          handleConfirmClose={() => setConfirmDialog(false)}
          handleSubmit={deleteFee}
        />
      </Box>

      <div style={{ height: 0, padding: 0, overflow: 'hidden' }}>
        <div
          ref={componentRef}
          style={{
            padding: 20,
          }}
        >
          <h4>{`Fee Structure Of ${school.name} for class ${feeCat.label} for year ${school.terms[0].name}`}</h4>
          {feeArr.map((a, index) => (
            <div
              key={index}
              style={
                feeArr.length - 1 === index
                  ? {
                      fontWeight: 'bold',
                      marginTop: 5,
                      marginBottom: 5,
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }
                  : {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }
              }
            >
              <span
                style={
                  feeArr.length - 1 === index
                    ? { fontWeight: 'bold', textAlign: 'left', width: '20%' }
                    : { textAlign: 'left', width: '20%' }
                }
              >
                {a.name}
              </span>
              <span
                style={
                  feeArr.length - 1 === index
                    ? { fontWeight: 'bold', textAlign: 'left', width: '20%' }
                    : { textAlign: 'left', width: '20%' }
                }
              >
                {// eslint-disable-next-line no-nested-ternary
                a.month
                  ? a.month
                  : feeArr.length - 1 === index
                  ? ''
                  : 'One Time'}
              </span>
              <span
                style={
                  feeArr.length - 1 === index
                    ? { fontWeight: 'bold', textAlign: 'left', width: '20%' }
                    : { textAlign: 'left', width: '20%' }
                }
              >
                &#8377; {a.amount}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
