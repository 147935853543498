import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import Loader from './Loader';
import { ADD_FEE_STRUCTURE_MUTATION } from '../resolvers/Mutations';
import { ACCOUNTS_QUERY, GET_FEES_STRUCTURE_QUERY } from '../resolvers/Queries';
import SelectField from './SelectField';
import TermsContext from './TermsContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}));

export default function AddFeesStructureForm(props) {
  const classes = useStyles();
  const { school } = props;
  const [accs, setAccs] = React.useState([]);
  const { currentTerm } = React.useContext(TermsContext);

  const [addFeeeStructure, addFeeStructureData] = useMutation(
    ADD_FEE_STRUCTURE_MUTATION
  );
  const accsData = useQuery(ACCOUNTS_QUERY, {
    variables: { schoolID: school.id },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const tmp = data.accounts.map(a => {
        const t = {
          value: a.id,
          label: `${a.name}-${a.accNumber}`,
        };
        return t;
      });
      setAccs(tmp);
    },
  });
  const submit = async values => {
    console.log(values);
    await addFeeeStructure({
      variables: {
        data: values,
      },
      refetchQueries: [
        {
          query: GET_FEES_STRUCTURE_QUERY,
          variables: { termID: values.termID },
          fetchPolicy: 'network-only',
        },
      ],
    });
  };
  if (addFeeStructureData.loading || accsData.loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        name: '',
        accID: '',
        schoolID: school.id,
        termID: currentTerm,
      }}
      enableReinitialize
      onSubmit={async values => {
        try {
          await submit({
            ...values,
            accID: school.onlineFees ? values.accID : undefined,
          });
        } catch (e) {
          console.log(e);
        }

        // TODO Change Panel After Adding new School
        // props.changePanel('', 0);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        accID: school.onlineFees
          ? Yup.number().required('Required')
          : Yup.number(),
        schoolID: Yup.string().required('Required'),
        termID: Yup.number().required('Required'),
      })}
    >
      {p => {
        const { values, touched, errors, handleChange, handleBlur } = p;
        console.log(errors);
        return (
          <Form className={classes.container}>
            <TextField
              label="Fee Structure Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.name && touched.name && errors.name}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            {school.onlineFees && (
              <Field
                name="accID"
                component={SelectField}
                options={accs}
                label="Select matching Bank Account"
                zIndex={10}
              />
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Add Fee Structure
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
