import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ErrorDialog(props) {
  const { open, handleConfirmClose, error } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">There Was An Error.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Error: {error}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleConfirmClose}
            color="primary"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
