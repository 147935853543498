import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/client';
import * as moment from 'moment';
import SelectField from './SelectField';
import {
  UPDATE_SUBJECT_MUTATION,
  CREATE_SUBJECT_MUTATION,
} from '../resolvers/Mutations';
import { GET_SUBJECTS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import MyTimePicker from './TimePicker';

const days = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 0, label: 'Sunday' },
];
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 400,
    paddingBottom: 50,
  },
  button: {
    position: 'relative',
    top: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  timetable: {
    padding: 20,
    border: '1px solid grey',
  },
}));
export default function SubjectForm(p) {
  const { update, sec, school, closeDialog, teachers, subject = false } = p;
  const [createSubject, createSubjectRes] = useMutation(
    CREATE_SUBJECT_MUTATION
  );
  const [updateSubject, updateSubjectRes] = useMutation(
    UPDATE_SUBJECT_MUTATION
  );
  const classes = useStyles();

  if (createSubjectRes.loading || updateSubjectRes.loading) return <Loader />;

  return (
    <Formik
      initialValues={{
        section: update ? subject.sectionID : sec.id,
        subject: update ? subject.subject : '',
        teachers: update
          ? subject.subject_teachers.map(s => s.user.id)
          : [{ label: 'Select Teacher', value: 0 }],
        timetable: update
          ? subject.timetable.map(t => {
              const tmp = {};
              tmp.start = moment(t.start, 'HH:mm:ssZ');
              tmp.end = moment(t.end, 'HH:mm:ssZ');
              tmp.day = t.day;
              return tmp;
            })
          : [],
      }}
      onSubmit={async values => {
        if (update) {
          const c = {
            subject: values.subject,
            timetable: values.timetable.map(t => {
              t.start = t.start.second('0').format('HH:mm:ss');
              t.end = t.end.second('0').format('HH:mm:ss');
              return t;
            }),
          };
          const subjectTeachers = values.teachers.map(t => ({
            teacherID: t,
            subjectID: subject.id,
          }));
          try {
            await updateSubject({
              variables: {
                data: c,
                subjectID: parseInt(subject.id),
                subjectTeachers,
              },
              refetchQueries: [
                {
                  query: GET_SUBJECTS_QUERY,
                  variables: {
                    sectionID: sec.id,
                  },
                },
              ],
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          const c = {
            subject: values.subject,
            timetable: values.timetable.map(t => {
              t.start = t.start.second('0').format('HH:mm:ss');
              t.end = t.end.second('0').format('HH:mm:ss');
              return t;
            }),
            termID: school.termID,
            classID: sec.class.id,
            sectionID: sec.id,
            schoolID: school.id,
            subject_teachers: {
              data: values.teachers.map(t => ({ teacherID: t })),
            },
          };
          try {
            await createSubject({
              variables: { data: c },
              refetchQueries: [
                {
                  query: GET_SUBJECTS_QUERY,
                  variables: {
                    sectionID: sec.id,
                  },
                },
              ],
            });
          } catch (e) {
            console.log(e);
          }
        }
        closeDialog();
      }}
      validationSchema={Yup.object().shape({
        section: Yup.string().required(),
        subject: Yup.string().required(),
        teachers: Yup.array(),
        timetable: Yup.array(),
      })}
    >
      {props => {
        const { values, touched, errors, handleChange, handleBlur } = props;

        return (
          <Form className={classes.container}>
            <TextField
              label="Subject name"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.subject && touched.subject && errors.subject}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            <Field
              name="teachers"
              component={SelectField}
              options={teachers}
              label="Select Teacher/s"
              isMulti
              zIndex={4}
            />
            <FieldArray
              name="timetable"
              label="Setup Timetable"
              render={arrayHelpers => (
                <div>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() =>
                      arrayHelpers.push({
                        start: moment(),
                        end: moment(),
                        day: 1,
                      })
                    }
                  >
                    Add Timetable
                  </Button>
                  <div style={{ marginBottom: 20 }} />

                  {values.timetable && values.timetable.length > 0
                    ? values.timetable.map((t, index) => (
                        <div key={index} className={classes.timetable}>
                          <Field
                            label="Weekday"
                            component={SelectField}
                            options={days}
                            name={`timetable.${index}.day`}
                          />
                          <div className={classes.break} />

                          <Field
                            name={`timetable.${index}.start`}
                            label="Start Time"
                            component={MyTimePicker}
                          />
                          <div className={classes.break} />
                          <Field
                            name={`timetable.${index}.end`}
                            label="End Time"
                            component={MyTimePicker}
                          />
                          <div className={classes.break} />
                        </div>
                      ))
                    : null}
                </div>
              )}
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              {update ? 'Update Subject' : 'Add Subject'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
