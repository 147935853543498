import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import Loader from '../components/Loader';
import { GET_SHORTURLS_QUERY } from '../resolvers/Queries';

export default function FeesRedirect(props) {
  const { client } = props;
  const { source } = useParams();
  const getDestination = useQuery(GET_SHORTURLS_QUERY, {
    variables: {
      source,
    },
    client,
  });
  if (!getDestination.loading) {
    const link = `/onlinefees/${getDestination.data.shorturls_by_pk.redirect}`;
    return <Redirect to={link} />;
  }

  return <Loader />;
}
