import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import Table, { tableIcons } from './Table';
import { DELETE_DISCOUNTS_MUTATION } from '../resolvers/Mutations';
import { STUDENT_FEES_AGG_QUERY } from '../resolvers/Queries';

export default function DeleteDiscountDialog(props) {
  const { open, handleConfirmClose, data } = props;
  const [deleteDiscount] = useMutation(DELETE_DISCOUNTS_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = async items => {
    try {
      await deleteDiscount({
        variables: {
          schoolID: data.schoolID,
          studentID: data.studentID,
          ids: items.map(i => i.id),
        },
        refetchQueries: [
          {
            query: STUDENT_FEES_AGG_QUERY,
            variables: {
              schoolID: data.schoolID,
              termID: data.currentTerm,
              studentID: data.studentID,
            },
            fetchPolicy: 'network-only',
          },
        ],
      });

      enqueueSnackbar('Discount Successfully Deleted!', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
      handleConfirmClose();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{data.title}</DialogTitle>
        <DialogContent>
          <Table
            columns={[
              { title: 'ID', field: 'id', hidden: true },
              { title: 'Status', field: 'status' },
              { title: 'Amount', field: 'amount' },
              { title: 'Collected On', field: 'collectedAt' },
              { title: 'Collected By', field: 'collectedBy' },
            ]}
            data={data.items}
            title="Discounts"
            options={{
              selection: true,
            }}
            actions={[
              {
                icon: tableIcons.Delete,
                tooltip: 'Delete Discounts',
                onClick: (event, rowData) => {
                  handleDelete(rowData);
                },
              },
            ]}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
