import React from 'react';
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import RupeeIcon from './RupeeIcon';
import SelectField from './SelectField';
import { ADD_FEES_MUTATION } from '../resolvers/Mutations';
import Loader from './Loader';
import CheckboxInputs from './CheckboxInput';

const months = [
  { value: 'JAN', label: 'JAN' },
  { value: 'FEB', label: 'FEB' },
  { value: 'MAR', label: 'MAR' },
  { value: 'APR', label: 'APR' },
  { value: 'MAY', label: 'MAY' },
  { value: 'JUN', label: 'JUN' },
  { value: 'JUL', label: 'JUL' },
  { value: 'AUG', label: 'AUG' },
  { value: 'SEP', label: 'SEP' },
  { value: 'OCT', label: 'OCT' },
  { value: 'NOV', label: 'NOV' },
  { value: 'DEC', label: 'DEC' },
];
const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 30,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
export default function OneTimeFee(props) {
  const classes = useStyles();
  const { feesCats, school } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [addFees, addFeesData] = useMutation(ADD_FEES_MUTATION);
  if (addFeesData.loading) return <Loader />;
  return (
    <div>
      <Typography component="h1" variant="h6">
        Add One Time Fees.
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          amount: '',
          fees_structure: [],
          months: '',
          fixedAmount: true,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name for Fee'),
          amount: Yup.number().nullable(),
          fixedAmount: Yup.boolean().required('Amount type is required'),
          fees_structure: Yup.string().required(
            'Select Applicable Fees Categories'
          ),
          months: Yup.string().nullable(),
        })}
        onSubmit={async v => {
          const data = [];
          v.fees_structure.map(fs => {
            const tmp = {
              schoolID: school.id,
              termID: school.termID,
              name: v.name,
              amount: v.amount || 0,
              fixedAmount: v.fixedAmount,
              month: v.months || null,
              feesStructureID: fs,
              slug: `${v.name}_${school.name}_${
                feesCats.filter(f => f.value === fs)[0].label
              }`
                .toLowerCase()
                .replace(/\s/g, '_')
                .replace(/-/g, '_'),
            };
            data.push(tmp);
            return fs;
          });
          try {
            await addFees({
              variables: {
                data,
              },
            });
            enqueueSnackbar(`${v.name} has been added succesfully!`, {
              variant: 'success',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            });
          } catch (e) {
            enqueueSnackbar(`Something Went Wrong. Heres the error ${e}`, {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            });
          }
        }}
      >
        {p => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
          } = p;
          return (
            <Form className={classes.container}>
              <TextField
                label="Fee Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.name && touched.name && errors.name}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
              <Field
                name="fixedAmount"
                label="Fixed Amount"
                component={CheckboxInputs}
                checked={values.fixedAmount}
              />
              {values.fixedAmount && (
                <TextField
                  label="Amount"
                  name="amount"
                  value={values.amount}
                  onChange={e => {
                    if (e.target.value !== '') {
                      setFieldValue(
                        e.target.name,
                        parseInt(e.target.value),
                        true
                      );
                    } else {
                      setFieldValue(e.target.name, e.target.value, true);
                    }
                  }}
                  onBlur={handleBlur}
                  helperText={errors.amount && touched.amount && errors.amount}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RupeeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <Field
                name="months"
                component={SelectField}
                options={months}
                label="Select Month Due"
                zIndex={10}
              />
              <Field
                name="fees_structure"
                component={SelectField}
                options={feesCats}
                label="Select Applicable Fees Categories"
                isMulti
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<MoneyIcon />}
              >
                Add One Time Fee
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
