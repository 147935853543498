import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { GET_STUDENTS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
import { UPDATE_STUDENT_MUTATION } from '../resolvers/Mutations';
import StudentDialog from './StudentDialog';

export default function ViewStudents(props) {
  const { school } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogClose] = React.useState(false);
  const [rowD, setRowD] = React.useState({});
  const closeDialog = () => setDialogClose(false);
  const [updateStudent, updateStudentRes] = useMutation(
    UPDATE_STUDENT_MUTATION
  );
  const vars = {
    id: school.id,
    termID: school.termID,
  };
  const studentsRes = useQuery(GET_STUDENTS_QUERY, { variables: vars });

  if (studentsRes.loading || updateStudentRes.loading) return <Loader />;
  if (studentsRes.error || updateStudentRes.error) {
    enqueueSnackbar(
      `Something Went Wrong. Heres the error ${studentsRes.error.message ||
        updateStudentRes.error.message}`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }
  let { students } = studentsRes.data;
  students = students.map(s => ({
    roll: s.roll,
    section: s.section.section,
    class: s.class.class,
    sections: `${s.class.id}:${s.section.id}`,
    name: s.name,
    phone: s.phone,
    registerNo: s.registerNo,
    disabled: s.disabled,
    gender: s.gender === 'BLANK' ? '' : s.gender,
    religion: s.religion,
    birthdate: s.birthdate ? moment(s.birthdate).format('DD/MM/YYYY') : '',
    addBirthdate: !!(s.birthdate && !!moment(s.birthdate)),
    feeCategory:
      s.students_fees_structures.length > 0
        ? s.students_fees_structures[0].fees_structure.name
        : '',
    feeCategoryID:
      s.students_fees_structures.length > 0
        ? s.students_fees_structures[0].fees_structure.id
        : '',
    id: s.id,
    qrcode: s.qrcode,
    email: s.loginEmail,
  }));
  return (
    <Box>
      <Table
        columns={[
          { title: 'ID', field: 'id', hidden: true },
          { title: 'Class', field: 'class', width: 20 },
          { title: 'Section', field: 'section' },
          { title: 'Sections', field: 'sections', hidden: true },
          { title: 'Add Birthdate', field: 'addBirthdate', hidden: true },
          { title: 'Roll', field: 'roll' },
          { title: 'Name', field: 'name' },
          { title: 'Register No.', field: 'registerNo' },
          { title: 'Phone', field: 'phone' },
          { title: 'Gender', field: 'gender', hidden: true },
          { title: 'Religion', field: 'religion', hidden: true },
          { title: 'Birthdate', field: 'birthdate', hidden: true },
          { title: 'Fee Category', field: 'feeCategory' },
          {
            title: 'Login Email',
            field: 'email',
            hidden: !(school.qrcode || school.chat || school.liveLectures),
          },
          { title: 'QRCode', field: 'qrcode', hidden: true },
          {
            title: 'Enabled',
            field: 'disabled',
            render: rowData => (
              <span>{rowData.disabled ? 'Disabled' : 'Enabled'}</span>
            ),
          },
        ]}
        data={students}
        title="Students"
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: 'Edit User',
            onClick: (event, rowData) => {
              setRowD(rowData);
              setDialogClose(true);
            },
          },
          rowData => ({
            icon: rowData.disabled ? tableIcons.Enable : tableIcons.Disable,
            tooltip: rowData.disabled ? 'Enable Student' : 'Disable Student',
            onClick: async (event, d) => {
              await updateStudent({
                variables: {
                  id: d.id,
                  data: { disabled: !d.disabled },
                },
                refetchQueries: [
                  {
                    query: GET_STUDENTS_QUERY,
                    variables: vars,
                  },
                ],
              });
              enqueueSnackbar(
                rowData.disabled
                  ? `User Has Been Successfully Enabled`
                  : `User Has Been Successfully Disabled`,
                {
                  variant: 'success',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                }
              );
            },
          }),
        ]}
      />
      <StudentDialog
        open={dialogOpen}
        handleClose={closeDialog}
        data={rowD}
        update
        school={school}
        closeMain=""
      />
    </Box>
  );
}
