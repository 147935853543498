import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, Link, withRouter } from 'react-router-dom';

import {
  CssBaseline,
  Container,
  CardMedia,
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import Copyright from '../components/Copyright';
import { GET_STUDENTS_BY_PHONE } from '../resolvers/Queries';
import Loader from '../components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
  },
  logo: {
    height: '100px',
    width: '100px',
    margin: '10px auto',
  },
  button: {
    color: '#FFF',
  },
  link: {
    textDecoration: 'none',
  },
}));

function OnlineFees(props) {
  const { client } = props;
  const { hash } = useParams();
  const classes = useStyles();
  const safeHash = decodeURIComponent(hash);
  const studentsData = useQuery(GET_STUDENTS_BY_PHONE, {
    variables: {
      hash: safeHash,
    },
    client,
  });
  if (studentsData.loading) return <Loader />;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <CardMedia
            className={classes.logo}
            component="div"
            image="/logo.png"
          />
          <h2 style={{ textAlign: 'center' }}>Online Fees</h2>
          <h3 style={{ textAlign: 'center' }}>Select Student</h3>
          <div>
            {studentsData.data.getStudents.map(s => (
              <Card key={s.id}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {s.name}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {`${s.section}`}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {`${s.school}`}
                  </Typography>
                  <div style={{ marginTop: 10 }} />
                  <Link
                    style={{ width: '100%' }}
                    to={`/onlinefees/${hash}/${s.schoolID}/${s.termID}/${s.id}/${s.school}`}
                    className={classes.link}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      Select This Student
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            ))}
          </div>
          <div style={{ marginTop: 20 }} />
          <Copyright />
        </Container>
      </main>
    </div>
  );
}
export default withRouter(OnlineFees);
