import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React from 'react';

const pageStyles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'row',
  },
});
const styles = theme => ({
  main: {
    '@media print': {
      display: 'block',
      html: {
        height: 'initial !important',
        overflow: 'initial !important',
        '-webkit-print-color-adjust': 'exact',
      },
      body: {
        height: 'initial !important',
        overflow: 'initial !important',
        '-webkit-print-color-adjust': 'exact',
      },
      break: {
        'page-break-inside': 'avoid',
      },
    },
    display: 'block',
    margin: 20,
  },
  '@page': {
    size: 'auto',
    margin: '20mm',
  },
  item: {
    display: 'flex',

    width: 250,
    height: 250,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  text: {
    paddingLeft: 20,
  },
  gridItem: {
    // height: 250,
    width: 300,
  },
  image: {
    padding: 5,
    margin: 0,
  },
});
class QRCodePrintStudents extends React.Component {
  renderImages = (students, classes) => {
    const pages = students.length > 49 ? Math.ceil(students.length / 49) : 1;
    const studentsArr = [...Array(pages).keys()].map((s, i) =>
      students.slice(i * 49, (i + 1) * 49)
    );
    return studentsArr.map((s, i) => {
      const studentsRow = [
        ...Array(Math.ceil(s.length / 7)).keys(),
      ].map((st, j) => s.slice(j * 7, (j + 1) * 7));
      return (
        <Page
          key={`page-${i}`}
          size="A4"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: 50,
          }}
        >
          {studentsRow.map((st, j) => (
            <View key={`row-${j}`} style={pageStyles.section}>
              {st.map((ss, k) => (
                <View key={`student-${k}`} style={{ marginBottom: 20 }}>
                  <Text
                    style={{ fontSize: 5, paddingLeft: 5 }}
                  >{`${ss.name.substr(0, 23)}`}</Text>
                  <Text
                    style={{ fontSize: 5, paddingLeft: 5 }}
                  >{`${ss.email.substr(0, 23)} `}</Text>
                  <Image
                    src={ss.qrcode}
                    alt="qrcode"
                    style={{ width: 75, height: 75 }}
                  />
                </View>
              ))}
            </View>
          ))}
        </Page>
      );
    });
    // return studentsArr.map((s, i) => (
    //   <Grid item xs={3} key={i} className={classes.gridItem}>
    //     {s.map((student, j) => (
    //       <div key={j} className={classes.item}>
    //         <div>
    //           <Typography variant="caption" className={classes.text}>
    //             {`${student.name.substr(0, 30)}`}
    //           </Typography>
    //           <Typography
    //             variant="caption"
    //             display="block"
    //             className={classes.text}
    //           >
    //             {student.email}
    //           </Typography>
    //         </div>
    //         <img
    //           src={student.qrcode}
    //           alt="qrcode"
    //           height={200}
    //           width={200}
    //           className={classes.image}
    //         />
    //       </div>
    //     ))}
    // </Grid>
    // ));
  };

  render() {
    const { students, classes } = this.props;
    return (
      <Document>{this.renderImages(students)}</Document>
      // <div className={classes.main}>
      //   <Grid container spacing={0}>
      //     {this.renderImages(students, classes)}
      //   </Grid>
      // </div>
    );
  }
}

export default QRCodePrintStudents;
