import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Loader from './Loader';
import { GET_SECTIONS_ONLY_QUERY } from '../resolvers/Queries';
import UserContext from './UserContext';
import PlainSelect from './PlainSelect';
import SubjectsView from './SubjectsView';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    padding: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapper: {
    width: '50%',
    padding: 20,
  },
  button: {
    height: 55,
    color: '#FFF',
    fontWeight: 'bold',
  },
}));

export default function ViewCurriculum(props) {
  const classes = useStyles();
  const { school } = props;
  const { user } = React.useContext(UserContext);

  const [confirmedSection, setConfirmedSection] = React.useState(false);
  const [section, setSection] = React.useState(null);
  const secsRes = useQuery(GET_SECTIONS_ONLY_QUERY, {
    variables: {
      id: user.schoolID,
      currentTerm: school.termID,
    },
    fetchPolicy: 'network-only',
  });

  if (secsRes.loading) return <Loader />;
  const secsArray = secsRes.data.sections.map(c => ({
    value: `${c.class.id}:${c.id}`,
    label: `${c.class.class} ${c.section}`,
  }));
  return (
    <Box>
      <Paper>
        <div className={classes.main}>
          <div className={classes.wrapper}>
            <PlainSelect
              selectedVal={section}
              onChange={setSection}
              options={secsArray}
              placeholder="Select A Section To See Curriculum"
              isDisabled={!!confirmedSection}
            />
          </div>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color={confirmedSection ? 'primary' : 'secondary'}
              disabled={!section}
              fullWidth
              className={classes.button}
              onClick={() => setConfirmedSection(!confirmedSection)}
            >
              {confirmedSection ? 'Reset Section' : 'Confirm Section'}
            </Button>
          </div>
        </div>
      </Paper>
      {confirmedSection && (
        <Paper className={classes.container}>
          <SubjectsView
            school={school}
            sec={
              secsRes.data.sections.filter(
                s => parseInt(section.value.split(':')[1]) === s.id
              )[0]
            }
          />
        </Paper>
      )}
    </Box>
  );
}
