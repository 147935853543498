import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import Loader from './Loader';
import UserContext from './UserContext';
import SchoolContext from './SchoolContext';
import { GET_SCHOOL_QUERY } from '../resolvers/Queries';
import TermsContext from './TermsContext';

const useStyles = makeStyles(theme => ({
  school: {
    display: 'flex',
    // justifyContent: 'space-between',
    marginBottom: 10,
  },
  title: {
    padding: 10,
    paddingBottom: 0,
  },
  subtitle: {
    paddingLeft: 10,
    paddingBottom: 10,
  },
  icon: {
    padding: 10,
    margin: 10,
    fontSize: 60,
    width: 100,
  },
  sr: {
    paddingLeft: 10,
    width: 30,
  },
  name: {
    width: '80%',
  },
}));
function ShowSchools(props) {
  const classes = useStyles();
  const { schools } = props;
  const [loading, setLoading] = React.useState(false);
  const { user, setUser } = React.useContext(UserContext);
  const { setSchool } = React.useContext(SchoolContext);
  const { setTerm, setTerms } = React.useContext(TermsContext);

  const client = useApolloClient();
  // setSchool(schoolData.data.school);
  const handlePress = async id => {
    setLoading(true);
    const schoolData = await client.query({
      query: GET_SCHOOL_QUERY,
      variables: {
        id,
      },
    });
    setSchool({ ...schoolData.data.schools_by_pk });
    setUser({
      ...user,
      schoolID: id,
    });
    setTerm(schoolData.data.schools_by_pk.termID);
    setTerms(schoolData.data.schools_by_pk.terms);
    const link = `/dashboard`;

    props.history.push(link);
  };
  if (loading) return <Loader />;

  return schools.map((s, index) => (
    <Paper className={classes.school} key={index}>
      <h4 className={classes.sr}>{index + 1}</h4>
      <div className={classes.name}>
        <Typography
          component="div"
          role="tabpanel"
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          key={`title${index}`}
          className={classes.title}
          variant="h6"
          color="primary"
        >
          {s.name}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          key={`subtitle${index}`}
          className={classes.subtitle}
          variant="subtitle1"
          color="primary"
        >
          {s.description || 'No Description Found'}
        </Typography>
      </div>
      <div>
        <ArrowRightAltIcon
          onClick={() => handlePress(s.id)}
          className={classes.icon}
          color="secondary"
        />
      </div>
    </Paper>
  ));
}

export default withRouter(ShowSchools);
