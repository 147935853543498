import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useMutation } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import AddUserForm from './AddUserForm';
import { UPDATE_USER_MUTATION, UPDATE_USER_PASS } from '../resolvers/Mutations';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function EditUserDialog(props) {
  const [updateUser, { error }] = useMutation(UPDATE_USER_MUTATION);
  const [updatePass, updatePassData] = useMutation(UPDATE_USER_PASS);

  const { open, handleClose, data } = props;
  const { enqueueSnackbar } = useSnackbar();
  const handlePassUpdate = async args => {
    await updatePass({
      variables: {
        id: args.id,
        password: args.password,
      },
    });
    if (updatePassData.error) {
      enqueueSnackbar(
        `Something went wrong. Here\`s the error: ${updatePassData.error.message}`,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }
      );
    } else {
      enqueueSnackbar('Password Was Successfully Updated!', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
    handleClose();
  };
  const handleUpdate = async args => {
    await updateUser(args);
    if (error) {
      enqueueSnackbar(
        `Something went wrong. Here\`s the error: ${error.message}`,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }
      );
    } else {
      enqueueSnackbar('User Was Successfully Updated!', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit User
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Update {data.name}</DialogContentText>
          <AddUserForm
            createUser={false}
            updateUser={handleUpdate}
            update
            user={data}
            updatePass={handlePassUpdate}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
