/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import NotInterested from '@material-ui/icons/NotInterested';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MaterialTable from 'material-table';
import { TablePagination } from '@material-ui/core';

function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={subprops => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}
export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Disable: forwardRef((props, ref) => <NotInterested {...props} ref={ref} />),
  Enable: forwardRef((props, ref) => (
    <CheckCircleOutline {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => (
    <VisibilityIcon {...props} ref={ref} />
  )),
  Money: forwardRef((props, ref) => (
    <AccountBalanceWalletOutlinedIcon {...props} ref={ref} />
  )),
};

export default function Table(props) {
  const { columns, data, title, actions, pagesize = 20, options = {} } = props;
  return (
    <MaterialTable
      components={{
        Pagination: PatchedPagination,
      }}
      columns={columns}
      data={data}
      title={title}
      icons={tableIcons}
      actions={actions}
      options={{
        ...options,
        actionsColumnIndex: -1,
        rowStyle: rowData => ({
          backgroundColor: rowData.disabled ? '#fce4ec' : '',
        }),
        exportButton: true,
        exportAllData: true,
        pageSize: pagesize,
        pageSizeOptions: [20, 50, 100],
        detailPanelColumnAlignment: 'right',
        padding: 'dense',
        headerStyle: {
          backgroundColor: '#DEF3FA',
          color: 'Black',
          whiteSpace: 'nowrap',
        },
      }}
      // style={{ padding: 20 }}
    />
  );
}
