import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/client';
import SelectField from './SelectField';
import {
  GET_USERS_QUERY,
  CLASSES_QUERY,
  GET_SCHOOL_QUERY,
} from '../resolvers/Queries';
import Loader from './Loader';
import UserContext from './UserContext';
import { UPDATE_CLASSES_ADMINS_MUTATION } from '../resolvers/Mutations';
import UpdatePasswordForm from './UpdatePasswordForm';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

const PERMISSIONS = ['ADMIN', 'TEACHER', 'FEES', 'ATTENDANT'];

export default function AddUserForm(pr) {
  const { createUser, updateUser, update, user, updatePass } = pr;
  const classes = useStyles();
  const { user: u } = React.useContext(UserContext);
  const formatName = name =>
    name
      .replace(/\s\s+/g, ' ')
      .replace(/\.(?=[^\s])/g, '. ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  const [updateClassesAdmin, updateClassesAdminData] = useMutation(
    UPDATE_CLASSES_ADMINS_MUTATION
  );
  const schoolData = useQuery(GET_SCHOOL_QUERY, {
    variables: {
      id: u.schoolID,
    },
  });
  const clsData = useQuery(CLASSES_QUERY, {
    variables: {
      schoolID: u.schoolID,
      termID: schoolData.data.schools_by_pk.termID,
    },
  });
  if (schoolData.loading || clsData.loading || updateClassesAdminData.loading)
    return <Loader />;
  const cls = [];
  clsData.data.classes.map(c => cls.push({ value: c.id, label: c.class }));
  return (
    <Formik
      initialValues={{
        name: update ? user.name : '',
        phone: update ? user.phone : '1234567890',
        email: update ? user.email : '',
        permission: update ? user.permission : '',
        classes: update ? user.classes : [],
      }}
      onSubmit={async values => {
        const data = {
          name: formatName(values.name),
          phone: values.phone,
          email: values.email,
          permissions: values.permission,
          disabled: false,
          schoolID: u.schoolID,
        };
        if (values.classes.length > 0) {
          if (!update) {
            data.classes_admins = {
              data: values.classes.map(c => ({ classID: parseInt(c) })),
            };
          } else {
            await updateClassesAdmin({
              variables: {
                id: user.id,
                data: values.classes.map(c => ({
                  classID: parseInt(c),
                  adminID: user.id,
                })),
              },
            });
          }
        }

        if (update) {
          await updateUser({
            variables: {
              id: user.id,
              cols: data,
            },
            refetchQueries: [
              {
                query: GET_USERS_QUERY,
                variables: { id: u.schoolID },
                fetchPolicy: 'network-only',
              },
            ],
          });
        } else {
          try {
            await createUser({
              variables: {
                data,
              },
              refetchQueries: [
                {
                  query: GET_USERS_QUERY,
                  variables: { id: u.schoolID },
                  fetchPolicy: 'network-only',
                },
              ],
            });
          } catch (e) {
            console.log(e);
          }
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        phone: Yup.string().length(10),
        email: Yup.string()
          .email()
          .required('Required'),
        permission: Yup.string().required('Required'),
        classes: Yup.array(),
      })}
    >
      {props => {
        const { values, touched, errors, handleChange, handleBlur } = props;
        return (
          <>
            <Form className={classes.container}>
              <TextField
                label="User Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.name && touched.name && errors.name}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                label="Phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.phone && touched.phone && errors.phone}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email && touched.email && errors.email}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                select
                name="permission"
                label="Select Applicable Persmission"
                variant="outlined"
                value={values.permission}
                onChange={handleChange}
                fullWidth
                margin="normal"
              >
                {PERMISSIONS.map(option => (
                  <MenuItem key={option} value={option}>
                    {`${option}`}
                  </MenuItem>
                ))}
              </TextField>
              {values.permission === 'ADMIN' && (
                <Field
                  name="classes"
                  component={SelectField}
                  options={cls}
                  label="Classes"
                  isMulti
                />
              )}

              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                {update ? 'Update User' : 'Add User'}
              </Button>
            </Form>
            {(u.permissions === 'ADMIN' || u.permissions === 'SUPERADMIN') && (
              <UpdatePasswordForm user={user} updatePass={updatePass} />
            )}
          </>
        );
      }}
    </Formik>
  );
}
