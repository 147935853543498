import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Button, TextField, FormControl } from '@material-ui/core';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  save: {
    marginTop: 10,
    marginBottom: 30,
  },
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
    minHeight: 18,
  },
}));
export default function SmsSettings(props) {
  const {
    fields,
    setFields,
    sections,
    onChange,
    getValue,
    submitNames,
    customStyles,
  } = props;
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <Typography component="h1" variant="h5">
          SMS School Name For Classes
        </Typography>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />

        {fields.map((f, index) => {
          const allSelected = [];
          fields.map(a => {
            if (a.cls.length > 0) {
              a.cls.map(c => allSelected.push(c));
            }
            return a;
          });
          const options = sections.filter(
            s => allSelected.indexOf(s.value) === -1
          );
          return (
            <div key={`div-${index}`}>
              <Select
                key={`select-${index}`}
                name={`select-${index}`}
                value={getValue(f)}
                onChange={val => onChange(val, index)}
                options={options}
                isMulti
                placeholder="Select Classes"
                className={classes.select}
                zIndex={10 - index}
                styles={customStyles}
              />
              <FormControl fullWidth variant="outlined">
                <TextField
                  id={`input-${index}`}
                  label="Name"
                  value={f.name}
                  onChange={e => {
                    const newFields = [...fields];
                    newFields[index].name = e.target.value;
                    setFields(newFields);
                  }}
                  variant="outlined"
                />
              </FormControl>
            </div>
          );
        })}
        <Button
          onClick={() => {
            const newFields = [...fields];
            newFields.push({ cls: [], name: '' });
            setFields(newFields);
          }}
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Add
        </Button>
        <Button
          onClick={submitNames}
          variant="outlined"
          color="secondary"
          className={classes.save}
        >
          Save Names
        </Button>
      </Box>
    </>
  );
}
