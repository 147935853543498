import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Loader from './components/Loader';
import 'firebase/auth';
import UserContext from './components/UserContext';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    if (loading) return <Loader />;
    return (
      <UserContext.Consumer>
        {({ user }) => {
          if (user.schoolID === '0') {
            return <Redirect to="/schools" />;
          }
          if (user.permissions === 'TEACHER') {
            return <Redirect to="/teacher" />;
          }
          if (user.permissions === 'STUDENT') {
            return <Redirect to="/studentLectures" />;
          }
          return <Redirect to="/dashboard" />;
        }}
      </UserContext.Consumer>
    );
  }
}
