import React from 'react';
import UserContext from '../components/UserContext';
import Unauthorized from '../components/Unauthorized';

export default function Authorize(props) {
  const { user } = React.useContext(UserContext);
  const { pms } = props;
  if (user.permissions === 'SUPERADMIN') {
    return <>{props.children}</>;
  }
  if (user.schoolID && pms.indexOf(user.permissions) > -1) {
    return <>{props.children}</>;
  }
  return <Unauthorized />;
}
