import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import {
  CssBaseline,
  Container,
  CardMedia,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import CryptoJS from 'crypto-js';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import Copyright from '../components/Copyright';
import { GET_PARENT_FEES_QUERY } from '../resolvers/Queries';
import Loader from '../components/Loader';
import { CREATE_ORDER_MUTATION } from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    // border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
    paddingBottom: 80,
  },
  logo: {
    height: '100px',
    width: '100px',
    margin: '10px auto',
  },
  button: {
    marginLeft: 10,
  },
  link: {
    textDecoration: 'none',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#026776',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 50,
    color: '#FFF',
  },
  toPay: {
    width: 230,
    paddingLeft: 15,
  },
  razorpay: {
    width: 100,
  },
  payButton: {
    color: '#FFF',
    borderColor: '#FFF',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 350,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function OnlineFeesSelect(props) {
  const { client } = props;
  const { hash, schoolID, termID, studentID, school } = useParams();
  const safeHash = decodeURIComponent(hash);
  const [open, setOpen] = React.useState(false);
  const ENCRYPTION_KEY = 'FoCKvdqwepoi4y3EZlSage7XGottHw123LmyqJHvUhs=';
  const bytes = CryptoJS.AES.decrypt(safeHash.toString(), ENCRYPTION_KEY);
  const phone = bytes.toString(CryptoJS.enc.Utf8);
  const classes = useStyles();
  const [fees, setFees] = React.useState({});
  const [form, setForm] = React.useState(false);
  const formEM = React.useRef();
  const [formData, setFormData] = React.useState({});
  const studentsData = useQuery(GET_PARENT_FEES_QUERY, {
    variables: {
      schoolID,
      termID,
      studentID,
    },
    fetchPolicy: 'network-only',
    client,
  });

  const [createOrder, createOrderData] = useMutation(CREATE_ORDER_MUTATION, {
    client,
  });
  if (studentsData.loading || createOrderData.loading) return <Loader />;
  const totalToPay = Object.values(fees).reduce(
    (total, n) => total + n.amount,
    0
  );
  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <CardMedia
            className={classes.logo}
            component="div"
            image="/logo.png"
          />
          <h2 style={{ textAlign: 'center' }}>Online Fees For {school}</h2>
          <h3 style={{ textAlign: 'center' }}>
            Select Fees To Pay (2% Service Charge + GST Applicable)
          </h3>
          <div>
            {studentsData.data.getFees.map((s, index) => (
              <Card key={`${index}-sf`}>
                <CardContent>
                  <div className={classes.listItem}>
                    <div className={classes.info}>
                      <Typography variant="h6" component="h2">
                        {s.feeName}
                      </Typography>
                      {s.month !== '' && (
                        <Typography variant="subtitle1">
                          {`Month: ${s.month}`}
                        </Typography>
                      )}
                      <Typography variant="subtitle2">
                        {`Amount: ${s.amount}`}
                      </Typography>
                    </div>
                    <div className={classes.icon}>
                      {!fees[s.slug] && (
                        <AddCircleIcon
                          color="secondary"
                          fontSize="large"
                          onClick={() => {
                            const newFees = { ...fees };
                            newFees[s.slug] = s;
                            setFees(newFees);
                          }}
                        />
                      )}
                      {fees[s.slug] && (
                        <RemoveIcon
                          color="secondary"
                          fontSize="large"
                          onClick={() => {
                            const newFees = { ...fees };
                            delete newFees[s.slug];
                            setFees(newFees);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
          <div>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Please enter the following details to continue
              </DialogTitle>
              <DialogContent>
                <Formik
                  initialValues={{
                    name: '',
                    phone,
                    email: '',
                    amount: (
                      totalToPay * 1.02 +
                      totalToPay * 0.02 * 0.18
                    ).toFixed(2),
                  }}
                  onSubmit={async values => {
                    const data = {
                      amount: parseFloat(values.amount).toFixed(2),
                      firstname: values.name,
                      email: values.email,
                      phone: values.phone,
                      productinfo: Object.values(fees)
                        .map(f => `${f.feesID}:${f.amount}`)
                        .join('-'),
                      udf1: schoolID,
                      udf2: termID,
                      udf3: studentID,
                      udf4: Object.values(fees)
                        .map(f => `${f.feesID}:${f.amount}`)
                        .join('-'),
                    };
                    try {
                      const { data: orderData } = await createOrder({
                        variables: {
                          data,
                        },
                      });
                      const fd = {
                        ...data,
                        key: orderData.createOrder.key,
                        txnid: orderData.createOrder.txnid.toString(),
                        surl:
                          'https://sama.sstracker.in/onlinefees/verifyPayment',
                        furl:
                          'https://sama.sstracker.in/onlinefees/verifyPayment',
                        hash: orderData.createOrder.hash,
                      };
                      // console.log(fd);
                      setFormData(fd);
                      setForm(true);
                      formEM.current.submit();
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required('Required'),
                    phone: Yup.string().length(10),
                    email: Yup.string()
                      .email()
                      .required('Required'),
                    amount: Yup.number().required('Required'),
                  })}
                >
                  {p => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                    } = p;
                    return (
                      <Form className={classes.container}>
                        <TextField
                          label="First Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.name}
                          error={errors.name && touched.name}
                          margin="normal"
                          fullWidth
                          required
                          variant="outlined"
                        />
                        <TextField
                          label="Phone"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.phone}
                          error={errors.phone && touched.phone}
                          margin="normal"
                          fullWidth
                          required
                          variant="outlined"
                        />
                        <TextField
                          label="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.email}
                          error={errors.email && touched.email}
                          margin="normal"
                          fullWidth
                          required
                          variant="outlined"
                        />
                        <TextField
                          name="amount"
                          label="Amount to Pay"
                          variant="outlined"
                          value={values.amount}
                          onChange={handleChange}
                          fullWidth
                          disabled
                          margin="normal"
                        />

                        <Button
                          onClick={() => setOpen(false)}
                          color="primary"
                          variant="outlined"
                        >
                          Go Back
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="outlined"
                          className={classes.button}
                        >
                          Pay Now
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </DialogContent>
            </Dialog>
          </div>
          <div className={classes.bottom}>
            <div className={classes.bottomContainer}>
              <div className={classes.wrapper}>
                <div className={classes.toPay}>
                  <Typography variant="h6">
                    Amount: &#8377;{` ${totalToPay}`}
                  </Typography>
                </div>
                <div className={classes.razorpay}>
                  <Button
                    onClick={() => setOpen(true)}
                    className={classes.payButton}
                    disabled={totalToPay === 0}
                  >
                    CONTINUE
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {form && (
            <div style={{ opacity: 0 }}>
              <form
                id="realForm"
                method="POST"
                action="https://secure.payu.in/_payment"
                // action="https://sandboxsecure.payu.in/_payment"
                ref={formEM}
              >
                <input type="hidden" name="key" value={formData.key} />
                <input type="hidden" name="hash" value={formData.hash} />
                <input type="hidden" name="txnid" value={formData.txnid} />
                <input type="hidden" name="amount" value={formData.amount} />
                <input
                  type="hidden"
                  name="firstname"
                  value={formData.firstname}
                />
                <input type="hidden" name="email" value={formData.email} />
                <input
                  type="hidden"
                  name="productinfo"
                  value={formData.productinfo}
                />
                <input type="hidden" name="phone" value={formData.phone} />
                <input type="hidden" name="surl" value={formData.surl} />
                <input type="hidden" name="furl" value={formData.furl} />
                <input
                  type="hidden"
                  name="service_provider"
                  value="payu_paisa"
                />
                <input type="hidden" name="udf1" value={formData.udf1} />
                <input type="hidden" name="udf2" value={formData.udf2} />
                <input type="hidden" name="udf3" value={formData.udf3} />
                <input type="hidden" name="udf4" value={formData.udf4} />
              </form>
            </div>
          )}
          <Copyright />
          <div style={{ paddingBottom: 80 }} />
        </Container>
      </main>
    </div>
  );
}
