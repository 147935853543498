import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import Loader from '../components/Loader';
import {
  GET_SCHOOL_QUERY,
  CLASSES_QUERY,
  GET_TERMS_QUERY,
} from '../resolvers/Queries';
import Authorize from './Authorize';
import Layout from '../components/Layout';
import { UPDATE_CLASSES_MUTATION } from '../resolvers/Mutations';
import UserContext from '../components/UserContext';
import Terms from '../components/Terms';
import SchoolSettings from '../components/SchoolSettings';
import SmsSettings from '../components/SmsSettings';
import AccountSettings from '../components/AccountSettings';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  save: {
    marginTop: 10,
    marginBottom: 30,
  },
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
    minHeight: 18,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Settings() {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = React.useContext(UserContext);
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [sections, setSections] = React.useState([]);
  const [fields, setFields] = React.useState([
    { cls: [], name: '' },
    { cls: [], name: '' },
  ]);
  const client = useApolloClient();
  let clsData;
  const setupSecs = async (cls, schoolName, isMulti) => {
    const names = {};
    const secs = cls.map(c => {
      if (!isMulti) {
        if (!names[schoolName]) names[schoolName] = [];
        names[schoolName].push(c.id);
      } else {
        if (!names[c.smsSuffix]) names[c.smsSuffix] = [];
        names[c.smsSuffix].push(c.id);
      }
      return {
        value: c.id,
        label: c.class,
      };
    });
    const newFields = Object.keys(names).map(n => ({
      cls: names[n],
      name: n,
    }));
    setSections(secs);
    setFields(newFields);
  };

  const schoolData = useQuery(GET_SCHOOL_QUERY, {
    variables: { id: user.schoolID },
    onCompleted: async data => {
      clsData = await client.query({
        query: CLASSES_QUERY,
        variables: {
          schoolID: data.schools_by_pk.id,
          termID: data.schools_by_pk.termID,
        },
      });
      await setupSecs(
        clsData.data.classes,
        data.schools_by_pk.name,
        data.schools_by_pk.manySchoolNames
      );
    },
  });
  const termsData = useQuery(GET_TERMS_QUERY, {
    variables: { schoolID: user.schoolID },
  });
  const [updateClasses, updateClassesData] = useMutation(
    UPDATE_CLASSES_MUTATION
  );
  const permissions = ['SUPERADMIN', 'ADMIN'];

  const onChange = (val, index) => {
    const newFields = [...fields];
    newFields[index].cls =
      !!val && val.length > 0 ? val.map(item => item.value) : [];
    setFields(newFields);
  };
  const getValue = f => {
    if (sections) {
      return sections.filter(option => f.cls.indexOf(option.value) >= 0);
    }
    return [];
  };
  const submitNames = async () => {
    const data = [];
    let totalSelected = [];
    fields.map(fl => {
      totalSelected = totalSelected.concat(fl.cls);
      data.push({
        ids: fl.cls,
        suffix: fl.name,
      });
      return fl;
    });
    if (totalSelected.length !== sections.length) {
      enqueueSnackbar('All Classes Not Assigned A School Name!', {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } else {
      for (const item of data) {
        try {
          await updateClasses({
            variables: item,
          });
        } catch (e) {
          console.log(e);
        }
      }
      clsData = await client.query({
        query: CLASSES_QUERY,
        variables: {
          schoolID: schoolData.data.schools_by_pk.id,
          termID: schoolData.data.schools_by_pk.termID,
        },
        fetchPolicy: 'network-only',
      });
      await setupSecs(clsData.data.classes, schoolData.data.schools_by_pk.name);
    }
  };
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      zIndex: state.selectProps.zIndex,
    }),
  };
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  if (schoolData.loading || updateClassesData.loading || termsData.loading)
    return <Loader />;
  return (
    <Authorize pms={permissions}>
      <Layout>
        <AppBar position="static" color="default" className={classes.box}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            centered
            aria-label="full width tabs example"
          >
            <Tab label="School Settings" {...a11yProps(0)} />
            <Tab label="Terms Settings" {...a11yProps(1)} />
            <Tab
              label="SMS Names Settings"
              {...a11yProps(2)}
              disabled={!schoolData.data.schools_by_pk.manySchoolNames}
            />
            <Tab
              label="Bank Accounts"
              {...a11yProps(3)}
              disabled={!schoolData.data.schools_by_pk.onlineFees}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SchoolSettings school={schoolData.data.schools_by_pk} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Terms terms={termsData.data.terms} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SmsSettings
            fields={fields}
            setFields={setFields}
            sections={sections}
            onChange={onChange}
            getValue={getValue}
            submitNames={submitNames}
            customStyles={customStyles}
          />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <AccountSettings school={schoolData.data.schools_by_pk} />
        </TabPanel>
      </Layout>
    </Authorize>
  );
}
