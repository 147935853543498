import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
  button: {
    color: '#FFF',
  },
  payment: {
    marginBottom: 100,
  },
}));
export default function ConfirmFees(props) {
  const { open, handleConfirmClose, handleSubmit, amount } = props;
  const classes = useStyle();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          YOU NEED TO COLLECT &#8377;{amount}.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please make sure you have collected and counted the said amount as
            this record can not be edited or deleted after you click collect.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleConfirmClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmit(true)}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            COLLECT & PRINT
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmit(false)}
            className={classes.button}
            color="primary"
            autoFocus
          >
            COLLECT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
