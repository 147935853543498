import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/client';
import * as moment from 'moment';
import SelectField from './SelectField';
import {
  UPDATE_SECTION_MUTATION,
  CREATE_SECTION_MUTATION,
} from '../resolvers/Mutations';
import { GET_SECTIONS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import MyTimePicker from './TimePicker';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: 400,
    paddingBottom: 50,
  },
  button: {
    position: 'relative',
    top: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}));
export default function SectionForm(p) {
  const { update, cls, school, closeDialog, section, teachers, closeMain } = p;
  const [createSection, createSectionRes] = useMutation(
    CREATE_SECTION_MUTATION
  );
  const [updateSection, updateSectionRes] = useMutation(
    UPDATE_SECTION_MUTATION
  );
  const classes = useStyles();
  let ts;
  const isSubjectwise = school.attType === 'SUBJECTWISE';
  if (!isSubjectwise) {
    ts = teachers.map(t => ({ label: `${t.name} | ${t.email}`, value: t.id }));
  }
  if (createSectionRes.loading || updateSectionRes.loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        section: update ? section.section : '',
        class: update ? section.class : '',
        teachers: update ? section.teachers.map(t => t.teacherID) : [],
        // eslint-disable-next-line no-nested-ternary
        notificationTime: update
          ? section.notificationTime
            ? moment()
                .hour(section.notificationTime.split(':')[0])
                .minute(section.notificationTime.split(':')[1])
                .second('0')
            : moment()
          : moment(),
        sr: update ? section.sr : '',
      }}
      onSubmit={async values => {
        if (update) {
          const c = {
            section: values.section,
            classID: values.class,
          };
          if (!isSubjectwise) {
            const sectionTeachers = values.teachers.map(t => ({
              teacherID: t,
              sectionID: section.sectionID,
            }));
            c.sr = values.sr;
            c.notificationTime = values.notificationTime
              .second('0')
              .format('HH:mm:ssZ');
            await updateSection({
              variables: {
                sectionID: section.sectionID,
                data: c,
                sectionTeachers,
              },
              refetchQueries: [
                {
                  query: GET_SECTIONS_QUERY,
                  variables: {
                    id: school.id,
                    currentTerm: school.termID,
                  },
                },
              ],
            });
          }
        } else {
          const c = {
            section: values.section,
            termID: school.termID,
            classID: values.class,
            schoolID: school.id,
          };
          if (!isSubjectwise) {
            const tIds = values.teachers.map(t => ({ teacherID: t }));
            c.section_teachers = { data: tIds };
            c.sr = values.sr;
            c.notificationTime = values.notificationTime
              .second('0')
              .format('HH:mm:ssZ');
          }
          await createSection({
            variables: { data: c },
            refetchQueries: [
              {
                query: GET_SECTIONS_QUERY,
                variables: {
                  id: school.id,
                  currentTerm: school.termID,
                },
              },
            ],
          });
        }
        closeDialog();
        closeMain();
      }}
      validationSchema={Yup.object().shape({
        section: Yup.string().required(),
        class: Yup.string().required(),
        sr: Yup.number(),
        teachers: Yup.array(),
        notificationTime: Yup.date(),
      })}
    >
      {props => {
        const { values, touched, errors, handleChange, handleBlur } = props;
        return (
          <Form className={classes.container}>
            <Field
              name="class"
              component={SelectField}
              options={cls}
              label="Select Class"
              zIndex={9}
            />
            <TextField
              label="Section"
              name="section"
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.section && touched.section && errors.section}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />
            {!isSubjectwise && (
              <>
                <TextField
                  label="Serial No"
                  name="sr"
                  value={values.sr}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.sr && touched.sr && errors.sr}
                  margin="normal"
                  fullWidth
                  required
                  type="number"
                  variant="outlined"
                />
                <Field
                  name="teachers"
                  component={SelectField}
                  options={ts}
                  label="Select Teacher/s"
                  isMulti
                  zIndex={4}
                />
                <Field
                  name="notificationTime"
                  label="Notification Time"
                  component={MyTimePicker}
                />
                <div className={classes.break} />
              </>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
            >
              {update ? 'Update Section' : 'Add Section'}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
