import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Authorize from '../pages/Authorize';
import Loader from './Loader';
import AddExpenseForm from './AddExpenseForm';
import { EXPENSES_QUERY } from '../resolvers/Queries';
import ViewExpenses from './ViewExpenses';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper1: {
    width: '80%',
  },
  wrapper2: {
    width: '20%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function AddExpense(props) {
  const classes = useStyles();
  const { school, expCats, user } = props;
  const permissions = ['ADMIN', 'SUPERADMIN', 'FEES'];
  const expensesData = useQuery(EXPENSES_QUERY, {
    variables: {
      termID: school.termID,
      start: moment()
        .utc()
        .startOf('day'),
      end: moment()
        .utc()
        .endOf('day'),
    },
  });
  if (expensesData.loading) return <Loader />;
  return (
    <Authorize pms={permissions}>
      <Container className={classes.main} maxWidth="xl" disableGutters>
        <Paper className={classes.wrapper1}>
          <div className={classes.content}>
            <div>
              <h2>
                Total Expenses On {moment().format('DD-MM-YYYY')} :
                {` ₹ ${expensesData.data.expenses.reduce(
                  (total, b) => total + b.amount,
                  0
                )}`}
              </h2>
              <ViewExpenses expensesData={expensesData} user={user} />
            </div>
          </div>
        </Paper>
        <Paper className={classes.wrapper2}>
          <div className={classes.content}>
            <h2>Add New Expense</h2>
            <AddExpenseForm
              school={school}
              expCats={expCats}
              expensesData={expensesData}
            />
          </div>
        </Paper>
      </Container>
    </Authorize>
  );
}
