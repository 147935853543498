import gql from 'graphql-tag';

export const GET_SHORTURLS_QUERY = gql`
  query GET_SHORTURLS_QUERY($source: String!) {
    shorturls_by_pk(source: $source) {
      redirect
    }
  }
`;
export const GET_USER_QUERY = gql`
  query GET_USER_QUERY($id: String!) {
    user(where: { fbID: $id }) {
      id
      permissions
      email
      school {
        id
      }
    }
  }
`;

export const GET_SCHOOLS_QUERY = gql`
  query GET_SCHOOLS_QUERY {
    schools(order_by: { name: asc }, where: { isSociety: { _eq: false } }) {
      name
      id
      description
    }
  }
`;
export const GET_TERMS_QUERY = gql`
  query GET_TERMS_QUERY($schoolID: uuid!) {
    terms(where: { schoolID: { _eq: $schoolID } }) {
      id
      name
      current
      schoolID
    }
  }
`;
export const GET_SCHOOL_QUERY = gql`
  query GET_SCHOOL_QUERY($id: uuid!) {
    schools_by_pk(id: $id) {
      id
      name
      adminPhone
      description
      onlyAbsent
      defaultAbsent
      smsDisabled
      showRegisterNo
      senderID
      defaultMins
      displayMins
      breakdownLectures
      att_type
      subjectwiseSmsTime
      timetableSummary
      weeklySms
      weeklySmsDay
      weeklySmsTime
      manySchoolNames
      termID
      feesModule
      onlineFees
      feesDate
      multipleAccounts
      feePercent
      images(where: { type: { _eq: "logo" } }) {
        url
      }
      terms {
        id
        name
        current
      }
      qrcode
      liveLecture
      studentEmail
      chat
      attNotification
      buses
    }
  }
`;

export const GET_USERS_QUERY = gql`
  query GET_USERS_QUERY($id: uuid!) {
    users(where: { schoolID: { _eq: $id } }) {
      id
      fbID
      name
      permissions
      phone
      email
      disabled
      schoolID
      classes_admins {
        class {
          class
          id
        }
      }
    }
  }
`;
export const GET_TEACHERS_QUERY = gql`
  query GET_TEACHERS_QUERY($id: uuid!) {
    users(where: { schoolID: { _eq: $id }, permissions: { _eq: "TEACHER" } }) {
      id
      fbID
      name
      permissions
      phone
      email
      disabled
      schoolID
      classes_admins {
        class {
          class
          id
        }
      }
    }
  }
`;

export const CLASSES_QUERY = gql`
  query CLASSES_QUERY($schoolID: uuid!, $termID: bigint!) {
    classes(
      where: { schoolID: { _eq: $schoolID }, termID: { _eq: $termID } }
      order_by: [{ sections_aggregate: { min: { sr: asc } } }]
    ) {
      class
      id
      smsSuffix
    }
  }
`;
export const GET_SECTIONS_ONLY_QUERY = gql`
  query GET_SECTIONS_ONLY_QUERY($id: uuid!, $currentTerm: bigint!) {
    sections(
      where: { schoolID: { _eq: $id }, termID: { _eq: $currentTerm } }
      order_by: { sr: asc }
    ) {
      class {
        class
        id
      }
      id
      sr
      section
      section_teachers {
        sectionID
        teacherID
        user {
          name
          email
        }
      }
    }
  }
`;
export const GET_SECTIONS_QUERY = gql`
  query GET_SECTIONS_QUERY($id: uuid!, $currentTerm: bigint) {
    sections(
      where: { schoolID: { _eq: $id }, termID: { _eq: $currentTerm } }
      order_by: { sr: asc }
    ) {
      class {
        class
        id
      }
      id
      sr
      section
      fbID
      notificationTime
      section_teachers {
        sectionID
        teacherID
        user {
          name
          email
        }
      }
      students_aggregate(where: { termID: { _eq: $currentTerm } }) {
        aggregate {
          count
        }
      }
    }
  }
`;
export const GET_SUBJECTS_QUERY = gql`
  query GET_SUBJECTS_QUERY($sectionID: bigint!) {
    subjects(
      where: { sectionID: { _eq: $sectionID } }
      order_by: [{ subject: asc }]
    ) {
      id
      subject
      timetable
      class {
        class
      }
      sectionID
      section {
        section
      }
      subject_teachers {
        user {
          id
          email
          name
        }
      }
      books {
        id
        name
        pages
        books_teachers {
          user {
            id
            email
            name
          }
        }
      }
    }
  }
`;
export const GET_STUDENTS_QUERY = gql`
  query GET_STUDENTS_QUERY($id: uuid!, $termID: bigint!) {
    students(
      where: { schoolID: { _eq: $id }, termID: { _eq: $termID } }
      order_by: [{ section: { sr: asc } }, { disabled: asc }, { roll: asc }]
    ) {
      name
      phone
      registerNo
      roll
      id
      gender
      religion
      birthdate
      disabled
      qrcode
      loginEmail
      students_fees_structures(
        where: { fees_structure: { termID: { _eq: $termID } } }
      ) {
        fees_structure {
          id
          name
        }
      }
      term {
        name
      }
      class {
        class
        id
      }
      section {
        id
        section
        sr
      }
    }
  }
`;
export const GET_STUDENTS_ENABLED_QUERY = gql`
  query GET_STUDENTS_ENABLED_QUERY($termID: bigint!) {
    students(
      where: { termID: { _eq: $termID }, disabled: { _eq: false } }
      order_by: [{ section: { sr: asc } }, { disabled: asc }, { roll: asc }]
    ) {
      name
      phone
      registerNo
      roll
      id
      gender
      religion
      birthdate
      disabled
      qrcode
      loginEmail
      students_fees_structures(
        where: { fees_structure: { termID: { _eq: $termID } } }
      ) {
        fees_structure {
          id
          name
        }
      }
      term {
        name
      }
      class {
        class
        id
      }
      section {
        id
        section
        sr
      }
    }
  }
`;
export const GET_FEES_STRUCTURE_STUDENTS = gql`
  query GET_FEES_STRUCTURE_STUDENTS($termID: bigint!) {
    fees_structure(where: { termID: { _eq: $termID } }) {
      id
      name
      students_fees_structures(
        where: { student: { disabled: { _eq: false } } }
        order_by: [
          { student: { section: { sr: asc } } }
          { student: { disabled: asc } }
          { student: { roll: asc } }
        ]
      ) {
        student {
          name
          phone
          registerNo
          roll
          id
          gender
          religion
          birthdate
          disabled
          term {
            name
          }
          class {
            class
            id
          }
          section {
            id
            section
            sr
          }
        }
      }
    }
  }
`;
export const GET_SECTIONS_STUDENTS = gql`
  query GET_SECTIONS_STUDENTS($id: ID!, $currentTerm: String!) {
    sections(where: { school: { id: $id }, schoolyearID: $currentTerm }) {
      id
      fsID
      class {
        id
      }
      students {
        id
        fsID
      }
      teachers {
        id
        email
      }
    }
  }
`;
export const GET_REPORT_QUERY = gql`
  query GET_REPORT_QUERY($data: ReportInput!) {
    report(data: $data) {
      link
    }
  }
`;

export const GET_FEES_QUERY = gql`
  query GET_FEES_QUERY($feesStructureID: bigint!, $termID: bigint!) {
    fees(
      where: {
        feesStructureID: { _eq: $feesStructureID }
        termID: { _eq: $termID }
      }
      order_by: { sr: asc }
    ) {
      id
      sr
      name
      amount
      slug
      month
      createdAt
      updatedAt
    }
  }
`;

export const STUDENT_FEES_QUERY = gql`
  query STUDENT_FEES_QUERY(
    $schoolID: uuid!
    $termID: bigint!
    $studentID: bigint!
  ) {
    students_fees(
      where: {
        schoolID: { _eq: $schoolID }
        termID: { _eq: $termID }
        id: { _eq: $studentID }
      }
    ) {
      name
      amount
      slug
      feeName
      month
      feesID
      collectedAt
      collectedBy
      feesStatus
      chequeNo
    }
  }
`;
export const STUDENT_FEES_AGG_QUERY = gql`
  query STUDENT_FEES_AGG_QUERY(
    $schoolID: uuid!
    $termID: bigint!
    $studentID: bigint!
  ) {
    students_fees_terms_agg(
      where: {
        schoolID: { _eq: $schoolID }
        termID: { _eq: $termID }
        id: { _eq: $studentID }
      }
      order_by: { sr: asc }
    ) {
      fixedAmount
      sr
      name
      amount
      slug
      feeName
      month
      feesID
      collectedAt
      collectedIDs
      collectedBy
      collectedAmount
      pendingAmount
      collectedAmounts
      feesStatuses
      chequeNos
    }
  }
`;
export const GET_ATTREPORT_QUERY = gql`
  query GET_ATTREPORT_QUERY(
    $termID: bigint!
    $attTimeGte: timestamptz!
    $attTimeLte: timestamptz!
  ) {
    attendances(
      where: {
        termID: { _eq: $termID }
        _and: [
          { attTime: { _gte: $attTimeGte } }
          { attTime: { _lte: $attTimeLte } }
        ]
      }
    ) {
      attTime
      sectionID
      studentAttendances {
        value
        studentID
      }
    }
  }
`;
export const GET_STUDENTS_BY_PHONE = gql`
  query GET_STUDENTS_BY_PHONE($hash: String!) {
    getStudents(hash: $hash) {
      name
      id
      schoolID
      termID
      phone
      section
      school
    }
  }
`;
export const GET_PARENT_FEES_QUERY = gql`
  query GET_PARENT_FEES_QUERY(
    $schoolID: uuid!
    $termID: bigint!
    $studentID: bigint!
  ) {
    getFees(
      data: { schoolID: $schoolID, termID: $termID, studentID: $studentID }
    ) {
      month
      feeName
      feesStatus
      amount
      slug
      feesID
    }
  }
`;

export const GET_TERM_FEES_SUB = gql`
  query GET_TERM_FEES_SUB($termID: bigint!) {
    term_fees(where: { termID: { _eq: $termID } }) {
      sum
      month
      feesStatus
    }
  }
`;
export const GET_TERM_FEES = gql`
  query GET_TERM_FEES($termID: bigint!) {
    term_fees(where: { termID: { _eq: $termID } }) {
      sum
      month
      feesStatus
    }
  }
`;
export const GET_CLEARING_CHEQUES = gql`
  query GET_CLEARING_CHEQUES($termID: bigint!, $studentID: bigint!) {
    clearing_cheques(
      where: { termID: { _eq: $termID }, studentID: { _eq: $studentID } }
    ) {
      chequeNo
      paidAmount
      collectedObjs
      totalAmount
      collectedAt
    }
  }
`;
export const GET_FEES_STRUCTURE_QUERY = gql`
  query GET_FEES_STRUCTURE_QUERY($termID: bigint!) {
    fees_structure(where: { termID: { _eq: $termID } }) {
      id
      name
      account {
        name
      }
    }
  }
`;
export const GET_FEES_STRUCTURES_ALL_TERMS_QUERY = gql`
  query GET_FEES_STRUCTURES_ALL_TERMS_QUERY($schoolID: uuid!) {
    fees_structure(where: { schoolID: { _eq: $schoolID } }) {
      id
      name
      account {
        name
      }
    }
  }
`;

export const GET_FEES_COLLECTED_QUERY = gql`
  query GET_FEES_COLLECTED_QUERY($termID: bigint!) {
    fees_collected(
      where: { termID: { _eq: $termID } }
      order_by: { collectedAt: desc }
    ) {
      amount
      student {
        name
        class {
          class
        }
        section {
          section
        }
        registerNo
      }
      id
      feesStatus
      fee {
        name
        month
      }
      collectedAt
      chequeNo
      user {
        name
      }
    }
  }
`;

export const ACCOUNTS_QUERY = gql`
  query ACCOUNTS_QUERY($schoolID: uuid!) {
    accounts(where: { schoolID: { _eq: $schoolID } }) {
      name
      accNumber
      ifsc
      id
    }
  }
`;
export const FEES_COLLECTED_QUERY = gql`
  query FEES_COLLECTED_QUERY($paymentID: bigint!) {
    fees_collected(where: { paymentID: { _eq: $paymentID } }) {
      amount
      chequeNo
      collectedAt
      fee {
        name
      }
      feesStatus
    }
  }
`;
export const PAYMENTS_COLLECTED_QUERY = gql`
  query PAYMENTS_COLLECTED_QUERY($schoolID: uuid!) {
    receipt_view(
      where: { schoolID: { _eq: $schoolID }, deleted: { _eq: false } }
      order_by: { createdAt: desc }
    ) {
      termID
      id
      receiptno
      deleted
      createdAt
      collectedAt
      payment {
        id
        amount
        student {
          name
          registerNo
          class {
            class
          }
          section {
            section
          }
        }
        fees_collecteds {
          user {
            name
          }
          fee {
            name
            month
            amount
          }
          collectedAt
          amount
          chequeNo
          feesStatus
        }
      }
    }
  }
`;
export const DELETED_PAYMENTS_COLLECTED_QUERY = gql`
  query DELETED_PAYMENTS_COLLECTED_QUERY($termID: bigint!) {
    receipt_view(
      where: { termID: { _eq: $termID }, deleted: { _eq: true } }
      order_by: { createdAt: desc }
    ) {
      id
      receiptno
      deleted
      updatedAt
      amount
      student {
        name
        registerNo
        class {
          class
        }
        section {
          section
        }
      }
    }
  }
`;
export const RECEIPT_QUERY = gql`
  query RECEIPT_QUERY($paymentID: bigint!) {
    receipt_view(where: { id: { _eq: $paymentID } }) {
      id
      receiptno
      amount
    }
  }
`;
export const TODAY_FEES = gql`
  query TODAY_FEES(
    $termID: bigint
    $start: date!
    $end: date!
    $schoolID: uuid
  ) {
    payments_view(
      where: {
        _and: [
          { schoolID: { _eq: $schoolID } }
          { termID: { _eq: $termID } }
          { createdAt: { _gte: $start } }
          { createdAt: { _lt: $end } }
        ]
      }
    ) {
      sum
      createdAt
    }
  }
`;

export const PENDING_FEES = gql`
  query PENDING_FEES($termID: bigint!) {
    pending_fees_view(where: { termID: { _eq: $termID } }) {
      amount
      student {
        id
        name
        registerNo
        roll
        class {
          class
        }
        section {
          section
        }
      }
    }
  }
`;
export const EXPENSE_CAT_QUERY = gql`
  query EXPENSE_CAT_QUERY($schoolID: uuid!) {
    expense_cats(where: { schoolID: { _eq: $schoolID } }) {
      id
      category
    }
  }
`;
export const EXPENSES_QUERY = gql`
  query EXPENSES_QUERY(
    $termID: bigint!
    $start: timestamptz!
    $end: timestamptz!
  ) {
    expenses(
      where: {
        _and: [
          { termID: { _eq: $termID } }
          { enteredOn: { _gte: $start } }
          { enteredOn: { _lte: $end } }
          { deleted: { _neq: true } }
        ]
      }
      order_by: { enteredOn: desc }
    ) {
      id
      amount
      comment
      expense_cat {
        category
      }
      paidOn
      enteredOn
      user {
        name
      }
    }
  }
`;
export const EXPENSES_RANGE_QUERY = gql`
  query EXPENSES_RANGE_QUERY(
    $termID: bigint!
    $start: timestamptz!
    $end: timestamptz!
  ) {
    expenses(
      where: {
        _and: [
          { termID: { _eq: $termID } }
          { paidOn: { _gte: $start } }
          { paidOn: { _lte: $end } }
          { deleted: { _neq: true } }
        ]
      }
      order_by: { paidOn: desc }
    ) {
      id
      amount
      comment
      expense_cat {
        category
      }
      paidOn
      enteredOn
      user {
        name
      }
    }
  }
`;

export const GET_DAILY_CLASSES = gql`
  query GET_DAILY_CLASSES(
    $whereSection: sections_bool_exp!
    $whereAtt: attendances_bool_exp
    $whereSubject: subjects_bool_exp
    $whereBook: books_bool_exp
  ) {
    sections(where: $whereSection) {
      section_teachers {
        user {
          id
          name
        }
      }
      sr
      section
      id
      class {
        class
        id
      }
      students_aggregate {
        aggregate {
          count
        }
      }
      subjects(where: $whereSubject) {
        id
        subject
        subject_teachers {
          user {
            id
            name
          }
        }
        attendances(where: $whereAtt, order_by: [{ createdAt: desc }]) {
          date
          month
          id
          live
          createdAt
          subjectID
          teacherAttendance {
            id
            value
            teacher {
              id
              name
            }
          }
        }
        books(where: $whereBook) {
          id
          name
          books_teachers {
            user {
              id
              name
            }
          }
          attendances(where: $whereAtt) {
            date
            month
            id
            createdAt
            live
            subjectID
            bookID
            teacherAttendance {
              id
              value
              teacher {
                id
                name
              }
            }
          }
        }
      }
      attendances(where: $whereAtt) {
        date
        month
        id
        createdAt
        live
        subjectID
        teacherAttendance {
          id
          value
          teacher {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TEACHER_QUERY = gql`
  query GET_TEACHER_QUERY($userID: uuid!) {
    users_by_pk(id: $userID) {
      id
      permissions
      email
      name
      phone
      zoomID
      zoomPassword
      zoomLink
      school {
        id
        name
        termID
        terms {
          name
          current
          id
        }
      }
    }
  }
`;
export const GET_STUDENTS_BY_LOGIN = gql`
  query GET_STUDENTS_BY_LOGIN($email: String!) {
    students(where: { loginEmail: { _eq: $email }, disabled: { _eq: false } }) {
      id
      schoolID
      sectionID
      classID
      name
      roll
      loginEmail
      class {
        class
      }
      section {
        section
      }
      school {
        id
        name
        termID
        terms {
          name
          current
          id
        }
      }
    }
  }
`;

export const START_LIVE_MERGED_QUERY = gql`
  query START_LIVE_MERGED_QUERY($data: AttInputMerged!) {
    startLiveMerged(data: $data) {
      id
      bookID
    }
  }
`;
export const GET_LIVE_STUDENTS_ATTS = gql`
  query GET_LIVE_STUDENTS_ATTS($attID: [bigint!]!) {
    studentAttendances(
      where: { attendanceID: { _in: $attID } }
      order_by: [
        { studentID: asc }
        { active: desc }
        { student: { roll: asc } }
      ]
      distinct_on: [studentID]
    ) {
      id
      value
      active
      studentID
      student {
        name
        roll
        id
        class {
          class
        }
        section {
          section
        }
      }
    }
  }
`;

export const GET_ATT_LIVE_QUERY = gql`
  query GET_ATT_LIVE_QUERY($attID: [bigint!]!, $studentIDs: [bigint!]) {
    attendances(where: { id: { _in: $attID } }) {
      id
      live
      bookID
      studentAttendances(where: { studentID: { _in: $studentIDs } }) {
        id
        studentID
        student {
          name
        }
        value
        active
        notifications
      }
      teacherAttendance {
        teacher {
          zoomID
          zoomPassword
        }
      }
    }
  }
`;
export const GET_LIVEATT_QUERY = gql`
  query GET_LIVEATT_QUERY($studentIDs: [bigint!]!, $sectionIDs: [bigint!]!) {
    attendances(
      where: { live: { _eq: true }, sectionID: { _in: $sectionIDs } }
    ) {
      id
      live
      createdAt
      sectionID
      section {
        section
      }
      class {
        class
      }
      subject {
        subject
      }
      book {
        name
      }
      teacherAttendance {
        teacher {
          zoomID
          zoomPassword
        }
      }
      studentAttendances(where: { studentID: { _in: $studentIDs } }) {
        id
        studentID
        student {
          name
        }
        value
        active
        notifications
      }
    }
  }
`;

export const GET_BOOKS_PROGESS = gql`
  query GET_BOOKS_PROGESS($bookIDs: [bigint!]!, $termID: bigint!) {
    books_progress(
      where: { bookID: { _in: $bookIDs }, termID: { _eq: $termID } }
      order_by: [{ bookID: asc }, { createdAt: desc }]
      distinct_on: [bookID]
    ) {
      id
      to
      book {
        name
        id
      }
    }
  }
`;
export const GET_BOOKS_AND_PROGRESS = gql`
  query GET_BOOKS_AND_PROGRESS($bookIDs: [bigint!]!, $termID: bigint!) {
    books(where: { id: { _in: $bookIDs } }) {
      id
      name
      books_progresses(
        where: { termID: { _eq: $termID } }
        order_by: [{ bookID: asc }, { createdAt: desc }]
        distinct_on: [bookID]
      ) {
        id
        to
      }
    }
  }
`;

export const GET_ATT_LIVE_STUDENT_QUERY = gql`
  query GET_ATT_LIVE_STUDENT_QUERY($attID: bigint!, $studentIDs: [bigint!]) {
    attendances_by_pk(id: $attID) {
      id
      live
      bookID
      studentAttendances(where: { studentID: { _in: $studentIDs } }) {
        id
        studentID
        student {
          name
        }
        value
        active
        notifications
      }
      teacherAttendance {
        teacher {
          zoomLink
        }
      }
    }
  }
`;

export const GET_BUSES_QUERY = gql`
  query GET_BUSES_QUERY($schoolID: uuid!) {
    buses(where: { schoolID: { _eq: $schoolID } }) {
      id
      busno
      deviceID
      gpsID
      simNumber
      disabled
    }
  }
`;
