import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import UploadData from './UploadData';
import { CREATE_USERS_MUTATION } from '../resolvers/Mutations';
import Loader from './Loader';
import { GET_USERS_QUERY } from '../resolvers/Queries';
import UserContext from './UserContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));
export default function BulkAddUsers() {
  const classes = useStyles();
  const { user: u } = React.useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = React.useState([]);
  const [submitEnabled, setsubmitEnabled] = React.useState(false);
  const [createUsers, { error, loading }] = useMutation(CREATE_USERS_MUTATION);
  const schema = yup.object().shape({
    name: yup.string().required(),
    phone: yup
      .string()
      .required()
      .length(10),
    email: yup
      .string()
      .email()
      .required(),
  });
  const formatName = name =>
    name
      .replace(/\s\s+/g, ' ')
      .replace(/\.(?=[^\s])/g, '. ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  const userImport = [
    ['name', 'phone', 'email'],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
    ['', '', ''],
  ];
  const handleSubmit = async () => {
    try {
      await createUsers({
        variables: {
          data: users,
        },
        refetchQueries: [
          {
            query: GET_USERS_QUERY,
            variables: { id: u.schoolID },
            fetchPolicy: 'network-only',
          },
        ],
      });
      enqueueSnackbar(`Teachers Successfully Imported`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } catch (e) {
      enqueueSnackbar(`Error in Apollo Action. ${e.message}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
  };
  const handleForce = da => {
    const usersArray = [];
    for (const user of da) {
      if (!!user.name && !!user.email && !!user.phone) {
        schema
          .isValid({
            name: user.name,
            phone: user.phone,
            email: user.email,
          })
          .then(function(valid) {
            if (valid) {
              const tmp = {
                name: formatName(user.name),
                email: user.email,
                phone: user.phone,
                permissions: 'TEACHER',
                schoolID: u.schoolID,
              };
              usersArray.push(tmp);
            } else {
              enqueueSnackbar(
                'Some field is not valid. Please Recheck The File And Try Again',
                {
                  variant: 'error',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                }
              );
            }
          });
      } else {
        enqueueSnackbar(
          'Some Field is blank. Please Recheck The File And Try Again',
          {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }
        );
      }
    }
    setUsers(usersArray);
    setsubmitEnabled(true);
    enqueueSnackbar('File looks good. Please click submit to import.', {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    });
  };
  if (loading) return <Loader />;
  if (error) {
    console.log(error);
    enqueueSnackbar(`Error in Apollo Action. Check console for more info.`, {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    });
  }
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <UploadData
            template={userImport}
            filename="UserTemplate.csv"
            checkData={handleForce}
            title="Upload CSV file for User import"
          />
          <Divider />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!submitEnabled}
          >
            Upload Users
          </Button>
        </div>
      </Paper>
      <Paper className={classes.wrapper}>
        <Typography className={classes.content} component="h1" variant="h5">
          Usage Guide
        </Typography>
        <Divider />
        <Typography className={classes.content} gutterBottom variant="body1">
          1 - Please make sure the file you are uploading has the following 3
          fields: name, email, phone.
        </Typography>

        <Typography className={classes.content} gutterBottom variant="body1">
          3 - Only teachers can be added via bulk create. Other users can not be
          added via file upload.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          4 - Download the User Template to make sure that all the fields exist.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          5 - Username for the user will be their email address and password
          will be their phone number. Passwords can be reset through the app.
        </Typography>
        <Typography className={classes.content} gutterBottom variant="body1">
          6 - Phone field needs to have only 10 numbers. Default phone number
          (password) is 1234567890.
        </Typography>
      </Paper>
    </Container>
  );
}
