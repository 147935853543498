import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import Report from './Report';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function AttReports(props) {
  const { school, sections, years } = props;
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          centered
          aria-label="full width tabs example"
        >
          <Tab label="Condensed Report" {...a11yProps(0)} />
          <Tab label="Detailed Report" {...a11yProps(1)} />
          <Tab label="Weekly Progressive Report" {...a11yProps(2)} />
          <Tab label="Attention Analysis" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Report
          school={school}
          sections={sections}
          years={years}
          type="condensed"
        />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Report
          school={school}
          sections={sections}
          years={years}
          type="detailed"
        />
      </TabPanel>
      {/* <TabPanel value={value} index={2} dir={theme.direction}>
        <Report school={school} sections={sections} years={years} type="all" />
      </TabPanel> */}
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Report
          school={school}
          sections={sections}
          years={years}
          type="progressive"
        />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Report
          school={school}
          sections={sections}
          years={years}
          type="attention"
        />
      </TabPanel>
    </Box>
  );
}
