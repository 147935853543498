import React from 'react';
import ViewSections from './ViewSections';

export default function ViewSectionSubjects(props) {
  const { school } = props;
  if (school.attType !== 'SUBJECTWISE') {
    return <ViewSections school={school} />;
  }
  return <h1>Subjects</h1>;
}
