import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Table, { tableIcons } from './Table';
import Loader from './Loader';
import { GET_BUSES_QUERY } from '../resolvers/Queries';
import { ADD_BUS_MUTATION, UPDATE_BUS_MUTATION } from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  save: {
    marginTop: 10,
    marginBottom: 30,
  },
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
    minHeight: 18,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  input: {
    padding: '5px 5px',
  },

  button2: {
    marginTop: 30,
    color: 'white',
  },
  buttonDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default function BusList({ schoolData }) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { data: buses, loading } = useQuery(GET_BUSES_QUERY, {
    variables: { schoolId: schoolData.id },
  });
  const addBus = useMutation(ADD_BUS_MUTATION);
  const updateBus = useMutation(UPDATE_BUS_MUTATION);
  const [dialogOpen, setDialogClose] = React.useState(false);
  const [rowD, setRowD] = React.useState({});
  const [update, setUpdate] = React.useState(false);

  if (loading) return <Loader />;
  return (
    <Box className={classes.container}>
      <Table
        columns={[
          { title: 'ID', field: 'id', hidden: true },
          { title: 'Bus No', field: 'busno', width: 20 },
          { title: 'Device ID', field: 'deviceID' },
          { title: 'GPS ID', field: 'gpsID', hidden: true },
          { title: 'Sim Number', field: 'simNumber', hidden: true },
          {
            title: 'Enabled',
            field: 'disabled',
            render: rowData => (
              <span>{rowData.disabled ? 'Disabled' : 'Enabled'}</span>
            ),
          },
        ]}
        data={buses}
        title="Students"
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: 'Edit User',
            onClick: (event, rowData) => {
              setRowD(rowData);
              setDialogClose(true);
            },
          },
          rowData => ({
            icon: rowData.disabled ? tableIcons.Enable : tableIcons.Disable,
            tooltip: rowData.disabled ? 'Enable Student' : 'Disable Student',
            onClick: async (event, d) => {
              // Disbale bus
              enqueueSnackbar(
                rowData.disabled
                  ? `User Has Been Successfully Enabled`
                  : `User Has Been Successfully Disabled`,
                {
                  variant: 'success',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                }
              );
            },
          }),
        ]}
      />
      <Fab
        color="secondary"
        aria-label="add"
        style={{
          position: 'absolute',
          bottom: theme.spacing(10),
          right: theme.spacing(10),
        }}
        onClick={() => setDialogClose(true)}
      >
        <AddIcon
          style={{
            color: '#fff',
          }}
        />
      </Fab>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogClose(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        style={{ zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <DialogTitle onClose={() => setDialogClose(false)}>
          {update ? 'Update Bus' : 'Add Bus'}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              busno: update ? rowD.busno : '',
              deviceID: update ? rowD.deviceID : '',
              simNumber: update ? rowD.simNumber : '',
              disabled: update ? rowD.disabled : false,
            }}
            onSubmit={async values => {
              try {
                if (!update) {
                  await addBus({
                    variables: {
                      data: {
                        busno: values.busno,
                        deviceID: values.deviceID,
                        simNumber: values.simNumber,
                        schoolID: schoolData.id,
                      },
                    },
                    refetchQueries: [
                      {
                        query: GET_BUSES_QUERY,
                        variables: { schoolId: schoolData.id },
                      },
                    ],
                  });
                } else {
                  await updateBus({
                    variables: {
                      id: rowD.id,
                      data: {
                        busno: values.busno,
                        deviceID: values.deviceID,
                        simNumber: values.simNumber,
                        schoolID: schoolData.id,
                      },
                    },
                    refetchQueries: [
                      {
                        query: GET_BUSES_QUERY,
                        variables: { schoolId: schoolData.id },
                      },
                    ],
                  });
                }
                setDialogClose(false);
                enqueueSnackbar(
                  update
                    ? `Bus Has Been Successfully Updated`
                    : `Bus Has Been Successfully Added`,
                  {
                    variant: 'success',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }
                );
              } catch (e) {
                setDialogClose(false);
                enqueueSnackbar(
                  update
                    ? `The bus was not updated`
                    : `The Bus Has Not Been Added`,
                  {
                    variant: 'error',
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }
                );
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <Form
                style={{
                  width: '100%',
                  minHeight: 18,
                }}
              >
                <TextField
                  label="Bus No"
                  name="busno"
                  value={values.busno}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.busno && touched.busno && errors.busno}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
                <div className={classes.break} />
                <TextField
                  label="Device ID"
                  name="deviceID"
                  value={values.deviceID}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.deviceID && touched.deviceID && errors.deviceID
                  }
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
                <div className={classes.break} />

                <TextField
                  label="Sim Number"
                  name="simNumber"
                  value={values.simNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.simNumber && touched.simNumber && errors.simNumber
                  }
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
                <div className={classes.break} />
                <div className={classes.break} />
                <div className={classes.break} />
                <div className={classes.buttonDiv}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    startIcon={<CancelIcon />}
                    onClick={() => setDialogClose(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                    style={{
                      color: '#fff',
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    {update ? 'Update Student' : 'Add Student'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
