import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import Layout from '../components/Layout';
import Authorize from './Authorize';
import { GET_TERMS_QUERY } from '../resolvers/Queries';
import UserContext from '../components/UserContext';
import Loader from '../components/Loader';
import PlainSelect from '../components/PlainSelect';
import PromoteSections from '../components/PromoteSections';
import PromoteStudents from '../components/PromoteStudents';

const useStyles = makeStyles(theme => ({
  paper: {},
  flexDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  },
  button: {
    height: 50,
    width: '100%',
  },
  box: {
    marginTop: 10,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Promotions() {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { user } = React.useContext(UserContext);
  const [confirmedYear, setConfirmedYear] = React.useState(false);
  const [selectedTerm, setSelectedTerm] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [currentTerm, setCurrentTerm] = React.useState('');
  const termsData = useQuery(GET_TERMS_QUERY, {
    variables: {
      schoolID: user.schoolID,
    },
    onCompleted: data => {
      const opts = data.terms
        .filter(t => !t.current)
        .map(t => ({ value: t.id, label: t.name }));
      const [c] = data.terms.filter(t => t.current);
      setCurrentTerm(c.id);
      setOptions(opts);
    },
  });
  const permissions = ['SUPERADMIN', 'ADMIN'];
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };

  if (termsData.loading) return <Loader />;
  return (
    <Layout>
      <Authorize pms={permissions}>
        <Paper className={classes.paper}>
          <div className={classes.flexDiv}>
            <div style={{ flex: 1, padding: 10 }}>
              <PlainSelect
                options={options}
                placeholder="Select Term To Promote From"
                selectedVal={selectedTerm}
                onChange={setSelectedTerm}
                isDisabled={confirmedYear}
              />
            </div>
            <div style={{ flex: 1, padding: 10 }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={confirmedYear}
                onClick={() => {
                  if (selectedTerm) setConfirmedYear(true);
                }}
              >
                Confirm Year
              </Button>
            </div>
            <div style={{ flex: 1, padding: 10 }}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={!confirmedYear}
                onClick={() => setConfirmedYear(false)}
              >
                Reset Selection
              </Button>
            </div>
          </div>
        </Paper>
        {confirmedYear && (
          <>
            <AppBar position="static" color="default" className={classes.box}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                // variant="fullWidth"
                centered
                aria-label="full width tabs example"
              >
                <Tab label="Promote Sections" {...a11yProps(0)} />
                <Tab label="Promote Students" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <PromoteSections
                prevTerm={selectedTerm.value}
                currentTerm={currentTerm}
                schoolID={user.schoolID}
                reset={() => setConfirmedYear(false)}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <PromoteStudents
                prevTerm={selectedTerm.value}
                currentTerm={currentTerm}
                schoolID={user.schoolID}
                reset={() => setConfirmedYear(false)}
              />
            </TabPanel>
          </>
        )}
      </Authorize>
    </Layout>
  );
}
