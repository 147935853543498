import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Container,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';

import { PDFDownloadLink } from '@react-pdf/renderer';
import Authorize from '../pages/Authorize';
import {
  ADD_STUDENTS_FEES_STRUCTURE,
  CREATE_STUDENT_MUTATION,
  DEL_STUDENTS_FEES_STRUCTURE,
  DELETE_STUDENTS_MUTATION,
  UPDATE_STUDENT_MUTATION,
} from '../resolvers/Mutations';
import {
  GET_FEES_STRUCTURE_QUERY,
  GET_SECTIONS_QUERY,
  GET_STUDENTS_ENABLED_QUERY,
  GET_STUDENTS_QUERY,
} from '../resolvers/Queries';
import Loader from './Loader';
import QRCodePrintStudents from './QRCodePrintStudents';
import UploadData from './UploadData';
import UserContext from './UserContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  button2: {
    marginTop: 30,
    color: 'white',
    textDecoration: 'none',
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  hide: {
    display: 'none',
  },
}));
export default function BulkEditStudents(props) {
  const classes = useStyles();
  const { school } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { user } = React.useContext(UserContext);
  const permissions = ['SUPERADMIN'];
  const [students, setStudents] = React.useState([]);
  const [qrStudents, setQrStudents] = React.useState([]);
  const [submitEnabled, setsubmitEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [createStudent] = useMutation(CREATE_STUDENT_MUTATION);
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION);
  const [addStFeeStruct] = useMutation(ADD_STUDENTS_FEES_STRUCTURE);
  const [delStFeeStruct] = useMutation(DEL_STUDENTS_FEES_STRUCTURE);
  const [deleteStudent] = useMutation(DELETE_STUDENTS_MUTATION);
  const currentYearID = school.termID;
  const feesStructureData = useQuery(GET_FEES_STRUCTURE_QUERY, {
    variables: { termID: school.termID },
    fetchPolicy: 'network-only',
  });
  const sectionsRes = useQuery(GET_SECTIONS_QUERY, {
    variables: {
      id: school.id,
      currentTerm: currentYearID,
    },
  });
  const getStudentsData = useQuery(
    user.permissions === 'SUPERADMIN'
      ? GET_STUDENTS_ENABLED_QUERY
      : GET_STUDENTS_QUERY,
    {
      variables: {
        id: user.permissions === 'SUPERADMIN' ? undefined : school.id,
        termID: school.termID,
      },
      onCompleted: ({ students: sts }) => {
        const qrcode = [];
        const st = sts.map(s => {
          const qrcodeTmp = {
            id: s.id,
            name: s.name,
            email: s.loginEmail,
            qrcode: s.qrcode,
          };
          if (qrcodeTmp.qrcode && qrcodeTmp.email) qrcode.push(qrcodeTmp);
          const tmp = {
            action: '',
            class: s.class.class,
            section: s.section.section,
            roll: s.roll,
            name: s.name,
            phone: s.phone || '',
            registerNo: s.registerNo || '',
            gender: s.gender || '',
            religion: s.religion || '',
            birthdate: s.birthdate
              ? moment(s.birthdate).format('DD-MM-YYYY')
              : '',
            disabled: s.disabled ? 'TRUE' : 'FALSE',
            id: s.id,
            term: s.term.name,
            feesCat:
              s.students_fees_structures.length > 0
                ? s.students_fees_structures[0].fees_structure.name
                : '',
          };
          return tmp;
        });
        setStudents(st);
        setQrStudents(qrcode);
      },
    }
  );
  const formatName = name =>
    name
      .replace(/\s\s+/g, ' ')
      .replace(/\.(?=[^\s])/g, '. ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  const schema = Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      phone: Yup.string().length(10),
      roll: Yup.number(),
      registerNo: Yup.mixed(),
      section: Yup.string().required(),
      class: Yup.string().required(),
      gender: Yup.string().matches(/(MALE|FEMALE|OTHER|BLANK)/),
      birthdate: Yup.date().nullable(),
      religion: Yup.string(),
      term: Yup.string().required(),
      id: Yup.string(),
      action: Yup.string().length(1),
      disabled: Yup.boolean(),
      feesCat: Yup.string(),
    })
  );

  const cls = {};
  if (sectionsRes.data) {
    sectionsRes.data.sections.map(c => {
      const cl = c.class.class
        .toLowerCase()
        .trim()
        .replace(' ', '_');
      const sc = c.section
        .toLowerCase()
        .trim()
        .replace(' ', '_');
      if (typeof cls[cl] === 'undefined')
        cls[cl] = { id: c.class.id, sections: {} };
      if (typeof cls[cl].sections[sc] === 'undefined')
        cls[cl].sections[sc] = {
          id: c.id,
        };
      return c;
    });
  }

  const handleSubmit = async () => {
    setLoading(true);
    const actionPromises = [];
    for (const st of students) {
      const tmp = {
        name: formatName(st.name),
        phone: st.phone,
        roll: st.roll,
        registerNo: st.registerNo,
        termID: parseInt(st.term),
        disabled: st.disabled,
        gender: st.gender,
        religion: st.religion,
        schoolID: school.id,
        classID: parseInt(st.class),
        sectionID: parseInt(st.section),
      };
      if (st.birthdate) tmp.birthdate = st.birthdate;

      if (st.action === 'U') {
        await updateStudent({
          variables: {
            id: st.id,
            data: tmp,
          },
        });
        if (st.feesStructureID) {
          try {
            await delStFeeStruct({
              variables: {
                studentID: parseInt(st.id),
                termID: school.termID,
              },
            });
            await addStFeeStruct({
              variables: {
                students_fees_structures: {
                  studentID: parseInt(st.id),
                  feesStructureID: st.feesStructureID,
                },
              },
            });
          } catch (e) {
            console.log(e);
          }
        }
      } else if (st.action === 'A') {
        if (st.feesStructureID) {
          tmp.students_fees_structures = {
            data: [
              {
                feesStructureID: st.feesStructureID,
              },
            ],
          };
        }
        tmp.createTermID = currentYearID;
        actionPromises.push(
          createStudent({
            variables: {
              data: tmp,
            },
          })
        );
      } else if (st.action === 'D') {
        if (user.permissions === 'SUPERADMIN') {
          actionPromises.push(
            deleteStudent({
              variables: {
                id: st.id,
              },
            })
          );
        } else {
          tmp.disabled = true;
          actionPromises.push(
            updateStudent({
              variables: {
                id: st.id,
                data: tmp,
              },
            })
          );
        }
      }
    }
    try {
      try {
        await Promise.all(actionPromises);
        await getStudentsData.refetch();
      } catch (e) {
        console.log(e);
      }

      setLoading(false);
      enqueueSnackbar(`Students Successfully Edited`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    } catch (e) {
      enqueueSnackbar(`Error in Apollo Action. ${e.message}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
  };
  const handleForce = da => {
    const studentsArray = [];
    const fees = {};
    feesStructureData.data.fees_structure.map(f => (fees[f.name] = f.id));
    let v = true;
    let msg;
    for (const student of da) {
      const cl = student.class
        .toLowerCase()
        .trim()
        .replace(' ', '_');
      const sc = student.section
        .toLowerCase()
        .trim()
        .replace(' ', '_');
      if (!v) continue;

      if (!student.roll) {
        msg = `${student.name} ${student.id} does not have a roll number`;
        v = false;
      } else if (!student.term) {
        msg = `${student.name} ${student.id} does not have a term`;
        v = false;
      } else if (!cl || !sc) {
        msg = `${student.name} ${student.id} does not have a valid class section`;
        v = false;
      } else if (
        !cls[cl] ||
        !cls[cl].id ||
        !cls[cl].sections[sc] ||
        !cls[cl].sections[sc].id
      ) {
        msg = `${student.name} ${student.id} does not have a valid class section`;
        v = false;
      } else if (student.action === 'U' && !student.id) {
        msg = `${student.name} ${student.id} does not have a id`;
        v = false;
      }
      if (!v) continue;

      const s = {
        roll: parseInt(student.roll),
        registerNo: student.registerno || '',
        name: student.name,
        phone:
          student.phone === '123' || !student.phone || student.phone.length < 10
            ? '1234567890'
            : student.phone,
        gender: student.gender.toUpperCase() || 'BLANK',
        religion: student.religion || '',
        term: school.terms.filter(t => t.name === student.term)[0].id || null,
        action: student.action || 'N',
        id: student.id,
        disabled: student.disabled.toLowerCase() === 'true',
      };
      if (student.birthdate) {
        s.birthdate = moment(student.birthdate, 'DD-MM-YYYY');
      }
      if (fees[student.feescat]) {
        s.feesStructureID = parseInt(fees[student.feescat]);
      }

      s.class = cls[cl].id;
      s.section = cls[cl].sections[sc].id;
      studentsArray.push(s);
    }
    if (!v) {
      enqueueSnackbar(`${msg}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
      return false;
    }
    schema
      .validate(studentsArray)
      .then(async function (valid) {
        if (valid) {
          setStudents(studentsArray);
          setsubmitEnabled(true);
          enqueueSnackbar('File looks good. Please click submit to import.', {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          });
        }
      })
      .catch(e => {
        enqueueSnackbar(`${e.message}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
      });
  };
  if (
    sectionsRes.loading ||
    getStudentsData.loading ||
    feesStructureData.loading ||
    loading
  )
    return <Loader />;

  if (sectionsRes.error) {
    enqueueSnackbar(
      `Error in Apollo Action. Here's the error - ${sectionsRes.error.message}`,
      {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }
    );
  }

  return (
    <Authorize pme={permissions}>
      <Container className={classes.main} maxWidth="xl" disableGutters>
        <Paper className={classes.wrapper}>
          <div className={classes.content}>
            <UploadData
              template={students}
              filename={`students-${school.name}.csv`}
              checkData={handleForce}
              title="Upload CSV file for Students Edit"
              buttonName="Export Students"
            />
            <Divider />
            <Divider className={classes.divider} />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!submitEnabled}
            >
              Upload Edit Students Sheet
            </Button>
            <Divider className={classes.divider} />
            {school.qrcode && !loading && user.permissions === 'SUPERADMIN' && (
              <>
                <div>
                  {/* <PDFViewer>
                    <QRCodePrintStudents students={qrStudents} />
                  </PDFViewer> */}
                  <PDFDownloadLink
                    document={
                      <QRCodePrintStudents
                        students={qrStudents
                          .filter(s => s.id)
                          .sort((a, b) => parseInt(a.id) - parseInt(b.id))}
                      />
                    }
                    style={{ textDecoration: 'none' }}
                  >
                    {({ blob, url, loading: pdfloading, error }) => (
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        className={classes.button2}
                        startIcon={<CloudDownloadIcon />}
                        disabled={pdfloading}
                      >
                        {pdfloading ? 'Loading QR Codes...' : 'Download now!'}
                      </Button>
                    )}
                  </PDFDownloadLink>
                </div>
              </>
            )}
          </div>
        </Paper>
        <Paper className={classes.wrapper}>
          <Typography className={classes.content} component="h1" variant="h5">
            Usage Guide
          </Typography>
          <Divider />
          <Typography className={classes.content} gutterBottom variant="body1">
            1 - Please use the EXPORT STUDENTS button to export all current
            students.
          </Typography>
          <Typography className={classes.content} gutterBottom variant="body1">
            2 - Please dont change any header. This will cause errors in import.
          </Typography>
          <Typography className={classes.content} gutterBottom variant="body1">
            3 - Only rows with 'U' or 'A' or 'D' in the actions column will be
            processed. To update student mark as 'U' and to add student mark as
            "A" in actions columns. To disable student mark as 'D'.
          </Typography>
          <Typography className={classes.content} gutterBottom variant="body1">
            4 - Birthdate, if entered, need to be in this format: DD/MM/YYYY.
            For example 24/01/1985. Other formats will cuase the import to fail.
          </Typography>
          <Typography className={classes.content} gutterBottom variant="body1">
            5 - Please add ONLY ONE valid mobile phone number per student.
          </Typography>
          <Typography className={classes.content} gutterBottom variant="body1">
            6 - Gender should be one of the following: MALE, FEMALE, OTHER
          </Typography>
        </Paper>
      </Container>
    </Authorize>
  );
}
