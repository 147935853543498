/* eslint-disable no-nested-ternary */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Container, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Redirect, useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import Copyright from '../components/Copyright';
import Loader from '../components/Loader';
import { GET_ATT_LIVE_STUDENT_QUERY } from '../resolvers/Queries';
import {
  MARK_ACTIVE_MUTATION,
  MARK_ATT_MUTATION,
} from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  cont: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 500,
  },
  fixedHeight: {
    height: 240,
  },
  title: {
    backgroundColor: '#0EABBC',
    fontFamily: 'Montserrat',
    color: '#FFF',
    textAlign: 'center',
    height: 70,
  },
}));
export default function StudentLive(props) {
  const history = useHistory();
  if (typeof history.location.state === 'undefined')
    return <Redirect to="/studentLectures" />;
  return <StudentLiveScreen props={props} />;
}
function StudentLiveScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { cls, subject, attID, timestamp } = history.location.state;
  const start = moment(timestamp);
  const [timer, setTimer] = React.useState(moment().diff(start, 'seconds'));
  const [students, setStudents] = React.useState([]);
  const client = useApolloClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [users, setUsers] = React.useState(null);
  const [markAttendanceMutation, markAttendanceData] = useMutation(
    MARK_ATT_MUTATION
  );
  const stopInterval = setInterval(() => {
    setTimer(moment().diff(start, 'seconds'));
  }, 1000);
  const getUsers = async () => {
    const u = JSON.parse(localStorage.getItem('students'));
    setUsers(u);
  };
  React.useEffect(() => {
    getUsers();
  }, [history]);
  const liveAttData = useQuery(
    GET_ATT_LIVE_STUDENT_QUERY,
    users
      ? {
          variables: {
            attID,
            studentIDs: users?.map(u => u.id),
          },
          pollInterval: 3000,
          fetchPolicy: 'network-only',
          onCompleted: async data => {
            if (data.attendances_by_pk.live) {
              const markAttPromises = [];
              const st = [];
              data.attendances_by_pk.studentAttendances.map(sAtt => {
                try {
                  const value =
                    sAtt.value === 'A'
                      ? moment().diff(start, 'minutes') > 5
                        ? 'L'
                        : 'P'
                      : sAtt.value;
                  const d = {
                    sAttID: sAtt.id,
                    notification: {
                      [moment().unix()]: true,
                    },
                    active: true,
                    value,
                  };
                  st.push(sAtt.student.name);
                  markAttPromises.push(
                    markAttendanceMutation({
                      variables: d,
                    })
                  );
                } catch (e) {
                  console.log(e);
                }
              });
              try {
                await Promise.all(markAttPromises);
                if (data.attendances_by_pk.teacherAttendance.teacher.zoomLink) {
                  window.open(
                    data.attendances_by_pk.teacherAttendance.teacher.zoomLink,
                    '_blank'
                  );
                }
              } catch (e) {
                console.log(e);
              }

              setStudents(st);
            }
          },
        }
      : { skip: true }
  );

  firebase.messaging().onMessage(payload => {
    if (payload.data.type === 'cancel') return;
    if (payload.data.type === 'presence') {
      enqueueSnackbar(`${payload.data.title}`, {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      });
    }
    if (payload.data.type === 'active') {
      const activeAction = key => (
        <>
          <Button
            style={{ color: '#FFF' }}
            onClick={async () => {
              try {
                await client.mutate({
                  mutation: MARK_ACTIVE_MUTATION,
                  variables: {
                    sAttID: payload.data.sAttID,
                    notification: {
                      [payload.data.timestamp]: true,
                    },
                    active: true,
                  },
                });
                closeSnackbar(key);
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Mark Active
          </Button>
        </>
      );
      enqueueSnackbar(`${payload.data.title}`, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 15000,
        action: activeAction,
      });
    }

    console.log('Message received. ', payload);
    // ...
  });
  const secondsToHms = d => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? (h < 10 ? `0${h}` : h) : '00';
    const mDisplay = m > 0 ? (m < 10 ? `0${m}` : m) : '00';
    const sDisplay = s > 0 ? (s < 10 ? `0${s}` : s) : '00';
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  };
  if (liveAttData.loading || markAttendanceData.loading) return <Loader />;
  return (
    <Container maxWidth="sm" className={classes.cont}>
      <div className={classes.title}>
        <Typography variant="h3" style={{ paddingTop: 10 }}>
          Live Lectures
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <div style={{ minHeight: 500 }}>
          <Typography
            variant="h4"
            style={{
              color: '#0EABBC',
              fontSize: 24,
              fontWeight: 'bold',
              fontFamily: 'Arial',
            }}
          >
            {moment().format('DD MMM YYYY')}
          </Typography>
          <div
            style={{
              width: '100%',
              marginTop: 10,
              padding: 10,
              border: '1px solid #ECFCFD',
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontSize: 18,
                fontFamily: 'Arial',
                verticalAlign: 'middle',
                color: '#026776',
              }}
            >
              {cls}
            </Typography>
            <Typography
              variant="h4"
              style={{
                fontSize: 16,
                fontFamily: 'Arial',
                verticalAlign: 'middle',
                color: '#0EABBC',
              }}
            >
              {subject}
            </Typography>
          </div>
          <div style={{ margin: 10 }}>
            <Typography
              variant="h2"
              style={{
                color: '#0EABBC',
                fontWeight: 'bold',
                fontFamily: 'Arial',
                textAlign: 'center',
              }}
            >
              {secondsToHms(timer)}
            </Typography>
          </div>
          {students && students.length > 0 ? (
            <div>
              <Typography
                variant="h4"
                style={{
                  fontSize: 18,
                  fontFamily: 'Arial',
                  verticalAlign: 'middle',
                  color: '#026776',
                  backgroundColor: '#ECFCFD',
                  padding: 10,
                }}
              >
                Students Names
              </Typography>
              <List
                dense
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                {students.map((s, i) => (
                  <div key={i}>
                    <ListItem style={{ padding: 0, cursor: 'pointer' }}>
                      <ListItemText
                        style={{
                          color: '#0EABBC',
                          padding: 10,
                          // height: 50,
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{
                            fontSize: 18,
                            fontFamily: 'Arial',
                            verticalAlign: 'middle',
                            color: '#026776',
                          }}
                        >
                          {s}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </div>
                ))}
              </List>
            </div>
          ) : (
            <div>
              <Typography
                variant="h4"
                style={{
                  marginTop: 20,
                  color: '#026776',
                  fontSize: 20,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                }}
              >
                No Lectures Are Live
              </Typography>
            </div>
          )}
        </div>
        {liveAttData.data && (
          <Dialog
            fullWidth
            maxWidth="sm"
            open={!liveAttData.data.attendances_by_pk.live}
            onClose={() => {}}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              Lecture No Longer Live
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This lecture has ended and you can no longer continue it. Press
                ok to go back.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => history.replace('/studentLectures')}
                variant="outlined"
                color="primary"
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Button
          color="secondary"
          onClick={() => {
            firebase.auth().signOut();
            history.push('/');
          }}
          variant="outlined"
          style={{ margin: 20 }}
        >
          Logout
        </Button>
        <Copyright />
      </Paper>
    </Container>
  );
}
