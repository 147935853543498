import React from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from '@apollo/client';
import SchoolContext from '../SchoolContext';
import { GET_TERM_FEES_SUB } from '../../resolvers/Queries';
import Loader from '../Loader';

const months = [
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
  'JAN',
  'FEB',
  'MAR',
];
export default function FeesMonthly() {
  const { school } = React.useContext(SchoolContext);
  const { data, loading } = useQuery(GET_TERM_FEES_SUB, {
    variables: {
      termID: school.termID,
    },
    pollInterval: 10000,
  });

  if (loading) return <Loader />;
  const paidData = [];
  const pendingData = [];
  const totalData = [];
  months.map((m, index) => {
    // let matches;
    // if (m === 'OneTime') {
    //   matches = fees.filter(f => !f.month);
    // } else {
    const matches = data.term_fees.filter(f => f.month === m);
    // }
    paidData[index] = 0;
    pendingData[index] = 0;
    totalData[index] = 0;
    matches.map(mt => {
      if (mt.feesStatus === 'PAID') {
        paidData[index] = mt.sum;
      } else {
        pendingData[index] = mt.sum;
      }
      const total = totalData[index] + mt.sum;
      totalData[index] = total;
      return mt;
    });
    return m;
  });
  return (
    <div>
      <h4>Monthly Fees Collection Status</h4>
      <div>
        <Line
          data={{
            labels: months,
            options: {
              responsive: true,
              maintainAspectRatio: false,
            },
            datasets: [
              {
                label: 'Fees Paid',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: paidData,
              },
              {
                label: 'Fees Pending',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(218, 65, 103,0.4)',
                borderColor: 'rgba(218, 65, 103,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(218, 65, 103,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(218, 65, 103,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: pendingData,
              },
              {
                label: 'Fees Expected',
                type: 'bar',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(150, 150, 115,0.4)',
                borderColor: 'rgba(150, 150, 115,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(150, 150, 115,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(150, 150, 115,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: totalData,
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
