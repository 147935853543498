import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { GET_USERS_QUERY } from '../resolvers/Queries';

export default function ConfirmDeleteDialog(props) {
  const { open, handleConfirmClose, data, deleteUser } = props;
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = async () => {
    await deleteUser({
      variables: {
        where: { id: { _eq: data.id } },
      },
      refetchQueries: [
        {
          query: GET_USERS_QUERY,
          variables: { id: data.schoolID },
        },
      ],
    });
    enqueueSnackbar('User Successfully Deleted!', {
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    });
    handleConfirmClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are You Sure You Want To Delete {data.name}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you delete this user, you will lose ALL RECORDS present and past.
            This action is not reversable. It would be safer to disable this
            user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Go Back
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
