import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AddSchoolForm from './AddSchoolForm';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  save: {
    marginTop: 10,
    marginBottom: 30,
  },
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
    minHeight: 18,
  },
}));
export default function SchoolSettings(props) {
  const { school } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography component="h1" variant="h5">
        School Settings
      </Typography>
      <AddSchoolForm school={school} update />
    </Box>
  );
}
