import { useMutation, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import * as Yup from 'yup';
import {
  ADD_STUDENTS_FEES_STRUCTURE,
  CREATE_STUDENT_MUTATION,
  DEL_STUDENTS_FEES_STRUCTURE,
  UPDATE_STUDENT_MUTATION,
} from '../resolvers/Mutations';
import {
  GET_FEES_STRUCTURE_QUERY,
  GET_SECTIONS_QUERY,
  GET_STUDENTS_QUERY,
} from '../resolvers/Queries';
import CheckboxInputs from './CheckboxInput';
import DatePicker from './DatePicker';
import Loader from './Loader';
import QRCodePrint from './QRCodePrint';
import SelectField from './SelectField';
import UserContext from './UserContext';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    padding: '5px 5px',
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },

  button: {
    marginTop: 30,
  },
  button2: {
    marginTop: 30,
    color: 'white',
  },
  buttonDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
const gender = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
  { value: 'OTHER', label: 'Other' },
  { value: 'Select Gender...', label: 'Blank' },
];
export default function StudentForm(props) {
  const { update, school, student, closeMain = {} } = props;
  const { user } = React.useContext(UserContext);
  const classes = useStyles();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { enqueueSnackbar } = useSnackbar();
  // const isSubjectwise = school.attType === 'SUBJECTWISE';
  const [createStudent, createStudentRes] = useMutation(
    CREATE_STUDENT_MUTATION
  );
  const [addStFeeStruct] = useMutation(ADD_STUDENTS_FEES_STRUCTURE);
  const [delStFeeStruct] = useMutation(DEL_STUDENTS_FEES_STRUCTURE);

  const [updateStudent, updateStudentRes] = useMutation(
    UPDATE_STUDENT_MUTATION
  );
  const feesStructureData = useQuery(GET_FEES_STRUCTURE_QUERY, {
    variables: { termID: school.termID },
    fetchPolicy: 'network-only',
  });
  const vars = {
    id: school.id,
    currentTerm: school.termID,
  };
  const sectionRes = useQuery(GET_SECTIONS_QUERY, { variables: vars });
  if (
    sectionRes.loading ||
    createStudentRes.loading ||
    updateStudentRes.loading ||
    feesStructureData.loading
  )
    return <Loader />;
  if (sectionRes.error || createStudentRes.error || updateStudentRes.error) {
    const tmp = {
      ...sectionRes.error,
      ...sectionRes.error,
      ...updateStudentRes.error,
    };
    enqueueSnackbar(`Something Went Wrong. Heres the error ${tmp.message}`, {
      variant: 'error',
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    });
  }
  const formatName = name =>
    name
      .replace(/\s\s+/g, ' ')
      .replace(/\.(?=[^\s])/g, '. ')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  const sectionsList = [];

  if (sectionRes.data) {
    const { sections } = sectionRes.data;
    sections.map(s => {
      const tmp = {
        value: `${s.class.id}:${s.id}`,
        label: `${s.class.class} ${s.section}`,
      };
      sectionsList.push(tmp);
      return s;
    });
  }
  return (
    <Formik
      initialValues={{
        name: update ? student.name : '',
        phone: update ? student.phone : '1234567890',
        roll: update ? student.roll : 0,
        registerNo: update ? student.registerNo : '',
        sections: update ? student.sections : '',
        gender: update && student.gender !== '' ? student.gender : 'BLANK',
        birthdate:
          update && student.birthdate
            ? moment(student.birthdate, 'DD/MM/YYYY')
            : moment(),
        religion: update ? student.religion : '',
        addBirthdate: update ? student.addBirthdate : false,
        feesStructure: update ? student.feeCategoryID : '',
        // eslint-disable-next-line no-nested-ternary
        loginEmail: update ? (student.email ? student.email : '') : '',
      }}
      onSubmit={async values => {
        const st = {
          name: formatName(values.name),
          phone: values.phone,
          roll: parseInt(values.roll),
          registerNo: values.registerNo,
          createTermID: vars.currentTerm,
          termID: vars.currentTerm,
          disabled: false,
          gender: values.gender,
          loginEmail: values.loginEmail,
          religion: values.religion,
          schoolID: school.id,
          classID: parseInt(values.sections.split(':')[0]),
          sectionID: parseInt(values.sections.split(':')[1]),
        };
        if (values.addBirthdate) st.birthdate = values.birthdate;
        if (update) {
          try {
            if (values.feesStructure) {
              await delStFeeStruct({
                variables: {
                  studentID: student.id,
                  termID: school.termID,
                },
              });
              await addStFeeStruct({
                variables: {
                  students_fees_structures: {
                    studentID: student.id,
                    feesStructureID: values.feesStructure,
                  },
                },
              });
            }
            await updateStudent({
              variables: {
                id: student.id,
                data: st,
              },
              refetchQueries: [
                {
                  query: GET_STUDENTS_QUERY,
                  variables: {
                    id: school.id,
                    termID: school.termID,
                  },
                },
              ],
            });
            enqueueSnackbar(`Student Updated Successfully!`, {
              variant: 'success',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            });
            closeMain();
            return;
          } catch (e) {
            console.log(e, st);
            enqueueSnackbar(
              `Something Went Wrong. Heres the error ${e.message}`,
              {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }
            );
          }
        }
        if (values.feesStructure) {
          st.students_fees_structures = {
            data: [
              {
                feesStructureID: parseInt(values.feesStructure),
              },
            ],
          };
        }

        try {
          console.log(st);
          await createStudent({
            variables: {
              data: st,
            },
            refetchQueries: [
              {
                query: GET_STUDENTS_QUERY,
                variables: {
                  id: school.id,
                  termID: school.termID,
                },
              },
            ],
          });
          enqueueSnackbar(`Student Added Successfully!`, {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          });
        } catch (e) {
          console.log(e);
          enqueueSnackbar(
            `Something Went Wrong. Heres the error ${e.message}`,
            {
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            }
          );
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        phone: Yup.string().length(10),
        roll: Yup.number().required(),
        registerNo: Yup.mixed(),
        sections: Yup.string().required(),
        gender: Yup.string(),
        religion: Yup.string(),
        birthdate: Yup.date(),
        addBirthdate: Yup.boolean(),
        feesStructure: Yup.number(),
        loginEmail: Yup.string().email(),
      })}
    >
      {p => {
        const { values, touched, errors, handleChange, handleBlur } = p;
        return (
          <>
            <Form className={classes.main}>
              <TextField
                label="Student Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.name && touched.name && errors.name}
                margin="normal"
                fullWidth
                required
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
              <div className={classes.break} />
              <TextField
                label="Phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.phone && touched.phone && errors.phone}
                margin="normal"
                fullWidth
                required
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
              <div className={classes.break} />
              <TextField
                label="Roll Number"
                name="roll"
                value={values.roll}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.roll && touched.roll && errors.roll}
                margin="normal"
                fullWidth
                required
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
              <div className={classes.break} />
              <TextField
                label="Register Number"
                name="registerNo"
                value={values.registerNo}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.registerNo && touched.registerNo && errors.registerNo
                }
                margin="normal"
                fullWidth
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
              <div className={classes.break} />
              <TextField
                label="Login Email"
                name="loginEmail"
                value={values.loginEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.loginEmail && touched.loginEmail && errors.loginEmail
                }
                margin="normal"
                fullWidth
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
                disabled={!user.permissions === 'SUPERADMIN'}
              />
              <div className={classes.break} />
              <Field
                name="sections"
                component={SelectField}
                options={sectionsList}
                required
                zIndex={10}
                label="Select Section"
              />
              <div className={classes.break} />

              <Field
                name="gender"
                component={SelectField}
                options={gender}
                label="Select Gender"
              />
              <div className={classes.break} />

              <TextField
                label="Religion"
                name="religion"
                value={values.religion}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.religion && touched.religion && errors.religion
                }
                margin="normal"
                fullWidth
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
              <div className={classes.break} />

              <Field
                name="addBirthdate"
                label="Add Birthdate"
                component={CheckboxInputs}
                checked={values.addBirthdate}
              />
              <div className={classes.break} />
              {values.addBirthdate && (
                <>
                  <Field
                    name="birthdate"
                    label="Birth Date"
                    component={DatePicker}
                  />
                  <div className={classes.break} />
                </>
              )}
              <Field
                name="feesStructure"
                component={SelectField}
                options={feesStructureData.data.fees_structure.map(f => ({
                  value: f.id,
                  label: f.name,
                }))}
                label="Select Fee Category"
              />
              <div className={classes.break} />

              <div className={classes.buttonDiv}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                >
                  {update ? 'Update Student' : 'Add Student'}
                </Button>
                {update && school.qrcode && (
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    className={classes.button2}
                    onClick={handlePrint}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download QRcode
                  </Button>
                )}
              </div>
            </Form>
            {school.qrcode && student.qrcode && (
              <QRCodePrint student={student} ref={componentRef} />
            )}
          </>
        );
      }}
    </Formik>
  );
}
