import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { ADD_EXPENSE_MUTATION } from '../resolvers/Mutations';
import Loader from './Loader';
import UserContext from './UserContext';
import SelectField from './SelectField';
import DatePicker from './DatePicker';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
}));
export default function AddExpenseForm(props) {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { school, expCats, expensesData } = props;
  const [addExpense, addExpenseData] = useMutation(ADD_EXPENSE_MUTATION);
  if (addExpenseData.loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        amount: 0,
        comment: '',
        categoryID: 0,
        paidOn: moment().toDate(),
        schoolID: school.id,
        termID: school.termID,
        userID: user.id,
        enteredOn: moment(),
      }}
      enableReinitialize
      onSubmit={async values => {
        try {
          await addExpense({
            variables: {
              data: { ...values },
            },
          });
          await expensesData.refetch();
        } catch (e) {
          console.log(e);
        }
      }}
      validationSchema={Yup.object().shape({
        amount: Yup.number().required(),
        comment: Yup.string().nullable(),
        categoryID: Yup.number().required(),
        paidOn: Yup.date().required(),
        schoolID: Yup.string().required(),
        termID: Yup.number().required(),
        userID: Yup.string().required(),
        enteredOn: Yup.date().required('Required'),
      })}
    >
      {p => {
        const { values, touched, errors, handleChange, handleBlur } = p;
        return (
          <>
            <Form className={classes.container}>
              <TextField
                label="Amount"
                name="amount"
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.amount && touched.amount && errors.amount}
                margin="normal"
                fullWidth
                required
                variant="outlined"
              />
              <Field
                name="categoryID"
                component={SelectField}
                options={expCats.map(e => ({ value: e.id, label: e.name }))}
                label="Exp Category"
              />
              <Field
                name="paidOn"
                label="Paid On Date"
                component={DatePicker}
              />
              <TextField
                label="Comment"
                name="comment"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.comment && touched.comment && errors.comment}
                margin="normal"
                fullWidth
                variant="outlined"
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                Add Expense Entry
              </Button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
