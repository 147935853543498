import React from 'react';

const styles = {
  main: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 99999,
    background: '#333547',
  },
};
const Loader = () => (
  <div style={styles.main}>
    <div className="loader loader-2">
      <i />
      <i />
      <i />
      <i />
    </div>
    <style>{`
      .loader {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -16px;
      }
      @-webkit-keyframes loader21 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(-10px, -10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @-webkit-keyframes loader22 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(10px, -10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @-webkit-keyframes loader23 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(10px, 10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @-webkit-keyframes loader24 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(-10px, 10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @keyframes loader21 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(-10px, -10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @keyframes loader22 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(10px, -10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @keyframes loader23 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(10px, 10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      @keyframes loader24 {
        0% {
          transform: translate(0px, 0px) rotateZ(0deg);
        }
        50% {
          transform: translate(-10px, 10px) rotateZ(90deg);
        }
        100% {
          transform: translate(0px, 0px) rotateZ(180deg);
        }
      }
      .loader-2 {
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
      }
      .loader-2 i {
        position: absolute;
        width: 15px;
        height: 15px;
        background: #ff4081;
        -webkit-animation: loader21 1000ms infinite ease-in-out;
        animation: loader21 1000ms infinite ease-in-out;
      }
      .loader-2 i + i {
        left: 15px;
        -webkit-animation-name: loader22;
        animation-name: loader22;
      }
      .loader-2 i + i + i {
        top: 15px;
        -webkit-animation-name: loader23;
        animation-name: loader23;
      }
      .loader-2 i + i + i + i {
        top: 15px;
        left: 0;
        -webkit-animation-name: loader24;
        animation-name: loader24;
      }
    `}</style>
  </div>
);
export default Loader;
