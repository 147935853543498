import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxInputs = ({ field, form: { errors }, label, ...props }) => (
  <>
    <FormControlLabel
      control={<Checkbox color="secondary" {...props} {...field} />}
      label={label}
    />
  </>
);

export default CheckboxInputs;
