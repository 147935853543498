import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CardMedia } from '@material-ui/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    height: '100px',
    width: '100px',
    margin: '20px auto',
  },
}));
const firebaseConfig = {
  apiKey: 'AIzaSyDs_k93IIcFaoz--PwzdGjA0Iki1oMEWVM',
  authDomain: 'sama-11841.firebaseapp.com',
  databaseURL: 'https://sama-11841.firebaseio.com',
  projectId: 'sama-11841',
  storageBucket: 'sama-11841.appspot.com',
  messagingSenderId: '320459962778',
  appId: '1:320459962778:web:df5c62a8841619d2ab2223',
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
function SignIn() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const validateEmail = e => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const validEmail = validateEmail(email);
    if (!validEmail) {
      setEmailError(!validEmail);
    } else {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() =>
          firebase
            .auth()
            .signInWithEmailAndPassword(email, pass)
            .then(res => {
              console.log('Logged In');
            })
            .catch(err => {
              if (
                err.code === 'auth/user-not-found' ||
                err.code === 'auth/invalid-email'
              ) {
                setEmailError(true);
                enqueueSnackbar('Please Check Your Email', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                });
              }
              if (err.code === 'auth/wrong-password') {
                setPassError(true);
                enqueueSnackbar('Please Check Your Password', {
                  variant: 'error',
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                });
              }
            })
        );
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Container component="div" maxwidth="xs">
          <CardMedia
            className={classes.logo}
            component="div"
            image="/logo.png"
          />
        </Container>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          method="POST"
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            error={emailError}
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={pass}
            onChange={e => setPass(e.target.value)}
            required
            fullWidth
            error={passError}
            name="password"
            label="Password"
            autoComplete="off"
            type="password"
            id="password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://sstracker.in" variant="body2">
                Not A Customer? Contact Us Here.
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8} />
    </Container>
  );
}
export default SignIn;
