import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import Layout from '../components/Layout';
import Authorize from './Authorize';
import { GET_FEES_STRUCTURE_QUERY } from '../resolvers/Queries';
import UserContext from '../components/UserContext';
import Loader from '../components/Loader';
import AddFees from '../components/AddFees';
import ViewFees from '../components/ViewFees';
import CollectFees from '../components/CollectFees';
import SchoolContext from '../components/SchoolContext';
import AddFeesStructure from '../components/AddFeesStructure';
import ViewFeesEntries from '../components/ViewFeesEntries';
import ViewDeletedFeesEntries from '../components/ViewDeletedFeesEntries';
import ViewPendingFeesEntries from '../components/ViewPendingFeesEntries';
import TermsContext from '../components/TermsContext';

const useStyles = makeStyles(theme => ({
  paper: {},
  flexDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  },
  button: {
    height: 50,
    width: '100%',
  },
  box: {
    marginTop: 10,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Fees() {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { user } = React.useContext(UserContext);
  const { school } = React.useContext(SchoolContext);
  const [feesCats, setFeesCats] = React.useState([]);
  const { currentTerm } = React.useContext(TermsContext);
  const feesCatsData = useQuery(GET_FEES_STRUCTURE_QUERY, {
    variables: {
      termID: currentTerm,
    },
    // fetchPolicy: 'network-only',
    onCompleted: async data => {
      setFeesCats(data.fees_structure);
    },
  });
  const permissions = ['SUPERADMIN', 'ADMIN', 'FEES'];
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };
  if (feesCatsData.loading) return <Loader />;
  return (
    <Layout>
      <Authorize pms={permissions}>
        <AppBar position="static" color="default" className={classes.box}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            centered
            aria-label="full width tabs example"
          >
            <Tab label="Collect Fees" {...a11yProps(0)} />
            <Tab label="Fees Entries" {...a11yProps(1)} />
            <Tab label="Pending Fees" {...a11yProps(2)} />
            <Tab label="View Fees" {...a11yProps(3)} />
            <Tab label="Add Fees" {...a11yProps(4)} />
            <Tab label="Fees Categories" {...a11yProps(5)} />
            <Tab label="Deleted Payments" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CollectFees school={school} user={user} feesCats={feesCats} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ViewFeesEntries school={school} user={user} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <ViewPendingFeesEntries school={school} user={user} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ViewFees school={school} user={user} feesCats={feesCats} />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <AddFees school={school} user={user} feesCats={feesCats} />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <AddFeesStructure school={school} user={user} feesCats={feesCats} />
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <ViewDeletedFeesEntries
            school={school}
            user={user}
            feesCats={feesCats}
          />
        </TabPanel>
      </Authorize>
    </Layout>
  );
}
