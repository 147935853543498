import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import CryptoJS from 'crypto-js';
import { ACCOUNTS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import { ADD_ACCOUNT_MUTATION } from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function AddAccountForm(pr) {
  const ENCRYPTION_KEY = 'FoCKvdqwepoi4y3EZlSage7XGottHw123LmyqJHvUhs=';
  const [addAccount, addAccountData] = useMutation(ADD_ACCOUNT_MUTATION);
  const { school } = pr;
  const classes = useStyles();
  if (addAccountData.loading) return <Loader />;
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <h3>Add Bank Details</h3>
      <Formik
        initialValues={{
          name: '',
          accNumber: '',
          ifsc: '',
          salt: '',
          key: '',
          misc: '',
        }}
        onSubmit={async values => {
          const data = {
            name: values.name,
            accNumber: values.accNumber,
            ifsc: values.ifsc,
            salt: values.salt,
            key: values.key,
            misc: values.misc,
            schoolID: school.id,
          };
          const t = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            ENCRYPTION_KEY
          ).toString();
          try {
            await addAccount({
              variables: {
                t,
              },
              update: (cache, { data: d }) => {
                const cacheData = cache.readQuery({
                  query: ACCOUNTS_QUERY,
                  variables: { schoolID: school.id },
                });
                cache.writeQuery({
                  query: ACCOUNTS_QUERY,
                  variables: { schoolID: school.id },
                  data: {
                    ...cacheData,
                    accounts: [
                      ...cacheData.accounts,
                      {
                        id: d.addAccount.id,
                        name: values.name,
                        accNumber: values.accNumber,
                        ifsc: values.ifsc,
                        misc: values.misc,
                        __typename: 'accounts',
                      },
                    ],
                  },
                });
              },
            });
          } catch (e) {
            console.log(e);
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          accNumber: Yup.string().required('Required'),
          ifsc: Yup.string().required('Required'),
          salt: Yup.string().required('Required'),
          key: Yup.string().required('Required'),
          misc: Yup.string().required('Required'),
        })}
      >
        {props => {
          const { values, touched, errors, handleChange, handleBlur } = props;
          return (
            <>
              <Form className={classes.container}>
                <TextField
                  label="Account Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <TextField
                  label="Account Number"
                  name="accNumber"
                  value={values.accNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.accNumber && touched.accNumber
                      ? errors.accNumber
                      : ''
                  }
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <TextField
                  label="IFSC Code"
                  name="ifsc"
                  value={values.ifsc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.ifsc && touched.ifsc ? errors.ifsc : ''}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <TextField
                  label="Gateway Key"
                  name="key"
                  value={values.key}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.key && touched.key ? errors.key : ''}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <TextField
                  label="Gateway Salt"
                  name="salt"
                  value={values.salt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.salt && touched.salt ? errors.salt : ''}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <TextField
                  label="Any Miscellaneous Required Data"
                  name="misc"
                  value={values.misc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.misc && touched.misc ? errors.misc : ''}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                >
                  Add Account
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
