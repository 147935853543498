import React from 'react';
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'inline-block',
    border: '1px solid #999',
    borderRadius: 3,
    padding: '10px',
    outline: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    textShadow: '1px 1px #fff',
    fontWeight: 700,
    fontSize: 20,
  },
  container: {
    marginTop: 20,
  },
  main: {
    marginTop: 20,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  hiddenButton: {
    display: 'none',
  },
}));
export default function UploadData(props) {
  const classes = useStyles();
  const papaparseOptions = {
    header: true,
    skipEmptyLines: 'greedy',
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
  };
  const { title, template, filename, checkData, buttonName } = props;
  return (
    <div className={classes.main}>
      <h4>{title}</h4>
      <CSVLink
        data={template}
        className="MuiButtonBase-root MuiButton-root MuiButton-contained"
        filename={filename}
      >
        {buttonName || 'Download Template'}
      </CSVLink>
      <Divider className={classes.divider} />
      <Button className={classes.hiddenButton}>Test</Button>
      <CSVReader
        cssClass={classes.container}
        cssInputClass={classes.input}
        onFileLoaded={checkData}
        parserOptions={papaparseOptions}
      />
    </div>
  );
}
