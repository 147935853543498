import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select, { components } from 'react-select';

const useStyles = makeStyles(theme => ({
  select: props => ({
    width: '100%',
    minHeight: 18,
    marginBottom: 10,
    marginTop: 10,
    zIndex: props.zIndex,
  }),
}));
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child =>
      child && child.type !== Placeholder ? child : null
    )}
  </ValueContainer>
);

export default function SelectField({
  field,
  form,
  options,
  label,
  isMulti = false,
  zIndex = 9,
}) {
  const classes = useStyles({ zIndex });
  const onChange = option => {
    form.setFieldValue(
      field.name,
      // eslint-disable-next-line no-nested-ternary
      isMulti
        ? !!option && option.length > 0
          ? option.map(item => item.value)
          : []
        : option.value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    }
    return isMulti ? [] : '';
  };

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      placeholder={`   ${label}   `}
      className={classes.select}
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        container: (provided, state) => ({
          ...provided,
          marginTop: 10,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: 'visible',
          paddingTop: 12,
          paddingBottom: 12,
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue ? -2 : '50%',
          transition: 'top 0.1s, font-size 0.1s',
          backgroundColor:
            state.hasValue || state.selectProps.inputValue ? '#FFF' : 'none',
          zIndex: state.hasValue || state.selectProps.inputValue ? 5 : 0,
          padding: state.hasValue || state.selectProps.inputValue ? '0 5px' : 0,
          fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        }),
      }}
    />
  );
}
