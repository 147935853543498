import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Divider, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import Loader from './Loader';
import { GET_SECTIONS_ONLY_QUERY } from '../resolvers/Queries';
import ConfirmDialog from './ConfirmDialog';
import { PROMOTE_STUDENTS_MUTATIONS } from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
  select: {
    width: '80%',
    marginBottom: 10,
    marginTop: 10,
  },
}));

export default function PromoteStudents(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { schoolID, prevTerm, currentTerm, reset } = props;
  const [secs, setSecs] = React.useState({});
  const [newSecs, setNewSecs] = React.useState([]);
  const [dialog, setDialog] = React.useState(false);
  const [promoteStudents, promoteStudentsData] = useMutation(
    PROMOTE_STUDENTS_MUTATIONS
  );
  const secsData = useQuery(GET_SECTIONS_ONLY_QUERY, {
    variables: { id: schoolID, currentTerm },
    onCompleted: data => {
      const tmp = {};
      data.sections.map((s, i) => {
        // if (i > 1) return s; // Only Dev
        tmp[i] = {
          name: `${s.class.class} ${s.section}`,
          id: s.id,
          tag: `${s.class.id}|${s.id}`,
          sr: s.sr,
        };
        return s;
      });
      const t = data.sections.map(s => ({
        label: `${s.class.class} ${s.section}`,
        value: `${s.class.id}|${s.id}`,
      }));
      setSecs(tmp);
      setNewSecs(t);
    },
    fetchPolicy: 'network-only',
  });
  const submitPromote = async () => {
    setDialog(false);
    for (const sec of Object.values(secs)) {
      // eslint-disable-next-line no-continue
      if (!sec.tag.value) continue;
      const vars = {
        schoolID,
        prevTerm,
        oldSecID: sec.id,
        classID: parseInt(sec.tag.value.split('|')[0]),
        sectionID: parseInt(sec.tag.value.split('|')[1]),
        termID: currentTerm,
      };
      try {
        await promoteStudents({
          variables: vars,
        });
        enqueueSnackbar(
          `Students from ${sec.name} promoted to ${sec.tag.label} successfully!`,
          {
            variant: 'success',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }
        );
      } catch (e) {
        enqueueSnackbar(`Error in Student Promotion: ${e}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
        console.log(e);
      }
    }
    reset();
  };
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      zIndex: state.selectProps.zIndex,
    }),
  };

  if (secsData.loading || promoteStudentsData.loading) return <Loader />;
  return (
    <Container className={classes.main} maxWidth="xl" disableGutters>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Typography component="h1" variant="h5">
            Promote Students
          </Typography>
          {Object.values(secs).length &&
            Object.values(secs).map((sec, index) => (
              // const p = '';
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                key={sec.id}
              >
                <Typography
                  variant="subtitle2"
                  style={{ width: 20, fontWeight: 'bold' }}
                  key={`sr${sec.id}`}
                >
                  {sec.sr}
                </Typography>
                <div
                  key={`div${sec.id}`}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                />
                <Typography
                  variant="subtitle1"
                  style={{ width: 500 }}
                  key={`name${sec.id}`}
                >{`Promote students from ${sec.name} to:`}</Typography>
                <Select
                  key={`select-${sec.id}`}
                  name={`select-${index}`}
                  value={sec.tag}
                  onChange={val => {
                    const n = { ...secs };
                    n[index].tag = val;
                    setSecs(n);
                  }}
                  options={newSecs}
                  placeholder="Select Section"
                  className={classes.select}
                  zIndex={Object.keys(secs).length - index}
                  styles={customStyles}
                />
              </div>
            ))}
          <Divider style={{ margin: 20 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => setDialog(true)}
            >
              Promote Students To New Sections - THIS CANNOT BE UNDONE
            </Button>
          </div>
          <ConfirmDialog
            open={dialog}
            handleConfirmClose={() => setDialog(false)}
            handleSubmit={submitPromote}
          />
        </div>
      </Paper>
      <Paper className={classes.wrapper}>
        <div className={classes.content}>
          <Typography className={classes.content} component="h1" variant="h5">
            Usage Guide
          </Typography>
          <Divider />
          <Typography className={classes.content} variant="h6" gutterBottom>
            1 - Please make sure that you have promoted the sections first and
            then added any new classes and / or sections for the new term.
            Otherwise students promotion may have errors in it which will be not
            be recoverable.
          </Typography>
          <Typography className={classes.content} variant="h6" gutterBottom>
            2 - If a class is not to be promoted anymore leave their selection
            blank
          </Typography>
        </div>
      </Paper>
    </Container>
  );
}
