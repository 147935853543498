import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Authorize from '../pages/Authorize';
import AddExpenseCatForm from './AddExpenseCatForm';
import ViewExpenseCats from './ViewExpenseCats';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function AddExpenseCategory(props) {
  const classes = useStyles();
  const { school, user, expCats } = props;

  const permissions = ['ADMIN', 'SUPERADMIN', 'FEES'];

  return (
    <Authorize pms={permissions}>
      <div>
        <Paper style={{ padding: 10, marginBottom: 10 }}>
          <h4>
            Please ensure that the data is correct. Expense Categories can not
            be modified or deleted once created. If in any doubt please contact
            support staff.
          </h4>
        </Paper>
        <Container className={classes.main} maxWidth="xl" disableGutters>
          <Paper className={classes.wrapper}>
            <div className={classes.content}>
              <ViewExpenseCats school={school} user={user} expCats={expCats} />
            </div>
          </Paper>
          <Paper className={classes.wrapper}>
            <div className={classes.content}>
              <h4>Example: Teacher Salary</h4>
              <AddExpenseCatForm school={school} user={user} />
            </div>
          </Paper>
        </Container>
      </div>
    </Authorize>
  );
}
