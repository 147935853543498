/* eslint-disable array-callback-return */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Routes from './Routes';
import UserContext from './UserContext';
import SchoolContext from './SchoolContext';

const useStyles = makeStyles(theme => ({
  wrapper: {},
  title: {
    color: '#b4c9de',
    textTransform: 'uppercase',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#424242',
    borderBottom: 'solid',
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
  icon: {
    marginRight: 30,
  },
}));

// TODO SETUP PERMSISSIONS CHECK TO SHOW ONLY RELEVANT LINKS
const Header = props => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { school } = React.useContext(SchoolContext);
  return (
    <div className={classes.wrapper}>
      {Routes.map((r, index) => {
        if (r.permissions.indexOf(user.permissions) > -1) {
          if (r.path === 'fees' && !school.feesModule) {
            return;
          }
          return (
            <Link
              key={`link${index}`}
              style={{ width: '100%', textDecoration: 'none' }}
              to={r.path}
            >
              <div className={classes.item} key={`div${index}`}>
                <div className={classes.icon}>{r.icon}</div>
                <Typography
                  component="h6"
                  // variant="h6"
                  noWrap
                  align="center"
                  className={classes.title}
                  key={`text${index}`}
                >
                  {r.name}
                </Typography>
              </div>
            </Link>
          );
        }
      })}
    </div>
  );
};
export default Header;
