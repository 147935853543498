import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Typography, TextField, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useApolloClient, useMutation } from '@apollo/client';
import RadioInputs from './RadioInputs';
import {
  // UPDATE_SCHOOL_MUTATION,
  CREATE_TERM_MUTATION,
  UPDATE_TERM_MUTATION,
} from '../resolvers/Mutations';
import Loader from './Loader';
import { GET_SCHOOL_QUERY } from '../resolvers/Queries';
import TermsContext from './TermsContext';
import SchoolContext from './SchoolContext';

export default function UpdateTerms(props) {
  const { terms } = props;
  // const [updateSchool, updateSchoolData] = useMutation(UPDATE_SCHOOL_MUTATION);
  const [createTerm, createTermData] = useMutation(CREATE_TERM_MUTATION);
  const [updateTerm, updateTermData] = useMutation(UPDATE_TERM_MUTATION);
  const { setSchool } = React.useContext(SchoolContext);
  const { setTerm, setTerms } = React.useContext(TermsContext);
  const client = useApolloClient();
  if (
    // updateSchoolData.loading ||
    createTermData.loading ||
    updateTermData.loading
  )
    return <Loader />;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        terms: terms.filter(t => t.current)[0].name,
        newTerm: '',
      }}
      onSubmit={async values => {
        if (values.newTerm === '') {
          const vars = terms.filter(t => values.terms === t.name)[0];
          try {
            await updateTerm({
              variables: {
                id: vars.id,
                schoolID: vars.schoolID,
              },
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            await createTerm({
              variables: {
                data: {
                  name: values.newTerm,
                  current: false,
                  schoolID: terms[0].schoolID,
                },
              },
            });
          } catch (e) {
            console.log(e);
          }
        }
        const schoolData = await client.query({
          query: GET_SCHOOL_QUERY,
          variables: {
            id: terms[0].schoolID,
          },
          fetchPolicy: 'network-only',
        });
        setSchool({ ...schoolData.data.schools_by_pk });
        setTerm(schoolData.data.schools_by_pk.termID);
        setTerms(schoolData.data.schools_by_pk.terms);
      }}
      validationSchema={Yup.object().shape({
        terms: Yup.string().required('Required'),
        newTerm: Yup.string(),
      })}
    >
      {p => {
        const { values, touched, errors, handleChange, handleBlur } = p;

        return (
          <Form>
            <Typography component="h1" variant="h6">
              Set Current Term Or Add New
            </Typography>
            <Field
              name="terms"
              options={terms.map(t => t.name)}
              component={RadioInputs}
            />
            <TextField
              label="New Term Name"
              name="newTerm"
              value={values.newTerm}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.newTerm && touched.newTerm && errors.newTerm}
              margin="normal"
              fullWidth
              variant="outlined"
            />

            <div>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Save Changes
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
