import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RadioInputs = ({
  field,
  form: { touched, errors },
  name,
  options,
  disabled,
}) => {
  const fieldName = name || field.name;
  return (
    <>
      <RadioGroup {...field} name={fieldName}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio />}
            label={option}
            disabled={disabled}
          />
        ))}
      </RadioGroup>

      {touched[fieldName] && errors[fieldName] && <>{errors[fieldName]}</>}
    </>
  );
};

export default RadioInputs;
