import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Divider } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import Authorize from '../pages/Authorize';
import AddFeesStructureForm from './AddFeesStructureForm';
import ViewFeesStructures from './ViewFeesStructures';
import UploadData from './UploadData';
import { BULK_ADD_FEE_STRUCTURE_MUTATION } from '../resolvers/Mutations';
import { GET_FEES_STRUCTURE_QUERY, ACCOUNTS_QUERY } from '../resolvers/Queries';
import Loader from './Loader';
import TermsContext from './TermsContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
  wrapper: {
    width: '48%',
  },
  content: {
    padding: 10,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  button: {
    marginTop: 30,
  },
}));

export default function AddFeesStructure(props) {
  const classes = useStyles();
  const { school, user } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [submitEnabled, setsubmitEnabled] = React.useState(false);
  const [structures, setStructures] = React.useState([]);
  const [bulkAdd, bulkAddData] = useMutation(BULK_ADD_FEE_STRUCTURE_MUTATION);
  const FeesStructureTemplate = [['name'], ['accID'], [''], [''], [''], ['']];
  const [accs, setAccs] = React.useState({});
  const { currentTerm } = React.useContext(TermsContext);
  const accsData = useQuery(ACCOUNTS_QUERY, {
    variables: { schoolID: school.id },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const tmp = {};
      data.accounts.map(a => {
        if (!tmp[a.id]) tmp[a.id] = true;
        return a;
      });
      setAccs(tmp);
    },
  });
  const handleForce = da => {
    const tmp = [];
    let valid = true;
    da.map(d => {
      const t = {
        schoolID: school.id,
        termID: currentTerm,
        name: d.name,
      };
      if (school.onlineFees) {
        if (!valid) return d;
        if (!accs[d.accid]) {
          enqueueSnackbar(`Bank account id does not match.`, {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          });
          valid = false;
          return d;
        }
        t.accID = parseInt(d.accid);
      }

      tmp.push(t);
      return d;
    });
    if (valid) {
      setStructures(tmp);
      setsubmitEnabled(true);
      enqueueSnackbar(
        `File validated. Press submit to upload fees categories.`,
        {
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }
      );
    }
  };
  const permissions = ['ADMIN', 'SUPERADMIN'];
  const handleSubmit = async () => {
    await bulkAdd({
      variables: {
        data: structures,
      },
      refetchQueries: [
        {
          query: GET_FEES_STRUCTURE_QUERY,
          variables: { termID: currentTerm },
          fetchPolicy: 'network-only',
        },
      ],
    });
  };
  if (bulkAddData.loading || accsData.loading) return <Loader />;
  return (
    <Authorize pms={permissions}>
      <div>
        <Paper style={{ padding: 10, marginBottom: 10 }}>
          <h4>
            Please ensure that the data is correct. Fees Structure once added
            should not be modified or deleted. If in any doubt please contact
            support staff. Please remember to update students with Fee Category
            Names. You can do thsi by bulk editing the students sheet.
          </h4>
        </Paper>
        <Container className={classes.main} maxWidth="xl" disableGutters>
          <Paper className={classes.wrapper}>
            <div className={classes.content}>
              <ViewFeesStructures school={school} user={user} />
            </div>
          </Paper>
          <Paper className={classes.wrapper}>
            <div className={classes.content}>
              <h4>Example: COMP-FE-REGULAR-NT/SBC/SC/ST/VJNT</h4>
              <AddFeesStructureForm school={school} user={user} />
            </div>
            <div className={classes.content}>
              <UploadData
                template={FeesStructureTemplate}
                filename="FeesStructureTemplate.csv"
                checkData={handleForce}
                title="Upload CSV file for Bulk Fees Categories import"
              />
              <Divider />
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={!submitEnabled}
              >
                Upload Fees Structure
              </Button>
            </div>
          </Paper>
        </Container>
      </div>
    </Authorize>
  );
}
