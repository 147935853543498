import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import moment from 'moment';
import Loader from './Loader';
import Table, { tableIcons } from './Table';
// import ClassSectionSubjectDialog from './ClassSectionSubjectDialog';
import { DEL_EXPENSE_MUTATION } from '../resolvers/Mutations';

export default function ViewExpenses(props) {
  const { user, expensesData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [deleteExpense, deleteExpenseData] = useMutation(DEL_EXPENSE_MUTATION);
  if (deleteExpenseData.loading) return <Loader />;
  return (
    <Box>
      <Table
        columns={[
          {
            title: 'Sr.No.',
            field: 'sr',
            width: '5%',
          },
          { title: 'Id', field: 'id', hidden: true },
          {
            title: 'Category',
            field: 'cat',
            width: '10%',
          },
          {
            title: 'Amount',
            field: 'amount',
            width: '10%',
          },
          {
            title: 'Comment',
            field: 'comment',
            width: '20%',
          },

          {
            title: 'Entry Date',
            field: 'enteredOn',
            width: '20%',
          },
          {
            title: 'Paid On',
            field: 'paidOn',
            width: '20%',
          },
          {
            title: 'User',
            field: 'collectedBy',
            width: '10%',
          },
        ]}
        data={expensesData.data.expenses.map((e, i) => {
          const t = 0;
          return {
            sr: i + 1,
            id: e.id,
            cat: e.expense_cat.category,
            enteredOn: moment(e.enteredOn).format('DD/MM/YY hh:mm a'),
            paidOn: moment(e.paidOn).format('DD/MM/YY hh:mm a'),
            amount: `${e.amount}`,
            comment: e.comment,
            collectedBy: e.user.name,
          };
        })}
        title="Expenses"
        options={{ tableLayout: 'fixed' }}
        actions={
          user.permissions === 'ADMIN' || user.permissions === 'SUPERADMIN'
            ? [
                rowData => ({
                  icon: tableIcons.Delete,
                  tooltip: 'Delete Fee',
                  onClick: async (event, d) => {
                    await deleteExpense({
                      variables: {
                        id: rowData.id,
                      },
                    });
                    await expensesData.refetch();
                    enqueueSnackbar('Expense Deleted Successfully!', {
                      variant: 'success',
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    });
                  },
                }),
              ]
            : []
        }
      />
    </Box>
  );
}
