import React from 'react';
import { Box, makeStyles, Paper, Button } from '@material-ui/core';
import PlainSelect from './PlainSelect';
import FeesTable from './FeesTable';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 10,
    padding: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapper: {
    width: '50%',
    padding: 20,
  },
  button: {
    height: 55,
    color: '#FFF',
    fontWeight: 'bold',
  },
}));
export default function ViewFees(props) {
  const classes = useStyles();
  const { school, user, feesCats } = props;
  const feeCats = feesCats.map(s => ({
    value: s.id,
    label: s.name,
  }));
  const [feeCat, setFeeCat] = React.useState();
  const [confirmedFeeCat, setConfirmedFeeCat] = React.useState(false);
  return (
    <Box>
      <Paper>
        <div className={classes.main}>
          <div className={classes.wrapper}>
            <PlainSelect
              selectedVal={feeCat}
              onChange={setFeeCat}
              options={feeCats}
              placeholder="Select A Fee Category To See Fees Chart"
              isDisabled={!!confirmedFeeCat}
            />
          </div>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color={confirmedFeeCat ? 'primary' : 'secondary'}
              fullWidth
              className={classes.button}
              onClick={() => setConfirmedFeeCat(!confirmedFeeCat)}
            >
              {confirmedFeeCat ? 'Reset Fee Category' : 'Confirm Fee Category'}
            </Button>
          </div>
        </div>
      </Paper>
      {confirmedFeeCat && (
        <Paper className={classes.container}>
          <FeesTable feeCat={feeCat} school={school} user={user} />
        </Paper>
      )}
    </Box>
  );
}
