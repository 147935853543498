import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import Loader from './Loader';
import { ADD_EXP_CAT_MUTATION } from '../resolvers/Mutations';
import { EXPENSE_CAT_QUERY } from '../resolvers/Queries';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    width: '100%',
    marginBottom: 10,
  },
  options: {
    width: '100%',
    marginBottom: 10,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}));

export default function AddExpenseCatForm(props) {
  const classes = useStyles();
  const { school } = props;
  const [createExpCat, createExpCatData] = useMutation(ADD_EXP_CAT_MUTATION);
  if (createExpCatData.loading) return <Loader />;
  return (
    <Formik
      initialValues={{
        name: '',
        schoolID: school.id,
      }}
      enableReinitialize
      onSubmit={async values => {
        try {
          await createExpCat({
            variables: {
              data: {
                category: values.name,
                schoolID: values.schoolID,
              },
            },
            refetchQueries: [
              {
                query: EXPENSE_CAT_QUERY,
                variables: { schoolID: school.id },
                fetchPolicy: 'network-only',
              },
            ],
          });
        } catch (e) {
          console.log(e);
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        schoolID: Yup.string().required('Required'),
      })}
    >
      {p => {
        const { values, touched, errors, handleChange, handleBlur } = p;
        return (
          <Form className={classes.container}>
            <TextField
              label="Expense Category Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.name && touched.name && errors.name}
              margin="normal"
              fullWidth
              required
              variant="outlined"
            />

            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Add Expense Category
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
