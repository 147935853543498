import React from 'react';
import Select, { components } from 'react-select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
}));
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child =>
      child && child.type !== Placeholder ? child : null
    )}
  </ValueContainer>
);
export default function PlainSelect(props) {
  const { selectedVal, onChange, options, placeholder, isDisabled } = props;
  const classes = useStyles();
  return (
    <Select
      name="select"
      value={selectedVal}
      onChange={val => onChange(val)}
      options={options}
      placeholder={placeholder}
      className={classes.select}
      zIndex={10}
      isDisabled={isDisabled}
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        container: (provided, state) => ({
          ...provided,
          marginTop: 10,
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: 'visible',
          paddingTop: 20,
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue ? -2 : '50%',
          transition: 'top 0.1s, font-size 0.1s',
          backgroundColor:
            state.hasValue || state.selectProps.inputValue ? '#FFF' : 'none',
          zIndex: state.hasValue || state.selectProps.inputValue ? 5 : 0,
          padding: state.hasValue || state.selectProps.inputValue ? '0 5px' : 0,
          fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        }),
      }}
    />
  );
}
