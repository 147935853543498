/* eslint-disable no-nested-ternary */
import { useQuery, useApolloClient } from '@apollo/client';
import { Container, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useSnackbar } from 'notistack';
import Copyright from '../components/Copyright';
import Loader from '../components/Loader';
import { GET_LIVEATT_QUERY } from '../resolvers/Queries';
import { MARK_ACTIVE_MUTATION } from '../resolvers/Mutations';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 500,
  },
  fixedHeight: {
    height: 240,
  },
  title: {
    backgroundColor: '#0EABBC',
    fontFamily: 'Montserrat',
    color: '#FFF',
    textAlign: 'center',
    height: 70,
  },
}));
export default function StudentLectures() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [users, setUsers] = React.useState(null);
  const getUsers = async () => {
    const u = JSON.parse(localStorage.getItem('students'));
    setUsers(u);
  };
  React.useEffect(() => {
    getUsers();
  }, [history]);
  const { data: liveAtts, loading: liveLoading } = useQuery(GET_LIVEATT_QUERY, {
    variables: {
      studentIDs: users?.map(u => u.id),
      sectionIDs: users?.map(u => u.sectionID),
    },
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  });

  firebase.messaging().onMessage(payload => {
    if (payload.data.type === 'cancel') return;
    if (payload.data.type === 'presence') {
      const activeAction = key => (
        <>
          <Button
            onClick={async () => {
              try {
                closeSnackbar(key);
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Ok
          </Button>
        </>
      );
      enqueueSnackbar(`${payload.data.title}`, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 60000,
        action: activeAction,
      });
    }
    if (payload.data.type === 'active') {
      const activeAction = key => (
        <>
          <Button
            onClick={async () => {
              try {
                await client.mutate({
                  mutation: MARK_ACTIVE_MUTATION,
                  variables: {
                    sAttID: payload.data.sAttID,
                    notification: {
                      [payload.data.timestamp]: true,
                    },
                    active: true,
                  },
                });
                closeSnackbar(key);
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Mark Active
          </Button>
        </>
      );
      enqueueSnackbar(`${payload.data.title}`, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 15000,
        action: activeAction,
      });
    }

    console.log('Message received. ', payload);
    // ...
  });
  if (liveLoading) return <Loader />;
  const atts = liveAtts.attendances;
  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h3" style={{ paddingTop: 10 }}>
          Live Lectures
        </Typography>
      </div>
      <Paper className={classes.paper}>
        <div style={{ minHeight: 500 }}>
          <Typography
            variant="h4"
            style={{
              color: '#0EABBC',
              fontSize: 24,
              fontWeight: 'bold',
              fontFamily: 'Arial',
            }}
          >
            {moment().format('DD MMM YYYY')}
          </Typography>
          {atts && atts.length > 0 ? (
            <List
              dense
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {atts.map(att => {
                const data = {
                  cls: `${att.class.class} ${att.section.section}`,
                  subject: att.book
                    ? `${att.subject.subject} ${att.book.name}`
                    : att.subject
                    ? `${att.subject.subject}`
                    : '',
                  timestamp: att.createdAt,
                  attID: att.id,
                };
                return (
                  <div key={att.id}>
                    <ListItem
                      style={{ padding: 0, cursor: 'pointer' }}
                      onClick={() => {
                        history.push('/studentLive', data);
                      }}
                    >
                      <ListItemText
                        style={{
                          color: '#0EABBC',
                          backgroundColor: '#ECFCFD',
                          padding: 10,
                          // height: 50,
                        }}
                      >
                        <Typography
                          variant="h4"
                          style={{
                            fontSize: 18,
                            fontFamily: 'Arial',
                            verticalAlign: 'middle',
                            color: '#026776',
                          }}
                        >
                          {data.cls}
                        </Typography>
                        <Typography
                          variant="h4"
                          style={{
                            fontSize: 16,
                            fontFamily: 'Arial',
                            verticalAlign: 'middle',
                            color: '#0EABBC',
                          }}
                        >
                          {data.subject}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: 16,
                            fontFamily: 'Arial',
                            verticalAlign: 'middle',
                            color: '#0EABBC',
                          }}
                        >
                          Students:{' '}
                          {att.studentAttendances
                            .map(s => s.student.name)
                            .join(',')}
                        </Typography>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <KeyboardArrowRightIcon
                          color="secondary"
                          style={{ fontSize: 56 }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          ) : (
            <div>
              <Typography
                variant="h4"
                style={{
                  marginTop: 20,
                  color: '#026776',
                  fontSize: 20,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                }}
              >
                No Lectures Are Live
              </Typography>
            </div>
          )}
        </div>

        <Button
          color="secondary"
          onClick={() => {
            firebase.auth().signOut();
            history.push('/');
          }}
          variant="outlined"
          style={{ margin: 20 }}
        >
          Logout
        </Button>
        <Copyright />
      </Paper>
    </Container>
  );
}
