import React from 'react';
import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { EXPENSE_CAT_QUERY } from '../resolvers/Queries';
import { DEL_EXP_CAT_MUTATION } from '../resolvers/Mutations';
import Loader from './Loader';
import Table, { tableIcons } from './Table';

export default function ViewExpenseCats(props) {
  const { school, user, expCats } = props;
  const [deleteExpCat, deleteExpCatData] = useMutation(DEL_EXP_CAT_MUTATION);
  if (deleteExpCatData.loading) return <Loader />;

  return (
    <Box>
      <Table
        columns={[
          { title: 'Sr', field: 'sr', width: 20 },
          { title: 'id', field: 'id', hidden: true },
          { title: 'Name', field: 'name' },
        ]}
        data={expCats}
        title="Fees Categories"
        actions={
          user.permissions === 'SUPERADMIN' || user.permissions === ' ADMIN'
            ? [
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Delete User',
                  onClick: async (event, rowData) => {
                    await deleteExpCat({
                      variables: {
                        id: rowData.id,
                      },
                      refetchQueries: [
                        {
                          query: EXPENSE_CAT_QUERY,
                          variables: { schoolID: school.id },
                          fetchPolicy: 'network-only',
                        },
                      ],
                    });
                  },
                },
              ]
            : []
        }
      />
    </Box>
  );
}
