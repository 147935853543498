import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';
import { CssBaseline, Container, CardMedia } from '@material-ui/core';
import Copyright from '../components/Copyright';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    // border: '1px solid #E7E7E7',
    backgroundColor: theme.palette.background,
    height: '100vh',
    paddingBottom: 80,
  },
  logo: {
    height: '100px',
    width: '100px',
    margin: '10px auto',
  },
  button: {
    marginLeft: 10,
  },
  link: {
    textDecoration: 'none',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#026776',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 50,
    color: '#FFF',
  },
  toPay: {
    width: 230,
    paddingLeft: 15,
  },
  razorpay: {
    width: 100,
  },
  payButton: {
    color: '#FFF',
    borderColor: '#FFF',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 350,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
function useP() {
  return new URLSearchParams(useLocation().search);
}
export default function VerifyOnlineFees(props) {
  const data = useP();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <CardMedia
            className={classes.logo}
            component="div"
            image="/logo.png"
          />
          {data.get('result') === 'success' && (
            <h2 style={{ textAlign: 'center' }}>
              Online Fees Successfully Received. Thank you for your time.
            </h2>
          )}
          {data.get('result') === 'failure' && (
            <h2 style={{ textAlign: 'center' }}>
              Online Fees collection failed. If any money has been deducted from
              your account it will be refunded within 3-7 business days. Please
              start again.
            </h2>
          )}
          {data.get('result') === 'cancel' && (
            <h2 style={{ textAlign: 'center' }}>
              Online Fees collection was cancelled by the user. Please click on
              sms link and try again.
            </h2>
          )}
          {data.get('result') === 'mismatch' && (
            <h2 style={{ textAlign: 'center' }}>
              An error has occurred. Please try again.
            </h2>
          )}
          {data.get('result') !== 'cancel' &&
            data.get('result') !== 'failure' &&
            data.get('result') !== 'success' &&
            data.get('result') !== 'mismatch' && (
              <h2 style={{ textAlign: 'center' }}>
                Something has gone wrong on the payment gateway. This is the
                error {data.get('result')} if you need to follow up with this.
                If any money has been deducted from you account it will be
                refunded within 3-7 business days. Please click on sms link and
                start again.
              </h2>
            )}
          <Copyright />
          <div style={{ paddingBottom: 80 }} />
        </Container>
      </main>
    </div>
  );
}
