import React from 'react';

const TermsContext = React.createContext({
  terms: [],
  currentTerm: { id: null },
  setTerms: () => {},
  setTerm: () => {},
});

export default TermsContext;
