import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import FeeReport from './FeeReport';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FeesReports(props) {
  const { school, sections, years } = props;
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          centered
          aria-label="full width tabs example"
        >
          <Tab label="Payments Report" {...a11yProps(0)} />
          <Tab label="Students Report" {...a11yProps(1)} />
          <Tab label="Daily Aggregated Report" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <FeeReport
          school={school}
          sections={sections}
          years={years}
          type="paymentsCollected"
        />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <FeeReport
          school={school}
          sections={sections}
          years={years}
          type="studentsCollected"
        />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <FeeReport
          school={school}
          sections={sections}
          years={years}
          type="dailyCollected"
        />
      </TabPanel>
    </Box>
  );
}
