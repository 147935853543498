import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import AddUser from '../components/AddUser';
import ViewUsers from '../components/ViewUsers';
import BulkAddUsers from '../components/BulkAddUsers';
import Authorize from './Authorize';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Users() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const permissions = ['SUPERADMIN', 'ADMIN'];
  const handleChange = (event = '', newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Authorize pms={permissions}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            centered
            aria-label="full width tabs example"
          >
            <Tab label="View Users" {...a11yProps(0)} />
            <Tab label="Add User" {...a11yProps(1)} />
            <Tab label="Bulk Add" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ViewUsers />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <AddUser />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <h4>Bulk Add Users</h4>
          <BulkAddUsers />
        </TabPanel>
      </Authorize>
    </Layout>
  );
}
